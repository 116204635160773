import * as types from "../types/types";

const initalState = {
    nftdata:[],
    nftloading:false,
    nftbidsdata:[],
    nftbidsloading:false,
    singlenft:[],
    singlenftloading:false,
    collections:[],
    collectionsloading:false,
    nftbypricedata:[],
    allnftcollectiblesloading: false,
    allnftcollectibles:[],
    singlenftcollectibleloading:false,
    siglenftcollectible:[],
    placednftcollectibleloading:false,
    placednftcollectible:[],
    singlemintednft: [],

    royaltyloading:false,
    royalty_platfromfee:[],
}

const matchReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_NFT_START: 
            return {
                ...state,
                nftloading: true
            }
        case types.FETCH_NFT_SUCCESS:
            return {
                ...state,
                nftloading: false,
                nftdata: action.payload
            } 
            case types.FETCH_NFT_BYPRICE_SUCCESS:
                return {
                    ...state,
                    nftloading: false,
                    nftbypricedata: action.payload
                } 

        case types.FETCH_NFT_BIDS_START:
            return {
                ...state,
                nftbidsloading: true
            }
        case types.FETCH_NFT_BIDS_SUCCESS:
            return {
                ...state,
                nftbidsloading: false,
                nftbidsdata: action.payload
            }
        case types.FETCH_SINGLE_NFT_START:
            return {
                ...state,
                singlenftloading: true
            }
        case types.FETCH_SINGLE_NFT_SUCCESS:
            return {
                ...state,
                singlenftloading: false,
                singlenft: action.payload
            }   
        case types.FETCH_COLLECTIONS_START:
            return {
                ...state,
                collectionsloading: true,
                allnftcollectiblesloading:true
            }
        case types.FETCH_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collectionsloading: false,
                collections: action.payload
            } 
            
        case types.FETCH_ALL_NFT_COLLECTIBLES_SUCCESS:
            return {
                ...state,
                allnftcollectiblesloading: false,
                allnftcollectibles : action.payload,
            }
        
        case types.FETCH_SINGLE_NFT_COLLECTIBLES_SUCCESS:
            return {
                ...state,
                singlenftcollectibleloading :false,
                siglenftcollectible : action.payload
            }

        case types.FETCH_PLACED_NFT_COLLECTIBLES_SUCCESS: 
            return {
                ...state,
                placednftcollectibleloading: false,
                placednftcollectible: action.payload
            }

        case types.FETCH_SINGLE_MINTED_NFT_COLLECTION:
            return {
                ...state,
                loading: false,
                singlemintednft: action.payload
            }

        case types.FETCH_GAS_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                gasprice: action.payload
            }  
        case types.FETCH_ROYALTY_START:
            return {
                ...state,
                royaltyloading: true
            }      
        case types.FETCH_ROYALTY_SUCCESS:
            return {
                ...state,
                royaltyloading: false,
                royalty_platfromfee: action.payload
            } 
            

        default:
            return state
    }
}

export default matchReducer;