import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import parseJwt, { apiBaseUrl, logo_dark, logo_light, deleteRecordwithID, banner_loader } from '../../store/helpers/common';
import XRSwitch from './../../components/xrgames/XRSwitch';
import {
    getUserdetails
} from "../../store/actions/userActions";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import renderHTML from 'react-render-html';
import $, { event } from 'jquery';
import moment from "moment-timezone";
import Fanhubmenus from '../Fanhubmenus';

class SignupHeader extends Component {
    constructor(props) {
        super(props);
        // this.myFunction = this.myFunction.bind(this);
        // this.handleLinkClick = this.handleLinkClick.bind(this); 
        this.state = {
            isLoggedIn: false,
            light_theme: true,
            ifluencer: false,
            visiter: false,
            user_id: "",
            firstname: '',
            lastname: '',
            nickname: '',
            userdata: "",
            profilepic: "",
            loggedout: false,
            cggurl: '',
            fanhub_name: null,
            fanhub_logo: null,
            asd: false,
            profile_pic: '',
            refresh: "no",
            addclass: false,
            addclasss: false,
            allmenusadmindata: "",
            username: "",
            email: "",

        }
    }
    fetchData() {
        const cggurl = this.props.location.pathname;
        this.setState({
            cggurl: cggurl,
        })
        this.props.history.listen(() => {
            this.changeurl(window.location.pathname)
        })
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('token') === 'undefined') {
                this.setState({
                    session_msg: 'Session is expired, Please Login Again.'
                })
                setTimeout(function () {
                    localStorage.clear('token');
                    localStorage.clear('session_expired');
                    this.setState({
                        session_msg: ""
                    })
                }.bind(this), 5000);
            } else {
                const token = localStorage.getItem("token");
                const currdetails = parseJwt(localStorage.getItem('token'));
                var today = new Date();
                var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                if (currdetails.exp < Date.now()) {
                } else {
                }
                const currid = currdetails.sub;
                const userrole = currdetails.urxrs;
                this.setState({ currid: currid, isLoggedIn: true, userrole: userrole });
                this.props.dispatch(getUserdetails(currid));
                if (currdetails.urxrs == '$In&f67l@n%eR76Hi7') {
                    this.setState({
                        ifluencer: true
                    })
                } else if (currdetails.urxrs == '$Us&er67l@vi%eRst76Heri7') {
                    this.setState({
                        visiter: true
                    })
                } else if (currdetails.urxrs == '#u$43*70&78h7') {
                    this.setState({
                        userLogin: true
                    })
                }

                if (userrole === '$Sa#79+57hD%4My5') {
                    let menusDatabyid;
                    menusDatabyid = {
                        method: 'GET',
                        url: `${process.env.REACT_APP_APIURL}/user/get-admin-menu-byid/${currid}`,
                        headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                    };
                    axios(menusDatabyid).then((response) => {
                        this.setState({
                            allmenusadmindata: response.data.data,
                        });

                    })
                        .catch((error) => {
                        });


                } else {
                    let menusData;
                    menusData = {
                        method: 'GET',
                        url: `${process.env.REACT_APP_APIURL}/user/get-admin-menu`,
                        headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                    };
                    axios(menusData).then((response) => {
                        this.setState({
                            allmenusadmindata: response.data.data,
                        });

                    })
                        .catch((error) => {
                        });
                }

            }
        } else {
            this.setState({
                loggedout: true
            })
        }
        if (localStorage.getItem('sc_theme_yf')) {
            this.setState({
                light_theme: localStorage.getItem('sc_theme_yf') == 'true' ? true : false
            })
        }
    }
    componentDidMount() {

        this.fetchData();
        window.addEventListener('resize', this.handleResize);
        // this.setState({ addclass: true });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.submitlayoutrefreshstate == 'yes') {
            // this.fetchData();
            if (!this.state.asd) {
                this.setState({
                    asd: true
                })
                this.fetchData();
                this.props.submitlayoutrefreshback();
            }
        }
    }
    componentWillReceiveProps(nextProps) {
      //  console.log(nextProps,"hghhhgdhsgd");
        if (nextProps.singleuserdata) {
            this.setState({
                userdata: nextProps.singleuserdata,
                fanhub_name: nextProps.singleuserdata.fanhub_name,
                fanhub_logo: nextProps.singleuserdata.fanhub_logo,
                email: nextProps.singleuserdata.email,
                username: nextProps.singleuserdata.nickname,
                profile_pic: nextProps.singleuserdata.profile_pic

            })
            if (nextProps.singleuserdata.domain_details) {
                this.setState({
                    domain_name: nextProps.singleuserdata.domain_details.domain_name,

                    platform_short_name: nextProps.singleuserdata.domain_details.platform_short_name,
                    internal_domain_name: nextProps.singleuserdata.domain_details.internal_domain_name,
                })
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        // Add any other cleanup tasks related to asynchronous operations.
      }
      
 





    addDefaultSrcprofile = (ev) => {
        ev.target.src = `${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`;
    }
    
    changeurl = (newurl) => {
        this.setState({
            cggurl: newurl,
        })
    }
   

    myFunction = () => {
        // this.setState({ addclass: !this.state.addclass })
        // if ($(".navbar-collapse").hasClass("collapsing")) {
        //     $(".navbar-collapse").removeClass("show");
        // }
        // this.setState({ addclass: !this.state.addclass });
        this.setState({ addclass: !this.state.addclass })
        if (this.state.addclass) {
            //$("#hambtn").addClass("changee");
            this.setState({ addclasss: !this.state.addclasss })
        } else {
            // ("#hambtn").addClass("changee");
            //this.state.addclass
            this.setState({ addclasss: !this.state.addclasss })
        }

    }

    // myFunction = () => {
    //     this.setState((prevState) => ({
    //         addclass: !prevState.addclass,
    //         addclasss: prevState.addclass ? false : prevState.addclasss,
    //     }));
    // }

    RemoveactivatepcNavToggle = (e) => {

        $(".navbar-collapse").removeClass("show");
        if (this.state.addclass) {
            this.setState({ addclass: false })
        } else {
            this.setState({ addclasss: false })
        }
    }


    edithomebanner = (e, id, link, image, bannertype, screentype, bannertext, modalstatus) => {
 
        this.setState({
            'banner_id': id,
            'banner_link': link,
            'banner_image': image,
            'bannertype': bannertype,
            'screentype': screentype,
            'bannertext': bannertext,
            'refresh': "no",
        })

        var fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${image}`;
        this.setState({
            banner_image_full: fullurll,
            bannerext: fullurll.split('.').pop()
        })
        if (modalstatus == "yes") {
            window.$('#modal-container-updatebanner').modal('show');
        } else {
            window.$('#modal-container-updatebanner').modal('hide');
        }

    }
    edithomepartners = (e, link_id, link_url, partner_image, partner_title, partner_is_active, partner_description, partner_modalstatus) => {
        this.setState({
            'linkid': link_id,
            'linkurl': link_url,
            'image_name': partner_image,
            'title': partner_title,
            'is_active': partner_is_active,
            'description': partner_description,
            'refresh': "no",
        })

        var fullurll = "";
        if (partner_image.includes('http')) {
            fullurll = partner_image;
        } else {
            fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/communitypartners/${partner_image}`;
        }

        this.setState({
            partner_image_full: fullurll,
            partnerext: partner_image.split('.').pop()
        })
        if (partner_modalstatus == "yes") {
            window.$('#modal-container-updatepartner').modal('show');
        } else {
            window.$('#modal-container-updatepartner').modal('hide');
        }

    }
    // dsf
    remtrid = (e) => {
        this.myFunction();
    }
    addDefaultSrcprofile = (ev) => {
        ev.target.src = `${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`;
    }
    logout = (e) => {
        e.preventDefault();
        // this.props.dispatch(getAdminLogs(this.state.currid));
        localStorage.clear('token');
        let formData = new FormData();
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.setItem('intro-popup', 'yes');
        formData.append('email', this.state.email);
        let request;
      request = {
        method: 'POST',
        url: `${process.env.REACT_APP_APIURL}/logouttoken`,
        headers: { 'Content-Type': 'application/json' },
        data: formData
      };

      axios(request).then((data) => {
        console.log(data,"datadta");
        if (data.data.ResponseCode === "1") {
            this.props.history.push('/signup');
            window.location.reload();
        } else {
        //   this.setState({ isSubmited: false })
          // swal("Oops!", data.data, "error");
        //   swalpopup(data.data, "error");
        }
      });
       
        // window.location.reload('/');
       
    };

    edithomebanner = (e, id, link, image, bannertype, screentype, bannertext, modalstatus) => {
    
        this.setState({
            'banner_id': id,
            'banner_link': link,
            'banner_image': image,
            'bannertype': bannertype,
            'screentype': screentype,
            'bannertext': bannertext,
            'refresh': "no",
        })

        var fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${image}`;
        this.setState({
            banner_image_full: fullurll,
            bannerext: fullurll.split('.').pop()
        })
        if (modalstatus == "yes") {
            window.$('#modal-container-updatebanner').modal('show');
        } else {
            window.$('#modal-container-updatebanner').modal('hide');
        }

    }
    edithomepartners = (e, link_id, link_url, partner_image, partner_title, partner_is_active, partner_description, partner_modalstatus) => {
        this.setState({
            'linkid': link_id,
            'linkurl': link_url,
            'image_name': partner_image,
            'title': partner_title,
            'is_active': partner_is_active,
            'description': partner_description,
            'refresh': "no",
        })

        var fullurll = "";
        if (partner_image.includes('http')) {
            fullurll = partner_image;
        } else {
            fullurll = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/communitypartners/${partner_image}`;
        }

        this.setState({
            partner_image_full: fullurll,
            partnerext: partner_image.split('.').pop()
        })
        if (partner_modalstatus == "yes") {
            window.$('#modal-container-updatepartner').modal('show');
        } else {
            window.$('#modal-container-updatepartner').modal('hide');
        }

    }
    handlebackstep = () => {
        localStorage.clear('token');
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.removeItem('encudata');
        localStorage.removeItem('encuid');
        localStorage.removeItem('camethrough');
        localStorage.removeItem('xp_password');
        localStorage.removeItem('zap_wallet_address');
        localStorage.removeItem('network');
        localStorage.removeItem('account_id');
        localStorage.removeItem('wallet_group');
        localStorage.removeItem('PLATFORM_ID_COMMOM_DB');
        localStorage.removeItem('intro-popup');
        // localStorage.setItem('setshowwalletpagestate',this.props.plandetail.showplandetails)
        localStorage.removeItem('setshowwalletpagestate');
        localStorage.removeItem('signupuserwalletemail');
        localStorage.removeItem('signupuserwalletname');
        localStorage.removeItem('signupuserwalletphone');
        localStorage.removeItem('camethrough');
        this.props.handlebacktoplan();
    }



    render() {
        const { fanhub_name, fanhub_logo, profile_pic, allmenusadmindata, platform_short_name ,username} = this.state;

        return (
            <>
                <header className="admin-header">
                    <div className="container-fluid">
                        <div className="m-0">
                            <div className="row">
                                <div className="col-md-4 col-sm-4 col-4 d-flex align-items-center justify-content-start">
                                    <div className="pull-left d-flex">
                                        {/* <NavLink  className="nav-brand"  to="/role-selection"> */}
                                        <NavLink className="nav-brand d-flex" to="/activity-center">
                                            {/* <span><img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/Logo_dark.png?random=${moment().format('YYYYMM-DDHHmmss')}`} /></span> */}
                                            {
                                                fanhub_logo ?
                                                    <img id="hub_logo_actv" className="img-fluid me-3" src={`${process.env.REACT_APP_GCPURL}/fanhubprofileimages/${fanhub_logo}`}></img>
                                                    :
                                                    this.props ? this.props.submitlayoutrefresh ? this.props.submitlayoutrefresh.fanhub_logo ?
                                                        <img id="hub_logo_actv" className="img-fluid me-3" src={`${process.env.REACT_APP_GCPURL}/fanhubprofileimages/${this.props.submitlayoutrefresh.fanhub_logo}`}></img>
                                                        :
                                                        <img id="hub_logo_actv" className="img-fluid me-3" src="https://storage.googleapis.com/xr-sports/xrsports_games/mav-header/xr_fav2.png" alt="Activelink Brand Logo" onClick={this.handlebackstep}></img>

                                                        :
                                                        <img id="hub_logo_actv" className="img-fluid me-3" src="https://storage.googleapis.com/xr-sports/xrsports_games/mav-header/xr_fav2.png" alt="Activelink Brand Logo" onClick={this.handlebackstep}></img>
                                                        :
                                                        <img id="hub_logo_actv" className="img-fluid me-3" src="https://storage.googleapis.com/xr-sports/xrsports_games/mav-header/xr_fav2.png" alt="Activelink Brand Logo" onClick={this.handlebackstep}></img>

                                            }

                                            {/* {
                                                            this.state.profile_pic
                                                                ?
                                                                this.state.profile_pic.includes('http')
                                                                    ?
                                                                    <LazyLoadImage className="img-fluid me-3" src={this.state.profile_pic} alt="" />
                                                                    :
                                                                    this.state.profile_pic === '' || this.state.profile_pic === null
                                                                        ?
                                                                        <LazyLoadImage className="img-fluid me-3" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />
                                                                        :
                                                                        <LazyLoadImage className="img-fluid me-3" src={`${process.env.REACT_APP_GCPURL}/profileimages/${this.state.profile_pic}`} alt="" onError={this.addDefaultSrcprofile} />
                                                                :
                                                                <LazyLoadImage className="img-fluid me-3" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />

                                                        }            */}
                                            {/* </NavLink> */}
                                            <h6 className="d-flex align-items-center justify-content-center mb-0">
                                                {
                                                    username ?
                                                        username
                                                        :
                                                        this.props ? this.props.submitlayoutrefresh ? this.props.submitlayoutrefresh.username ?
                                                            this.props.submitlayoutrefresh.username
                                                            :
                                                            'Activelink' :
                                                            'Activelink' :
                                                            'Activelink'
                                                }
                                            </h6>
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8 col-8 d-flex align-items-center justify-content-end">
                                    {
                                        this.props.history.location.pathname === '/role-selection' || this.props.history.location.pathname === '/my-wallet' || this.props.history.location.pathname === '/plans' || this.props.match.path === '/checkout/:id' || this.props.match.path === '/payment-confirmation/:id' || this.props.match.path === '/personal-hub/:id' ?
                                            ''
                                            :
                                            <div className="pull-right d-flex align-items-center">
                                                {
                                                    this.state.domain_name ?
                                                        this.state.domain_name ?
                                                            <a href={`https://${this.state.domain_name}`} target="_blank" className="d-lg-block d-md-block d-sm-block d-none active_domain_link me-4">
                                                                <span className="blinker me-3"></span> {this.state.domain_name}
                                                            </a>
                                                            : ''
                                                        :
                                                        this.state.internal_domain_name ?
                                                            <a href={`https://${this.state.internal_domain_name}`} target="_blank" className="d-lg-block d-md-block d-sm-block d-none active_domain_link me-4">
                                                                <span className="blinker me-3"></span> {this.state.internal_domain_name}
                                                            </a>
                                                            : ''

                                                }
                                             
                                                <div className="nav-item dropdown profile_menu all_product_features">
                                                    <a className="cfh_button me-4 dropdown-toggle"  id="navbarDropdownMenuLink12" data-bs-toggle="dropdown">+ Create</a>
                                                    <div className="dropdown-menu p-3" aria-labelledby="navbarDropdownMenuLink12">
                                                        <NavLink className="dropdown-item mb-1 ps-0 p-0" to="/my-feed"><img className="img-fluid me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/Feed.png" />Feed Post</NavLink>
                                                        <NavLink className="dropdown-item mb-1 ps-0 p-0" to="/create-challenge"><img className="img-fluid me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/Challenges.png" />Challenge</NavLink>
                                                        <NavLink className="dropdown-item mb-1 ps-0 p-0" to="/add-tournament"><img className="img-fluid me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/Tournaments.png" />Tournament</NavLink>
                                                        <NavLink className="dropdown-item mb-1 ps-0 p-0" to="/admin-add-event"><img className="img-fluid me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/Events.png" />Event</NavLink>
                                                        <NavLink className="dropdown-item mb-1 ps-0 p-0" to="/my-media"><img className="img-fluid me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/Media.png" />Video</NavLink>
                                                        <NavLink className="dropdown-item ps-0 p-0" to="/my-blogs"><img className="img-fluid blogs me-2" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/header-assets/blog.png" />Blog</NavLink>
                                                    </div>
                                                </div>
                                                {/* <NavLink to="/wallet-info"> */}
                                                <NavLink to={localStorage.getItem('account_id') ? "/wallet-info" : "/wallet-info"}>
                                                    <img className="img-fluid me-4 header-wallet-icon" src="https://storage.googleapis.com/xr-sports/xrgames/admin-assets/wallet-filled-money-tool.png" />
                                                </NavLink>
                                                <div className="dropdown profile_menu">
                                                    <a className="dropdown-toggle"  id="navbarDropdownMenuLink111" data-bs-toggle="dropdown">
                                                        {
                                                            this.state.profile_pic
                                                                ?
                                                                this.state.profile_pic.includes('http')
                                                                    ?
                                                                    <LazyLoadImage className="img-fluid user-profile-icon" src={this.state.profile_pic} alt="" />
                                                                    :
                                                                    this.state.profile_pic === '' || this.state.profile_pic === null
                                                                        ?
                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />
                                                                        :
                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/fanhubprofileimages/${fanhub_logo}?random=${moment().format('YYYYMM-DDHHmmss')}`} alt="" onError={this.addDefaultSrcprofile} />
                                                                :
                                                                <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" />

                                                        }
                                                    </a>
                                                    <div className="dropdown-menu profile p-3" aria-labelledby="navbarDropdownMenuLink111">
                                                        <div className="float-start w-100">
                                                            <h6 className="">User Profile</h6>

                                                        </div>
                                                        <div className="learn-more sidenavlogout float-start w-100 p-3 my-2">
                                                            <div className="d-flex align-items-center justify-content-center"><div className="sidenav_user_profile">

                                                                {

                                                                    this.state.propicfieldimgfile
                                                                        ?
                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={this.state.propicfieldimgfile} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />

                                                                        :
                                                                        this.state.propicfieldimgfile_avatar
                                                                            ?
                                                                            <LazyLoadImage className="img-fluid user-profile-icon" src={this.state.propicfieldimgfile_avatar} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />
                                                                            :

                                                                            this.state.profile_pic
                                                                                ?
                                                                                this.state.profile_pic.includes('http')
                                                                                    ?
                                                                                    <LazyLoadImage className="img-fluid user-profile-icon" src={this.state.profile_pic} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />
                                                                                    :
                                                                                    this.state.profile_pic === '' || this.state.profile_pic === null
                                                                                        ?
                                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />
                                                                                        :
                                                                                        <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/profileimages/${this.state.profile_pic}?random=${moment().format('YYYYMM-DDHHmmss')}`} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />
                                                                                :
                                                                                <LazyLoadImage className="img-fluid user-profile-icon" src={`${process.env.REACT_APP_GCPURL}/Profile-pic-Activelink.svg`} alt="" onError={(e) => this.addDefaultSrcprofile(e)} />
                                                                }


                                                                {/* <img className="img-fluid user-profile-icon" src="https://storage.googleapis.com/xr-sports/xrgames/Profile-pic-Activelink.svg" alt=""/> */}
                                                            </div><div className="sidenav_user_details ms-2"><p className="ms-2 mb-0"><strong>{this.state.username}</strong></p> <p className="ms-2 mb-0"><i className="fas fa-envelope me-2"></i>{this.state.email}</p></div></div></div>
                                                        <NavLink to="/my-profile" className="dropdown-item mb-1 p-0"><i className="fas fa-user me-2"></i>My Profile</NavLink>
                                                        {/* <NavLink to = "/" className="dropdown-itemm"><i className="fa fa-inbox me-2"></i>My Account </NavLink> */}
                                                        <NavLink to="/customize-homepage" className="dropdown-item mb-1 p-0"><i className="fas fa-edit me-2"></i>Customize Fan Hub</NavLink>
                                                        <div className="float-start w-100 mt-3">
                                                            <NavLink to="/signup" className="btn btn-primary text-center w-100" onClick={(e) => this.logout(e)}>Logout</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    }

                                </div>
                                <nav className="navbar navbar-toggleable-md navbar-light bg-light px-0 p-0" id="phonenavigationn">
                                    <div className="navbar admin-header float-start w-100 d-sm-block d-lg-none d-md-none d-block p-0">
                                        <button onClick={() => this.myFunction()} className="navbar-toggler navbar-toggler-right" id="" type="button" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-desk">
                                            <div className={`${this.state.addclass ? `change` : this.state.addclasss ? '' : ''}`} id="hambtn"  >
                                                <div className="bar1"></div>
                                                <div className="bar2"></div>
                                                <div className="bar3"></div>
                                            </div>
                                        </button>
                                        <div className="collapse navbar-collapse w-100 px-0" id="bs-example-navbar-collapse-desk">
                                            <ul className="navbar-nav m-0">


                                                <>

                                                    <div className="left_navigation mobile-viewss float-start w-100 mt-0" role="navigation" id="nav-bar-dynamic">
                                                        <div className="sidebar-collapse ms-0" id="sidebar-wrapper">
                                                            <div className="menu-admin-menu-container">
                                                                <div className="tabbable" id="tabs-17315">
                                                                    <div className="default-menu-bar left_navigation">
                                                                        {

                                                                            this.state.addclass && (window.location.pathname === "/customize-homepage" || window.location.pathname === "/customize-fanhublogo" || window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes"  || window.location.pathname === "/lobby-banner-setup") ?

                                                                                <div className="fan_hub_menus">
                                                                                    <>
                                                                                        <Fanhubmenus props={this.props} history={this.props.history} edithomebanner={this.edithomebanner} edithomepartners={this.edithomepartners}  />
                                                                                    </>
                                                                                </div>



                                                                                :

                                                                                // window.location.pathname === "/add-custom-menu" || window.location.pathname === "/navigation-boxes" ?

                                                                                //         <>
                                                                                //             <Fanhubmenus props={this.props} history={this.props.history} edithomebanner={this.edithomebanner} edithomepartners={this.edithomepartners} refresh={this.state.refresh} />
                                                                                //         </>

                                                                                //     :
                                                                                
                                                                            this.state.addclass?
                                                                                <ul className="navbar-nav ps-0">
                                                                                    <div id="accordion_mob" className="accordion">
                                                                                        {
                                                                                            allmenusadmindata ?
                                                                                                Object.keys(allmenusadmindata).map((post) => {
                                                                                                    let menu_title = allmenusadmindata[post].menu_title;
                                                                                                    let menu_id = allmenusadmindata[post].menu_id;
                                                                                                    let menu_link = allmenusadmindata[post].menu_link;
                                                                                                    let menu_icon = allmenusadmindata[post].menu_icon;
                                                                                                    let menu_parent = allmenusadmindata[post].menu_parent;
                                                                                                    let sub_menus = allmenusadmindata[post].sub_menus;
                                                                                                    let linktype = allmenusadmindata[post].linktype;
                                                                                                    return (

                                                                                                        menu_title == "Communityyyyyy" ?
                                                                                                            this.state.userrole == "$Supa#79+57hDuh%+ad+iN%4My5" ?
                                                                                                                <>
                                                                                                                    <div className="card-header my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordion_mob" href={`#menumobile_${menu_id}`} >
                                                                                                                        <p className="card-title mb-0 ms-2">
                                                                                                                            {menu_icon ?
                                                                                                                                <NavLink className=" btn-header-link p-0" onClick={() => this.myFunction()} to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                                                                                : ''}

                                                                                                                            <span>{menu_title}</span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div id={`menumobile_${menu_id}`} className="card-body collapse" data-parent="#accordion_mob">
                                                                                                                        <ul className="sidemenu">
                                                                                                                            {
                                                                                                                                sub_menus ? sub_menus.map((item) => {
                                                                                                                                    return (

                                                                                                                                        <li className="" key={item.menu_title}>

                                                                                                                                            {
                                                                                                                                                item.menu_link.includes('http') ?
                                                                                                                                                    <a onClick={(e) => this.RemoveactivatepcNavToggle(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                                                                    :
                                                                                                                                                    <NavLink className="" onClick={(e) => this.RemoveactivatepcNavToggle(e)} target={linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                                                            }

                                                                                                                                        </li>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                : ""
                                                                                                            :
                                                                                                            this.state.userrole == "$Sa#79+57hD%4My5" ?
                                                                                                                <>

                                                                                                                    <div className="card-header my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordion_mob" href={`#menumobile_${menu_id}`} >
                                                                                                                        <p className="card-title mb-0 ms-2">
                                                                                                                            {menu_icon ?
                                                                                                                                <NavLink className=" btn-header-link p-0" onClick={(e) => this.RemoveactivatepcNavToggle(e)} to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                                                                                : ''}

                                                                                                                            <span>{menu_title}</span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div id={`menumobile_${menu_id}`} className="card-body collapse" data-parent="#accordion_mob">
                                                                                                                        <ul className=" sidemenu">
                                                                                                                            {
                                                                                                                                sub_menus ? sub_menus.map((item) => {
                                                                                                                                    return (
                                                                                                                                        <li className="" key={item.menu_title}>
                                                                                                                                            {
                                                                                                                                                item.menu_link.includes('http') ?
                                                                                                                                                    <a onClick={(e) => this.RemoveactivatepcNavToggle(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                                                                    :
                                                                                                                                                    <NavLink className="" onClick={(e) => this.RemoveactivatepcNavToggle(e)} target={linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                                                            }

                                                                                                                                        </li>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :

                                                                                                                <>

                                                                                                                    <div className="card-header my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordion_mob" href={`#menumobile_${menu_id}`} >
                                                                                                                        <p className="card-title ms-2 mb-0">

                                                                                                                            {menu_icon ?
                                                                                                                                <NavLink className=" btn-header-link p-0" onClick={(e) => this.RemoveactivatepcNavToggle(e)} to={`#`}>{renderHTML(menu_icon)}</NavLink>
                                                                                                                                : ''}

                                                                                                                            <span>{menu_title}</span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div id={`menumobile_${menu_id}`} className="card-body collapse" data-parent="#accordion_mob">
                                                                                                                        <ul className=" sidemenu">
                                                                                                                            {
                                                                                                                                sub_menus ? sub_menus.map((item) => {
                                                                                                                                    return (
                                                                                                                                        item.menu_link == '/superadmin-profile' ?
                                                                                                                                            this.state.userrole != "$Supa#79+57hDuh%+ad+iN%4My5" ?
                                                                                                                                                '' :
                                                                                                                                                <li className="" key={item.menu_title}>
                                                                                                                                                    {
                                                                                                                                                        item.menu_link.includes('http') ?
                                                                                                                                                            <a onClick={(e) => this.RemoveactivatepcNavToggle(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                                                                            :
                                                                                                                                                            <NavLink className="" onClick={(e) => this.RemoveactivatepcNavToggle(e)} target={item.linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                                                                    }
                                                                                                                                                </li>
                                                                                                                                            :
                                                                                                                                            <li className="" key={item.menu_title}>
                                                                                                                                                {
                                                                                                                                                    item.menu_link.includes('http') ?
                                                                                                                                                        <a onClick={(e) => this.RemoveactivatepcNavToggle(e)} target="_blank" href={item.menu_link}><span>{item.menu_title}</span></a>
                                                                                                                                                        :
                                                                                                                                                        <NavLink className="" onClick={(e) => this.RemoveactivatepcNavToggle(e)} target={item.linktype === 'true' ? "_blank" : ''} to={item.menu_link}><span>{item.menu_title}</span></NavLink>
                                                                                                                                                }
                                                                                                                                            </li>

                                                                                                                                    )
                                                                                                                                })
                                                                                                                                    : ''
                                                                                                                            }
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                    )

                                                                                                })
                                                                                                : ''
                                                                                        }
                                                                                    </div>
                                                                                </ul>
                                                                                :
                                                                                ''
                                                                                
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>


                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>


                {/* <div className="modal " id="modal-container-563241993422" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header py-2">
                                        <h5 className="mb-0">Crop and Adjust</h5>
                                       
                                    </div>
                                    <div className="modal-body text-center mx-4">
                                    <img src="https://storage.googleapis.com/xrdynamicplusactivelink/homecarouselbanners/f6b9bb1d09a5dd0bd5a0ad445edf6148.webp?random=202303-06161337" alt="Banner" />
                                    </div>
                                </div>	
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }

}
const mapStateToProps = (state) => ({
    singleuserdata: state.userDetails.singleuser,
    singleuserdataloading: state.userDetails.loading,
})
export default withRouter(connect(mapStateToProps)(SignupHeader));
