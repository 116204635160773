import * as actionTypes from '../types/types';
import {apiBaseUrl} from '../helpers/common';
import {handleResponse} from '../helpers/userServices';
export const fetchdynamicStart = () => {
    return {
        type: actionTypes.FETCH_DYNAMIC_BEGIN,
       
    }
}
export const fetchDynamicDetails = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_SUCCESS,
        payload: data.data
    }
}
export const fetchDynamicFooterDetails = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_FOOTER_SUCCESS,
        payload: data.data
    }
}
export const fetchDynamicPageDetails = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_PAGE_SUCCESS,
        payload: data.data
    }
}
export function getDynamicdetails(id) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-client-details/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicDetails(data))
        });
    }
}
export const fetchDynamicFooterCraousalBegin = (data) => {
    return {
        type: actionTypes.FETCH_FOOTER_CRAOUSAL_BEGIN
    }
}
export const fetchDynamicFooterCraousalSuccess = (data) => {
    return {
        type: actionTypes.FETCH_FOOTER_CRAOUSAL_SUCCESS,
        payload: data.data
    }
}
export function getDynamicFooterCraousaldetails() {
    return dispatch => {
        dispatch(fetchDynamicFooterCraousalBegin());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-footer-craousal`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicFooterCraousalSuccess(data))
        });
    }
}
export function getDynamicfooterdetails() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-footer-details`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicFooterDetails(data))
        });
    }
}

export const fetchDynamicPlatformImages = (data) => {
    return {
        type: actionTypes.FETCH_DYNAMIC_PLATFORM_IMAGES_SUCCESS,
        payload: data.data
    }
}
export function getDynamicplatformimages() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-dynamic-platformimages-details`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicPlatformImages(data))
        });
    }
}

export function getDynamicplatformimagesbypage(page) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-dynamic-platformimages-bypage/${page}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicPlatformImages(data))
        });
    }
}

export function getdynamicSociallink() {
    return dispatch => {
        dispatch(fetchdynamicStart());
       
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-dynamic-social-link`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {

            dispatch(fetchGetDynamicSociallink(data))
        });
    }
}
export const fetchGetDynamicSociallink = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_SOCIAL_LINK,
        payload: data.data
    }
}
export function getDynamicPageContent(id,plat=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {

    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-page-content/${id}/${plat}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicPageDetails(data))
        });
    }
}
export const fetchDynamicOptionsDetails = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_OPTIONS_SUCCESS,
        payload: data.data
    }
}
export function getDynamicOptions() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-page-options`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicOptionsDetails(data))
        });
    }
}

export const fetchDynamicInfoTips = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_INFOTIPS,
        payload: data.data
    }
}
export function getDynamicInfoTips(id) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-dynamic-tours/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicInfoTips(data))
        });
    }
}

export const fetchDynamicInfoTipsAdmin = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_INFOTIPS_ADMIN,
        payload: data.data
    }
}
export function getDynamicInfoTipsAdmin(id) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-dynamic-infotips/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchDynamicInfoTipsAdmin(data))
        });
    }
}

export const fetchusermenuStart = () => {
    return {
        type: actionTypes.FETCH_MENUUSER_BEGIN
    }
}

export const fetchusermenuSuccess = (data) => {
    return {
        type: actionTypes.FETCH_MENUUSER_SUCCESS,
        payload: data.data
    }
}
 
export function getAlluserMenus() {
    return dispatch => {
        dispatch(fetchusermenuStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-menu`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchusermenuSuccess(data));
        });
    }
}


export const fetchMenuAdminStart = () => {
    return {
        type: actionTypes.FETCH_MENU_ADMIN_BEGIN
    }
}

export const fetchMenuAdminSuccess = (data) => {
    return {
        type: actionTypes.FETCH_MENU_ADMIN_SUCCESS,
        payload: data.data
    }
}

export function getAllAdminDataMenus() {
    return dispatch => {
        dispatch(fetchMenuAdminStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-menu`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchMenuAdminSuccess(data));
        });
    }
}
export const fetchAllDynamicCssVersionSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_DYNAMIC_VERSION_SUCCESS,
        payload: data.data
    }
}

export function getAllDynamicCssVersions() {
    return dispatch => {
        dispatch(fetchMenuAdminStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-all-dynamic-css-version`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllDynamicCssVersionSuccess(data));
        });
    }
}

export const fetchPageDataStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_PAGE_BEGIN
    }
}
export const fetchPageDataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_PAGE_SUCCESS,
        payload: data.data
    }
}
export function getPageData(slug,plat=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchPageDataStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-page-content/${slug}/${plat}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchPageDataSuccess(data))
        });
    }
}



export const fetchsecureclientdataStart = () => {
    return {
        type: actionTypes.FETCH_SECURE_CLIENT_BEGIN
    }
}
export const fetchsecureclientdataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SECURE_CLIENT_SUCCESS,
        payload: data.data
    }
}
export function getsecureclientData(plat=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchsecureclientdataStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-secure-client-data/${plat}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(fetchsecureclientdataSuccess(data))
        });
    }
}





export const createsecuresignedurlStart = () => {
    return {
        type: actionTypes.CREATE_SECURE_SIGNEDURL_BEGIN
    }
}
export const createsecuresignedurlstartSuccess = (data) => {
    return {
        type: actionTypes.CREATE_SECURE_SIGNEDURL_SUCCESS,
        payload: data.data
    }
}
export function createsecuresignedurlData(file_location,filename) {
    return dispatch => {
        dispatch(createsecuresignedurlStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/create-signed-url/${file_location}/${filename}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(createsecuresignedurlstartSuccess(data))
        });
    }
}

export const getsingleFeedStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_FEED_BEGIN
    }
}
export const fetchSingleFeedSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_FEED_SUCCESS,
        payload: data.data
    }
}
export function getSinglefeed(feed_id,user_id=0,comment_for="feed") {
    return dispatch => {
        dispatch(getsingleFeedStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-feed/${feed_id}/${user_id}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 
            'X-Auth-Token': `${localStorage.getItem('token')}` 
            }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(fetchSingleFeedSuccess(data))
        });
    }
}

export const fetchSingleFeedCommentsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_FEED_COMMENTS_SUCCESS,
        payload: data.data
    }
}
export function getsingleFeedcomments(feed_id,user_id=0,comment_for="feed") {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-feed-comments/${feed_id}/${user_id}/${comment_for}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`, 
            // 'X-Auth-Token': `${localStorage.getItem('token')}`
         }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchSingleFeedCommentsSuccess(data))
        });
    }
}

export function getsingleFeedcomments_home(feed_id,user_id=0,comment_for="feed") {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-feed-comments-home/${feed_id}/${user_id}/${comment_for}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 
            'X-Auth-Token': `${localStorage.getItem('token')}`
         }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchSingleFeedCommentsSuccess(data))
        });
    }
}


export const fetchFeedsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_FEEDS_SUCCESS,
        payload: data.data
    }
}

export function getAllFeeds(type, user_id = 0, searchcategory = 0) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allfeeds/${type}/${user_id}/${searchcategory}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(fetchFeedsSuccess(data))
        });
    }
}






export const fetchFeedsCustomSuccess = (data) => {
    return {
        type: actionTypes.FETCH_CUSTOM_FEEDS_SUCCESS,
        payload: data.data
    }
}
export function getAllCustomFeeds() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allcustomfeeds`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(fetchFeedsCustomSuccess(data))
        });
    }
}


export const fetchCSVDataBegin = (data) => {
    return {
        type: actionTypes.FETCH_CSV_DATA_BEGIN
    }
}
export const fetchCSVDataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_CSV_DATA_SUCCESS,
        payload: data.data
    }
}
export function getCSVdatadetails(pagenum) {
    return dispatch => {
        dispatch(fetchCSVDataBegin());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-CSV-data/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchCSVDataSuccess(data))
        });
    }
}




export const fetchhomepagealldataStart = () => {
    return {
        type: actionTypes.FETCH_HOMEPAGE_ALLDATA_BEGIN
    }
}
export const fetchhomepagealldataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_HOMEPAGE_ALLDATA_SUCCESS,
        payload: data.data
    }
}
export function gethomepageallData(plat= `${process.env.REACT_APP_PLATFORM_SUFFIX}`,user_id=0) {
    return dispatch => {
        dispatch(fetchhomepagealldataStart());
        // const request = new Request(`https://esports.activelink.network/public/get-homepage-all-data/${plat}/${user_id}`, {
            const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-homepage-all-data/${plat}/logout/${user_id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            
            dispatch(fetchhomepagealldataSuccess(data))
        });
    }
}
export const fetchEmailTempdataStart = () => {
    return {
        type: actionTypes.FETCH_EMAIL_TEMP_BEGIN
    }
}
export const fetchEmailTempSuccess = (data) => {
    return {
        type: actionTypes.FETCH_EMAIL_TEMP_SUCCESS,
        payload: data.data
    }
}
export function getEmailTempdata(emailtype) {
    return dispatch => {
        dispatch(fetchEmailTempdataStart());
            const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-email-template/${emailtype}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchEmailTempSuccess(data))
        });
    }
}

export const fetchClientDetails = (data) => {
    return {
        type: actionTypes.FETCH_CLIENT_DETAILS_SUCCESS,
        payload: data.data
    }
}

export function getClientDetails() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/client-details`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchClientDetails(data))
        });
    }
}

export const fetchLogs = (data) => {
    return {
        type: actionTypes.FETCH_CLIENT_LOGS,
        payload: data.data
    }
}

export function getAdminLogs(id) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/logout-log/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchLogs(data))
        });
    }
}

export const fetchActivityCenterData = (data) => {
    return {
        type: actionTypes.FETCH_ACTIVE_CENTER_DATA,
        payload: data.data
    }
}

export function getActivityCenterData(id) {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/activity-center-data/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchActivityCenterData(data))
        });
    }
}

export const fetchAllPetitions = (data) => {
    return {
        type: actionTypes.FETCH_ALL_PETITIONS,
        payload: data.data
    }
}

export function getAllPetitionsData() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-petitions`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllPetitions(data))
        });
    }
}

export const fetchAllHighlights = (data) => {
    return {
        type: actionTypes.FETCH_ALL_HIGHLIGHTS,
        payload: data.data
    }
}

export function getAllHighlights() {
    return dispatch => {
        dispatch(fetchdynamicStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/all-highlight`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllHighlights(data))
        });
    }
}