export const FETCH_DATA_BEGIN   = 'FETCH_DATA_BEGIN';
export const FETCH_GAMES_BEGIN = 'FETCH_GAMES_BEGIN';
export const FETCH_DATA_BEGIN_SINGLE_TOUR = 'FETCH_DATA_BEGIN_SINGLE_TOUR';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_SINGLE_GAME_SUCCESS = 'FETCH_SINGLE_GAME_SUCCESS';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_ORDER_SUCCESS  = 'FETCH_ORDER_SUCCESS';
export const FETCH_SINGLE_ORDER_SUCCESS ='FETCH_SINGLE_ORDER_SUCCESS';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const FETCH_ALL_USERS_ORDER_SUCCESS = 'FETCH_ALL_USERS_ORDER_SUCCESS';
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS';
export const FETCH_IVR_SUCCESS  = 'FETCH_IVR_SUCCESS';
export const FETCH_HOURS_SUCCESS = 'FETCH_HOURS_SUCCESS';
export const FETCH_STICKETS_SUCCESS = 'FETCH_STICKETS_SUCCESS';
export const FETCH_SINGLE_STICKET_SUCCESS = 'FETCH_SINGLE_STICKET_SUCCESS';
export const FETCH_ALL_USERS_STICKETS_SUCCESS = 'FETCH_ALL_USERS_STICKETS_SUCCESS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_ALL_TRANSACTIONS_SUCCESS = 'FETCH_ALL_TRANSACTIONS_SUCCESS';
export const FETCH_SUMBYMONTH_TRANSACTIONS_SUCCESS = 'FETCH_SUMBYMONTH_TRANSACTIONS_SUCCESS';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_EXTENSION_SUCCESS = 'FETCH_EXTENSION_SUCCESS';
export const FETCH_EXTENSION_SEARCH_SUCCESS = 'FETCH_EXTENSION_SEARCH_SUCCESS';
export const FETCH_TAB = 'FETCH_TAB';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_TEAM_SUCCESS = 'FETCH_TEAM_SUCCESS';
export const FETCH_SPECIFIC_TEAM = 'FETCH_SPECIFIC_TEAM';
export const FETCH_ALL_USERS_TEAM_SUCCESS = 'FETCH_ALL_USERS_TEAM_SUCCESS';
export const FETCH_SINGLE_TEAM_SUCCESS = 'FETCH_SINGLE_TEAM_SUCCESS';
export const FETCH_SINGLE_TEAM = 'FETCH_SINGLE_TEAM';
export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const FETCH_SINGLE_REWARDS_SUCCESS = 'FETCH_SINGLE_REWARDS_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USERS_COUNT = 'FETCH_USERS_COUNT';

export const FETCH_ACCESS_FANHUB_SUCCESS='FETCH_ACCESS_FANHUB_SUCCESS';
export const FETCH_ACCESS_FANHUB_BEGIN='FETCH_ACCESS_FANHUB_BEGIN'

export const FETCH_ALL_USER_SUCCESS = 'FETCH_ALL_USER_SUCCESS';
export const FETCH_EXTENSION_NOT_IN_TEAM_SUCCESS = 'FETCH_EXTENSION_NOT_IN_TEAM_SUCCESS';
export const FETCH_TOURNAMENTS_SUCCESS = 'FETCH_TOURNAMENTS_SUCCESS';
export const FETCH_ALL_TOURNAMENTS_MATCHES = 'FETCH_ALL_TOURNAMENTS_MATCHES';
export const FETCH_FILTER_TOURNAMENTS_SUCCESS = 'FETCH_FILTER_TOURNAMENTS_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_MATCH_SUCCESS = 'FETCH_MATCH_SUCCESS';
export const FETCH_MATCH_BY_ID_SUCCESS = 'FETCH_MATCH_BY_ID_SUCCESS';
export const FETCH_COUNTBY_MONTH_SUCCESS = 'FETCH_COUNTBY_MONTH_SUCCESS';
export const FETCH_USERS_MATCH_SUCCESS = 'FETCH_USERS_MATCH_SUCCESS';
export const FETCH_SINGLE_TOURNAMENT_SUCCESS = 'FETCH_SINGLE_TOURNAMENT_SUCCESS';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const FETCH_DATA_ACTIVE_MATCHES_SUCCESS = 'FETCH_DATA_ACTIVE_MATCHES_SUCCESS';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENTS_BEGIN = 'FETCH_EVENTS_BEGIN';
export const FETCH_SINGLE_EVENT_SUCCESS = 'FETCH_SINGLE_EVENT_SUCCESS';
export const FETCH_METAS_BEGIN = 'FETCH_METAS_BEGIN';
export const FETCH_META_SUCCESS = 'FETCH_META_SUCCESS';
export const FETCH_COUNTBY_MONTH_TICKETS_SUCCESS = 'FETCH_COUNTBY_MONTH_TICKETS_SUCCESS';
export const FETCH_BRACKETS_BEGIN = 'FETCH_BRACKETS_BEGIN';
export const FETCH_BRACKETS_SUCCESS = 'FETCH_BRACKETS_SUCCESS';
export const FETCH_FILTERED_TOURNAMENTS = 'FETCH_FILTERED_TOURNAMENTS';
export const FETCH_TOURTICKSETS_SUCCESS = 'FETCH_TOURTICKSETS_SUCCESS';
export const FETCH_SINGLE_TOURTICKSETS_SUCCESS = 'FETCH_SINGLE_TOURTICKSETS_SUCCESS';
export const FETCH_BRACKETS_TWO_SUCCESS = 'FETCH_BRACKETS_TWO_SUCCESS';
export const FETCH_NOTIFICATIONS_BEGIN = 'FETCH_NOTIFICATIONS_BEGIN';
export const FETCH_TEAM_NOTI_SUCCESS = 'FETCH_TEAM_NOTI_SUCCESS';
export const FETCH_ENVY_NOTI_SUCCESS = 'FETCH_ENVY_NOTI_SUCCESS';
export const FETCH_DATA_BEGIN_EMAIL = 'FETCH_DATA_BEGIN_EMAIL';
export const SEND_MESSAGE_EMAIL_SUCCESS = 'SEND_MESSAGE_EMAIL_SUCCESS';
export const FETCH_START_POPS = 'FETCH_START_POPS';
export const FETCH_POPS_FINISH_SUCCESS = 'FETCH_POPS_FINISH_SUCCESS';
export const FETCH_DATA_BEGIN_ALL_TOUR = 'FETCH_DATA_BEGIN_ALL_TOUR';
export const FETCH_ALL_TOURNAMENTS_SUCCESS = 'FETCH_ALL_TOURNAMENTS_SUCCESS';
export const FETCH_FILTERED_ALL_TOURNAMENTS = 'FETCH_FILTERED_ALL_TOURNAMENTS';
export const FETCH_ALL_TOURS_SUCCESS = 'FETCH_ALL_TOURS_SUCCESS';
export const FETCH_DATA_BEGIN_ALL_TOURS = 'FETCH_DATA_BEGIN_ALL_TOURS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_DATA_BEGIN_TWO = 'FETCH_DATA_BEGIN_TWO';

export const FETCH_BANNERS_BEGIN = 'FETCH_BANNERS_BEGIN';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_SINGLE_BANNERS_SUCCESS = 'FETCH_SINGLE_BANNERS_SUCCESS';
export const FETCH_JOINED_DATA_BEGIN = 'FETCH_JOINED_DATA_BEGIN';
export const FETCH_JOINED_TEAM_SUCCESS = 'FETCH_JOINED_TEAM_SUCCESS';
export const FETCH_LEAVE_SUCCESS = 'FETCH_LEAVE_SUCCESS';
export const FETCH_ALL_SPECIFIC_TEAM = 'FETCH_ALL_SPECIFIC_TEAM';
export const FETCH_REDEEM_REWARDS_SUCCESS = 'FETCH_REDEEM_REWARDS_SUCCESS';

export const FETCH_LOBBY_BANNER_BEGIN ='FETCH_LOBBY_BANNER_BEGIN'
export const FETCH_LOBBY_BANNER_SUCCESS='FETCH_LOBBY_BANNER_SUCCESS';
export const FETCH_SINGLE_LOBBY_BANNER_SUCCESS='FETCH_SINGLE_LOBBY_BANNER_SUCCESS';
export const FETCH_LOBBY_JOINED_DATA_BEGIN='FETCH_LOBBY_JOINED_DATA_BEGIN';
export const FETCH_LOBBY_JOINED_TEAM_SUCCESS='FETCH_LOBBY_JOINED_TEAM_SUCCESS';
export const FETCH_LOBBY_LEAVE_SUCCESS='FETCH_LOBBY_LEAVE_SUCCESS';
export const FETCH_LOBBY_ALL_SPECIFIC_TEAM='FETCH_LOBBY_ALL_SPECIFIC_TEAM';
export const FETCH_LOBBY_REDEEM_REWARD_SUCCESS='FETCH_LOBBY_REDEEM_REWARD_SUCCESS';

export const FETCH_SPONSOR_DATA_BEGIN = 'FETCH_SPONSOR_DATA_BEGIN';
export const FETCH_SPONSOR_SUCCESS = 'FETCH_SPONSOR_SUCCESS';
export const FETCH_SINGLE_SPONSOR_SUCCESS = 'FETCH_SINGLE_SPONSOR_SUCCESS';
export const FETCH_ALL_HIGH_SCHOOL_SUCCESS = 'FETCH_ALL_HIGH_SCHOOL_SUCCESS';

export const FETCH_NEWS_BEGIN = 'FETCH_NEWS_BEGIN';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_SUBADMIN_SUCCESS = 'FETCH_SUBADMIN_SUCCESS';

export const FETCH_ALL_TEAM_LEADER = 'FETCH_ALL_TEAM_LEADER';

export const FETCH_ACTIVE_CHANNEL_BEGIN = 'FETCH_ACTIVE_CHANNEL_BEGIN';
export const FETCH_ALL_CHANNEL = 'FETCH_ALL_CHANNEL';


export const FETCH_USER_BYSLUG_BEGIN = 'FETCH_USER_BYSLUG_BEGIN';
export const FETCH_USER_BYSLUG_SUCCESS = 'FETCH_USER_BYSLUG_SUCCESS';


// homepage banners

export const FETCH_DESK_BANNERS_SUCCESS = 'FETCH_DESK_BANNERS_SUCCESS';
export const FETCH_MOB_BANNERS_SUCCESS = 'FETCH_MOB_BANNERS_SUCCESS';
export const FETCH_HOME_BANNER_SUCCESS = 'FETCH_HOME_BANNER_SUCCESS';


//lobby banner
 export const FETCH_LOBBY_DESK_BANNER_SUCCESS='FETCH_LOBBY_DESK_BANNER_SUCCESS';
 export const FETCH_LOBBY_MOB_BANNER_SUCCESS='FETCH_LOBBY_MOB_BANNER_SUCCESS';
 export const FETCH_LOBBY_HOME_BANNER_SUCCESS='FETCH_LOBBY_HOME_BANNER_SUCCESS';
 export const FETCH_LOBBY_ALL_DATA_SUCCESS='FETCH_LOBBY_ALL_DATA_SUCCESS';


 //stream
 export const FETCH_STREAM_SUCCESS='FETCH_STREAM_SUCCESS';
 export const FETCH_ALL_STRAM_DATA_SUCCESS='FETCH_ALL_STRAM_DATA_SUCCESS';
 export const FETCH_STREAM_BEGIN='FETCH_STREAM_BEGIN';

// events new

export const FETCH_CURRENT_EVENT_SUCCESS = 'FETCH_CURRENT_EVENT_SUCCESS';
export const FETCH_PREVIOUS_EVENT_SUCCESS = 'FETCH_PREVIOUS_EVENT_SUCCESS';

// admin
export const FETCH_BANNED_USER_SUCCESS = 'FETCH_BANNED_USER_SUCCESS';
export const FETCH_DELETED_USER_SUCCESS = 'FETCH_DELETED_USER_SUCCESS';


//ecommerce

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_PARENT_CATEGORIES_START = 'FETCH_PARENT_CATEGORIES_START';
export const FETCH_PARENT_CATEGORIES_SUCCESS = 'FETCH_PARENT_CATEGORIES_SUCCESS';

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';

export const FETCH_SEARCHED_PRODUCTS_START = 'FETCH_SEARCHED_PRODUCTS_START';
export const FETCH_SEARCHED_PRODUCTS_SUCCESS = 'FETCH_SEARCHED_PRODUCTS_SUCCESS';

export const FETCH_FEATURED_PRODUCTS_START = 'FETCH_FEATURED_PRODUCTS_START';
export const FETCH_FEATURED_PRODUCTS_SUCCESS = 'FETCH_FEATURED_PRODUCTS_SUCCESS';

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_VARIATION_GROUP_START = 'FETCH_VARIATION_GROUP_START';
export const FETCH_VARIATION_GROUP_SUCCESS = 'FETCH_VARIATION_GROUP_SUCCESS';
export const FETCH_VARIATION_START = 'FETCH_VARIATION_START';
export const FETCH_VARIATION_SUCCESS = 'FETCH_VARIATION_SUCCESS';
export const FETCH_GROUPED_VARIATION_START = 'FETCH_GROUPED_VARIATION_START';
export const FETCH_GROUPED_VARIATION_SUCCESS = 'FETCH_GROUPED_VARIATION_SUCCESS';

export const FETCH_CART_START = 'FETCH_CART_START';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';

export const FETCH_ADDRESSES_START = 'FETCH_ADDRESSES_START';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';

export const FETCH_CATEGORY_PRODUCT_START = 'FETCH_CATEGORY_PRODUCT_START';
export const FETCH_CATEGORY_PRODUCT_SUCCESS = 'FETCH_CATEGORY_PRODUCT_SUCCESS';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';

export const FETCH_USER_ORDERS_START = 'FETCH_USER_ORDERS_START';
export const FETCH_USER_ORDERS_SUCCESS = 'FETCH_USER_ORDERS_SUCCESS';

export const FETCH_SINGLE_USER_ORDER_START = 'FETCH_SINGLE_USER_ORDER_START';
export const FETCH_SINGLE_USER_ORDER_SUCCESS = 'FETCH_SINGLE_USER_ORDER_SUCCESS';

export const FETCH_COUPONS_START = 'FETCH_COUPONS_START';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';

export const FETCH_SHOP_SETTINGS_START = 'FETCH_SHOP_SETTINGS_START';
export const FETCH_SHOP_SETTINGS_SUCCESS = 'FETCH_SHOP_SETTINGS_SUCCESS';


export const FETCH_CART_CARTID_SUCCESS = 'FETCH_CART_CARTID_SUCCESS';
export const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';


export const FETCH_DYNAMIC_BEGIN = 'FETCH_DYNAMIC_BEGIN';
export const FETCH_ALL_DYNAMIC_SUCCESS = 'FETCH_ALL_DYNAMIC_SUCCESS';
export const FETCH_ALL_DYNAMIC_FOOTER_SUCCESS = 'FETCH_ALL_DYNAMIC_FOOTER_SUCCESS';
export const FETCH_ALL_DYNAMIC_PAGE_SUCCESS = 'FETCH_ALL_DYNAMIC_PAGE_SUCCESS';

// NFTs

	
export const FETCH_NFT_START = 'FETCH_NFT_START';	
export const FETCH_NFT_SUCCESS = 'FETCH_NFT_SUCCESS';	
export const FETCH_NFT_BYPRICE_SUCCESS = 'FETCH_NFT_BYPRICE_SUCCESS';	
export const FETCH_NFT_BIDS_START = 'FETCH_NFT_BIDS_START';	
export const FETCH_NFT_BIDS_SUCCESS = 'FETCH_NFT_BIDS_SUCCESS';	
export const FETCH_SINGLE_NFT_START = 'FETCH_SINGLE_NFT_START';	
export const FETCH_SINGLE_NFT_SUCCESS = 'FETCH_SINGLE_NFT_SUCCESS';	
export const FETCH_COLLECTIONS_START = 'FETCH_COLLECTIONS_START';	
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';	
export const FETCH_GAS_PRICE_SUCCESS = 'FETCH_GAS_PRICE_SUCCESS';	
export const FETCH_ROYALTY_START = 'FETCH_ROYALTY_START';	
export const FETCH_ROYALTY_SUCCESS = 'FETCH_ROYALTY_SUCCESS';	
	
export const FETCH_ALL_NFT_COLLECTIBLES_SUCCESS = "FETCH_ALL_NFT_COLLECTIBLES_SUCCESS";	
export const FETCH_SINGLE_NFT_COLLECTIBLES_SUCCESS = "FETCH_SINGLE_NFT_COLLECTIBLES_SUCCESS";	
export const FETCH_PLACED_NFT_COLLECTIBLES_SUCCESS = "FETCH_PLACED_NFT_COLLECTIBLES_SUCCESS";	
export const FETCH_PURCHASED_NFTS_BY_WALLET = 'FETCH_PURCHASED_NFTS_BY_WALLET';
	
export const FETCH_SINGLE_MINTED_NFT_COLLECTION = 'FETCH_SINGLE_MINTED_NFT_COLLECTION';
// Coin Payment 
export const FETCH_RATES_START = 'FETCH_RATES_START';
export const FETCH_RATES_SUCCESS = 'FETCH_RATES_SUCCESS';
export const FETCH_ALL_WALLET_TRANSACTIONS_SUCCESS = 'FETCH_ALL_WALLET_TRANSACTIONS_SUCCESS';
export const FETCH_ALL_JOINED_TEAMS_DATA_SUCCESS = 'FETCH_ALL_JOINED_TEAMS_DATA_SUCCESS';
export const FETCH_MY_TOURNAMENTS_SUCCESS = 'FETCH_MY_TOURNAMENTS_SUCCESS';
export const FETCH_ALL_DELETED_TEAM = 'FETCH_ALL_DELETED_TEAM';




export const FETCH_ALL_DYNAMIC_OPTIONS_SUCCESS = 'FETCH_ALL_DYNAMIC_OPTIONS_SUCCESS';
export const FETCH_ALL_DYNAMIC_INFOTIPS = 'FETCH_ALL_DYNAMIC_INFOTIPS';

export const FETCH_ALL_DYNAMIC_INFOTIPS_ADMIN = 'FETCH_ALL_DYNAMIC_INFOTIPS_ADMIN';
export const FETCH_DB_GAMES_SUCCESS = 'FETCH_DB_GAMES_SUCCESS';
export const FETCH_LEADERBOARD_TOURNAMENTS_SUCCESS = 'FETCH_LEADERBOARD_TOURNAMENTS_SUCCESS';
export const FETCH_RELATION_DATA_BEGIN = 'FETCH_RELATION_DATA_BEGIN';
export const FETCH_RELATION_SUCCESS = 'FETCH_RELATION_SUCCESS';


// Challeneges Action

export const FETCH_SINGLE_CHALLENGE_BEGIN = 'FETCH_SINGLE_CHALLENGE_BEGIN';
export const FETCH_SINGLE_CHALLENGE_SUCCESS = 'FETCH_SINGLE_CHALLENGE_SUCCESS';

export const FETCH_CHALLENGES_BEGIN = 'FETCH_CHALLENGES_BEGIN';
export const FETCH_CHALLENGES_SUCCESS = 'FETCH_CHALLENGES_SUCCESS';

export const FETCH_ALL_INTERESTS_APP_BEGIN = 'FETCH_ALL_INTERESTS_APP_BEGIN';
export const FETCH_ALL_INTERESTS_APP_SUCCESS = 'FETCH_ALL_INTERESTS_APP_SUCCESS';

export const FETCH_INFLUENCER_BY_INTEREST_BEGIN = 'FETCH_INFLUENCER_BY_INTEREST_BEGIN';
export const FETCH_INFLUENCER_BY_INTEREST_SUCCESS = 'FETCH_INFLUENCER_BY_INTEREST_SUCCESS';

export const FETCH_HOMEPAGE_CHALLENGES_BEGIN = 'FETCH_HOMEPAGE_CHALLENGES_BEGIN';
export const FETCH_HOMEPAGE_CHALLENGES_SUCCESS = 'FETCH_HOMEPAGE_CHALLENGES_SUCCESS';




export const FETCH_ALL_CHALLENGES_BEGIN = 'FETCH_ALL_CHALLENGES_BEGIN';
export const FETCH_ALL_CHALLENGES_SUCCESS = 'FETCH_ALL_CHALLENGES_SUCCESS';

export const FETCH_SINGLE_CHALLENGE_SLUG_BEGIN = 'FETCH_SINGLE_CHALLENGE_SLUG_BEGIN';
export const FETCH_SINGLE_CHALLENGE_SLUG_SUCCESS = 'FETCH_SINGLE_CHALLENGE_SLUG_SUCCESS';

export const FETCH_SINGLE_CHALLENGE_POST_BEGIN = 'FETCH_SINGLE_CHALLENGE_POST_BEGIN';
export const FETCH_SINGLE_CHALLENGE_POST_SUCCESS = 'FETCH_SINGLE_CHALLENGE_POST_SUCCESS';


export const FETCH_SINGLE_CHALLENGE_USER_SLUG_BEGIN = 'FETCH_SINGLE_CHALLENGE_USER_SLUG_BEGIN';
export const FETCH_SINGLE_CHALLENGE_USER_SLUG_SUCCESS = 'FETCH_SINGLE_CHALLENGE_USER_SLUG_SUCCESS';



export const FETCH_JOINED_PLAYERS_VOTES_BEGIN = 'FETCH_JOINED_PLAYERS_VOTES_BEGIN';
export const FETCH_JOINED_PLAYERS_VOTES_SUCCESS = 'FETCH_JOINED_PLAYERS_VOTES_SUCCESS';





// plans actions
export const FETCH_PLANS_BEGIN = 'FETCH_PLANS_BEGIN';
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';

export const FETCH_SINGLE_PLAN_BEGIN = 'FETCH_SINGLE_PLAN_BEGIN';
export const FETCH_SINGLE_PLAN_SUCCESS = 'FETCH_SINGLE_PLAN_SUCCESS';

export const FETCH_USER_REWARD_BEGIN = 'FETCH_USER_REWARD_BEGIN';
export const FETCH_USER_REWARD_SUCCESS = 'FETCH_USER_REWARD_SUCCESS';

export const FETCH_WINNER_REWARD_BEGIN = 'FETCH_WINNER_REWARD_BEGIN';
export const FETCH_WINNER_REWARD_SUCCESS = 'FETCH_WINNER_REWARD_SUCCESS';


export const FETCH_SINGLE_REWARD_BEGIN = 'FETCH_SINGLE_REWARD_BEGIN';
export const FETCH_SINGLE_REWARD_SUCCESS = 'FETCH_SINGLE_REWARD_SUCCESS';

export const FETCH_SINGLE_PAGE_BEGIN = 'FETCH_SINGLE_PAGE_BEGIN';
export const FETCH_SINGLE_PAGE_SUCCESS = 'FETCH_SINGLE_PAGE_SUCCESS';

export const FETCH_USER_PROFILE_BYSLUG_BEGIN = 'FETCH_USER_PROFILE_BYSLUG_BEGIN';
export const FETCH_USER_PROFILE_BYSLUG_SUCCESS = 'FETCH_USER_PROFILE_BYSLUG_SUCCESS';

export const FETCH_SINGLE_MATCH_BEGIN = 'FETCH_SINGLE_MATCH_BEGIN';
export const FETCH_SINGLE_MATCH_SUCCESS = 'FETCH_SINGLE_MATCH_SUCCESS';

export const FETCH_CHALLENGE_PLAYERS_BEGIN = 'FETCH_CHALLENGE_PLAYERS_BEGIN';
export const FETCH_CHALLENGE_PLAYERS_SUCCESS = 'FETCH_CHALLENGE_PLAYERS_SUCCESS';


export const FETCH_TRACK_BEGIN = 'FETCH_TRACK_BEGIN';
export const SIGNUP_TRACK_SUCCESS = 'SIGNUP_TRACK_SUCCESS';
export const CHALLENGE_CREATION_TRACK_SUCCESS = 'CHALLENGE_CREATION_TRACK_SUCCESS';


// stripe cards actions
export const FETCH_CARDS_BEGIN = 'FETCH_CARDS_BEGIN';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';

export const FETCH_SINGLE_CARD_BEGIN = 'FETCH_SINGLE_CARD_BEGIN';
export const FETCH_SINGLE_CARD_SUCCESS = 'FETCH_SINGLE_CARD_SUCCESS';


export const FETCH_SECURE_CLIENT_BEGIN = 'FETCH_SECURE_CLIENT_BEGIN';
export const FETCH_SECURE_CLIENT_SUCCESS = 'FETCH_SECURE_CLIENT_SUCCESS';

// --------- wallet types--------------------------------
export const FETCH_LIMITED_COLLECTIBELS = 'FETCH_LIMITED_COLLECTIBELS';
export const FETCH_LIMITED_WALLET_ACTIVITIES = 'FETCH_LIMITED_WALLET_ACTIVITIES';
export const FETCH_USER_ALL_WALLETS = 'FETCH_USER_ALL_WALLETS';
export const FETCH_USER_ALL_WALLETS_BY_GROUP = 'FETCH_USER_ALL_WALLETS_BY_GROUP';

export const FETCH_WALLET_INFORMATION_START = 'FETCH_WALLET_INFORMATION_START';
export const FETCH_WALLET_INFORMATION = 'FETCH_WALLET_INFORMATION';
export const FETCH_WALLET_ACTIVITIES = 'FETCH_WALLET_ACTIVITIES';
export const FETCH_WALLET_USER_DETAILS = 'FETCH_WALLET_USER_DETAILS';



export const FETCH_MENU_BEGIN = 'FETCH_MENU_BEGIN';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_DYNAMIC_SIDEBAR_MENU_SUCCESS = 'FETCH_DYNAMIC_SIDEBAR_MENU_SUCCESS';
export const FETCH_ADMIN_MENU_BEGIN = 'FETCH_ADMIN_MENU_BEGIN';
export const FETCH_ADMIN_MENU_SUCCESS = 'FETCH_ADMIN_MENU_SUCCESS';
export const FETCH_ALL_SUBMENU_SUCCESS = 'FETCH_ALL_SUBMENU_SUCCESS';
export const FETCH_ALL_PERMISSIONS_SUCCESS = 'FETCH_ALL_PERMISSIONS_SUCCESS';
export const FETCH_USER_MENU_SUCCESS = 'FETCH_USER_MENU_SUCCESS';

// --------- communities types--------------------------------
export const FETCH_COMMUNITY_START = 'FETCH_COMMUNITY_START';
export const FETCH_ALL_COMMUNITY_SUCCESS = 'FETCH_ALL_COMMUNITY_SUCCESS';
export const FETCH_COMMUNITY_TOURS_SUCCESS = 'FETCH_COMMUNITY_TOURS_SUCCESS';
export const FETCH_COMMUNITY_TOURS_SUCCESS_FOR_COMMUNITY = 'FETCH_COMMUNITY_TOURS_SUCCESS_FOR_COMMUNITY';
export const FETCH_COMMUNITY_EVENT_SUCCESS = 'FETCH_COMMUNITY_EVENT_SUCCESS';
export const FETCH_COMMUNITY_USERS_TEAM_SUCCESS = 'FETCH_COMMUNITY_USERS_TEAM_SUCCESS';
export const FETCH_ALL_COMMUNITY_LEADER_SUCCESS = 'FETCH_ALL_COMMUNITY_LEADER_SUCCESS';
export const FETCH_COMMUNITY_MEMBER_SUCCESS = 'FETCH_COMMUNITY_MEMBER_SUCCESS';
export const FETCH_BANNED_MEMBER_SUCCESS = 'FETCH_BANNED_MEMBER_SUCCESS';
export const FETCH_DELETED_MEMBERS_SUCCESS = 'FETCH_DELETED_MEMBERS_SUCCESS';
export const FETCH_COMMUNITY_DRAFT_TOURNAMENT = 'FETCH_COMMUNITY_DRAFT_TOURNAMENT';
export const FETCH_ALL_COMMUNITY_TEAM = 'FETCH_ALL_COMMUNITY_TEAM';
export const FETCH_COMMUNITY_TOURTICKSETS = 'FETCH_COMMUNITY_TOURTICKSETS';
export const FETCH_COMMUNITY_INACTIVE_TOURS = 'FETCH_COMMUNITY_INACTIVE_TOURS';
export const FETCH_SINGLE_COMMUNITY = 'FETCH_SINGLE_COMMUNITY';
export const FETCH_SINGLE_COMMUNITY_FOR_ALL = 'FETCH_SINGLE_COMMUNITY_FOR_ALL';
export const FETCH_ALL_COMMUNITY_EVENT_SUCCESS = 'FETCH_ALL_COMMUNITY_EVENT_SUCCESS';
export const FETCH_ALL_COMM_EVENT_SUCCESS = 'FETCH_ALL_COMM_EVENT_SUCCESS';
export const FETCH_ALL_COMM_TEAMS_SUCCESS = 'FETCH_ALL_COMM_TEAMS_SUCCESS';
export const FETCH_ALL_COMM_GALLERY_SUCCESS = 'FETCH_ALL_COMM_GALLERY_SUCCESS';
export const FETCH_COMM_SETTINGS_SUCCESS = 'FETCH_COMM_SETTINGS_SUCCESS';
export const FETCH_COMM_REQUESTED_MEMBERS_SUCCESS = 'FETCH_COMM_REQUESTED_MEMBERS_SUCCESS';
export const FETCH_COMMUNITY_SIX_SERIES = 'FETCH_COMMUNITY_SIX_SERIES';
export const FETCH_SIX_SERIES = 'FETCH_SIX_SERIES';
export const FETCH_SUPERADMIN_COMMUNITY_SUCCESS = 'FETCH_SUPERADMIN_COMMUNITY_SUCCESS';

//------------tournaments--------------------------------
export const FETCH_DATA_BEGIN_ARCHIVED_TOUR = 'FETCH_DATA_BEGIN_ARCHIVED_TOUR';
export const FETCH_ARCHIVED_TOURNAMENTS_SUCCESS = 'FETCH_ARCHIVED_TOURNAMENTS_SUCCESS';
export const FETCH_EXTERNALURL_JOINED_SUCCESS = 'FETCH_EXTERNALURL_JOINED_SUCCESS';
export const FETCH_DRAFT_TOURNAMENT = 'FETCH_DRAFT_TOURNAMENT';
export const FETCH_TOURNAMENT_SERIES = 'FETCH_TOURNAMENT_SERIES';
export const FETCH_SINGLE_TOURNAMENT_SERIES = 'FETCH_SINGLE_TOURNAMENT_SERIES';
export const FETCH_PLATFORM_VARIANTS = 'FETCH_PLATFORM_VARIANTS';
export const FETCH_ALL_PLATFORM_VARIANTS = 'FETCH_ALL_PLATFORM_VARIANTS';
export const FETCH_ALL_DRAFT_TOURNAMENT = 'FETCH_ALL_DRAFT_TOURNAMENT';
export const FETCH_ALL_SORT_TOURNAMENT = 'FETCH_ALL_SORT_TOURNAMENT';
export const FETCH_ALL_SORT_DRAFT_TOURNAMENT = 'FETCH_ALL_SORT_DRAFT_TOURNAMENT';
export const FETCH_ALL_SORT_ARCHIVED_TOURNAMENT = 'FETCH_ALL_SORT_ARCHIVED_TOURNAMENT';
export const FETCH_ALL_TOURS_COMM_SUCCESS = 'FETCH_ALL_TOURS_COMM_SUCCESS';
export const FETCH_ALL_TOURS_SERIES_SUCCESS = 'FETCH_ALL_TOURS_SERIES_SUCCESS';

//-------------xr dynamic---------------
export const CREATE_SECURE_SIGNEDURL_BEGIN = 'CREATE_SECURE_SIGNEDURL_BEGIN';
export const CREATE_SECURE_SIGNEDURL_SUCCESS = 'CREATE_SECURE_SIGNEDURL_SUCCESS';
export const FETCH_ALL_DYNAMIC_SOCIAL_LINK = 'FETCH_ALL_DYNAMIC_SOCIAL_LINK';
export const FETCH_ALL_DYNAMIC_VERSION_SUCCESS = 'FETCH_ALL_DYNAMIC_VERSION_SUCCESS';
export const FETCH_CUSTOM_FEEDS_SUCCESS = 'FETCH_CUSTOM_FEEDS_SUCCESS';
export const FETCH_DYNAMIC_PLATFORM_IMAGES_SUCCESS = 'FETCH_DYNAMIC_PLATFORM_IMAGES_SUCCESS';
export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS';
export const FETCH_FOOTER_CRAOUSAL_BEGIN = 'FETCH_FOOTER_CRAOUSAL_BEGIN';
export const FETCH_FOOTER_CRAOUSAL_SUCCESS = 'FETCH_FOOTER_CRAOUSAL_SUCCESS';
export const FETCH_MENUUSER_BEGIN = 'FETCH_MENUUSER_BEGIN';
export const FETCH_MENUUSER_SUCCESS = 'FETCH_MENUUSER_SUCCESS';
export const FETCH_MENU_ADMIN_BEGIN = 'FETCH_MENU_ADMIN_BEGIN';
export const FETCH_MENU_ADMIN_SUCCESS = 'FETCH_MENU_ADMIN_SUCCESS';
export const FETCH_SINGLE_FEED_BEGIN = 'FETCH_SINGLE_FEED_BEGIN';
export const FETCH_SINGLE_FEED_COMMENTS_SUCCESS = 'FETCH_SINGLE_FEED_COMMENTS_SUCCESS';
export const FETCH_SINGLE_FEED_SUCCESS = 'FETCH_SINGLE_FEED_SUCCESS';
export const FETCH_ACTIVE_DATA_BEGIN = 'FETCH_ACTIVE_DATA_BEGIN';
export const FETCH_ADMINVIDEOS_SUCCESS = 'FETCH_ADMINVIDEOS_SUCCESS';
export const FETCH_ADMIN_BY_USER_SUCCESS = 'FETCH_ADMIN_BY_USER_SUCCESS';
export const FETCH_ADMIN_EXCLUSIVE_VIDEOS_SUCCESS = 'FETCH_ADMIN_EXCLUSIVE_VIDEOS_SUCCESS';
export const FETCH_ADMIN_PREFERENCES = 'FETCH_ADMIN_PREFERENCES';
export const FETCH_ADMIN_USERS_SUCCESS = 'FETCH_ADMIN_USERS_SUCCESS';
export const FETCH_ADMIN_USER_VIDEOS_SUCCESS = 'FETCH_ADMIN_USER_VIDEOS_SUCCESS';
export const FETCH_ALL_ACTIVE_USERS = 'FETCH_ALL_ACTIVE_USERS';
export const FETCH_ALL_COLLECTIBELS = 'FETCH_ALL_COLLECTIBELS';
export const FETCH_ALL_SUPERADMIN_SUCCESS = 'FETCH_ALL_SUPERADMIN_SUCCESS';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_EXCLUSIVE_VIDEOS_SUCCESS = 'FETCH_EXCLUSIVE_VIDEOS_SUCCESS';
export const FETCH_LEADERBOARD_DATA_SUCCESS = 'FETCH_LEADERBOARD_DATA_SUCCESS';
export const FETCH_RELATED_EXCLUSIVE_VIDEO_SUCCESS = 'FETCH_RELATED_EXCLUSIVE_VIDEO_SUCCESS';
export const FETCH_SINGLE_EXCLUSIVE_VIDEO_BEGIN = 'FETCH_SINGLE_EXCLUSIVE_VIDEO_BEGIN';
export const FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_BEGIN = 'FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_BEGIN';
export const FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_SUCCESS = 'FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_SUCCESS';
export const FETCH_SINGLE_EXCLUSIVE_VIDEO_SUCCESS = 'FETCH_SINGLE_EXCLUSIVE_VIDEO_SUCCESS';
export const FETCH_TOP_THREE_PLAYER_SUCCESS = 'FETCH_TOP_THREE_PLAYER_SUCCESS';
export const FETCH_USERS_HAHSTAGS = 'FETCH_USERS_HAHSTAGS';
export const FETCH_SINGLE_SUBSCHOOL_SUCCESS = 'FETCH_SINGLE_SUBSCHOOL_SUCCESS';
export const FETCH_SUBSCHOOLS_MOBILE_SUCCESS = 'FETCH_SUBSCHOOLS_MOBILE_SUCCESS';
export const FETCH_SUBSCHOOLS_PC_SUCCESS = 'FETCH_SUBSCHOOLS_PC_SUCCESS';
export const FETCH_SUBSCHOOLS_SUCCESS = 'FETCH_SUBSCHOOLS_SUCCESS';
export const FETCH_ALL_USER_TRANSACTIONS_SUCCESS = 'FETCH_ALL_USER_TRANSACTIONS_SUCCESS';

export const FETCH_ALL_FILTER_GAME_BY_CATEGORY_SUCCESS = 'FETCH_ALL_FILTER_GAME_BY_CATEGORY_SUCCESS';
export const FETCH_ALL_GAMERTAG_USER = 'FETCH_ALL_GAMERTAG_USER';
export const FETCH_ALL_GAME_CATEGORY_SUCCESS = 'FETCH_ALL_GAME_CATEGORY_SUCCESS';
export const FETCH_COMINGSOON_GAMES_SUCCESS = 'FETCH_COMINGSOON_GAMES_SUCCESS';
export const FETCH_HOMEPAGE_GAMES_SUCCESS = 'FETCH_HOMEPAGE_GAMES_SUCCESS';
export const FETCH_HOMEPAGE_LEADER_GAMES_SUCCESS = 'FETCH_HOMEPAGE_LEADER_GAMES_SUCCESS';
export const FETCH_HOMEPAGE_REGISTER_IMAGE_SUCCESS = 'FETCH_HOMEPAGE_REGISTER_IMAGE_SUCCESS';
export const FETCH_SINGLE_NEWS_BEGIN = 'FETCH_SINGLE_NEWS_BEGIN';
export const FETCH_SINGLE_NEWS_SUCCESS = 'FETCH_SINGLE_NEWS_SUCCESS';
export const FETCH_TRENDING = 'FETCH_TRENDING';
export const FETCH_LATEST_EXCLUSIVE_VIDEO = 'FETCH_LATEST_EXCLUSIVE_VIDEO';

export const FETCH_CSV_DATA_BEGIN = 'FETCH_CSV_DATA_BEGIN';
export const FETCH_CSV_DATA_SUCCESS = 'FETCH_CSV_DATA_SUCCESS';


export const FETCH_HOMEPAGE_ALLDATA_BEGIN = 'FETCH_CUSTOM_FEEDS_SUCCESS';
export const FETCH_HOMEPAGE_ALLDATA_SUCCESS = 'FETCH_ALL_USER_TRANSACTIONS_SUCCESS';

//---------communication layer---------------
export const FETCH_CHAT_USERS_DATA = 'FETCH_CHAT_USERS_DATA';
export const FETCH_DATA_BEGIN_CHAT = 'FETCH_DATA_BEGIN_CHAT';
//---------communication layer---------------
export const FETCH_RESTRICTED_USERS_SUCCESS = 'FETCH_RESTRICTED_USERS_SUCCESS';
export const FETCH_USER_DASHBOARD_DETAILS = 'FETCH_USER_DASHBOARD_DETAILS';
export const FETCH_ROUND_ROBIN_BRACKET_SUCCESS = 'FETCH_ROUND_ROBIN_BRACKET_SUCCESS';
export const FETCH_START_ROUND_ROBIN = 'FETCH_START_ROUND_ROBIN';

export const FETCH_ALL_PLAYLIST_SUCCESS = 'FETCH_ALL_PLAYLIST_SUCCESS';

export const FETCH_COMMUNITY_SUCCESS = 'FETCH_COMMUNITY_SUCCESS';
export const FETCH_USER_ALL_WALLETS_FANHUB = 'FETCH_USER_ALL_WALLETS_FANHUB';
export const FETCH_EMAIL_TEMP_BEGIN = 'FETCH_EMAIL_TEMP_BEGIN';
export const FETCH_EMAIL_TEMP_SUCCESS = 'FETCH_EMAIL_TEMP_SUCCESS';

export const FETCH_CLIENT_DETAILS_SUCCESS = 'FETCH_CLIENT_DETAILS_SUCCESS';
export const FETCH_CLIENT_LOGS = 'FETCH_CLIENT_LOGS';
export const FETCH_HELP_CENTER_MENU_SUCCESS = 'FETCH_HELP_CENTER_MENU_SUCCESS';
export const FETCH_SUPPORT_TICKET_SUCCESS = 'FETCH_SUPPORT_TICKET_SUCCESS';
export const FETCH_SINGLE_HELP_CENTER_MENU_SUCCESS = 'FETCH_SINGLE_HELP_CENTER_MENU_SUCCESS';
export const FETCH_ACTIVE_CENTER_DATA = 'FETCH_ACTIVE_CENTER_DATA';
export const FETCH_ALL_PETITIONS = 'FETCH_ALL_PETITIONS';
export const FETCH_ALL_VARIATION_SUCCESS = 'FETCH_ALL_VARIATION_SUCCESS';
export const FETCH_USER_DETAIL_HUB_SUCCESS = 'FETCH_USER_DETAIL_HUB_SUCCESS';
export const FETCH_ALL_TEAM_AVATARS = 'FETCH_ALL_TEAM_AVATARS';
export const FETCH_ALL_HIGHLIGHTS = 'FETCH_ALL_HIGHLIGHTS';



