import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import parseJwt, { apiBaseUrl, logo_dark, logo_light, deleteRecordwithID, banner_loader } from '../../store/helpers/common';
import XRSwitch from './../../components/xrgames/XRSwitch';
import { getSingleUserdetails } from "../../store/actions/userActions";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getAdminLogs } from "../../store/actions/XrDynamicAction";
import $, { event } from 'jquery';
class XRHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            light_theme: true,
            ifluencer: false,
            visiter: false,
            activeUser:false,
            user_id: "",
            firstname: '',
            lastname: '',
            nickname: '',
            userdata: "",
            profilepic: "",
            loggedout: false,
            cggurl: '',
            redirect_url: '/signup'
        }
    }
    componentDidMount() {
        const cggurl = this.props.location.pathname;
        this.setState({
            cggurl: cggurl,
        })
        const location = this.state.cggurl;
        this.props.history.listen(() => {
            this.changeurl(window.location.pathname)
        })

        if (localStorage.getItem('token')) {
            if (localStorage.getItem('token') === 'undefined') {
                this.setState({
                    session_msg: 'Session is expired, Please Login Again.'
                })

                setTimeout(function () {
                    localStorage.clear('token');
                    localStorage.clear('session_expired');
                    this.setState({
                        session_msg: ""
                    })
                }.bind(this), 5000);
            } else {
                const token = localStorage.getItem("token");
                const currdetails = parseJwt(localStorage.getItem('token'));
                const redirectpage = currdetails.rdrt
                const userstatus = currdetails.sat;
                const role = currdetails.urxrs;
                console.log(role,"userrole");

                if (userstatus === 'B@$$56fhhab45NN') {
                    this.setState({ redirect_url: '/Banned' })

                } else if (userstatus === 'De$%Let90dTe@') {
                    // this.props.history.push('/Deleted');
                    this.setState({ redirect_url: '/Deleted' })
                } else {
                    if (redirectpage === 'fan') {
                        // this.props.history.push('/role-selection');
                        this.setState({ redirect_url: '/verifysignup' })
                    }
                    else if (redirectpage === 'plan') {
                        // this.props.history.push('/plans');
                        this.setState({ redirect_url: '/plans' })
                    }
                    else if (redirectpage === 'wal') {
                        // this.props.history.push('/my-wallet');
                        this.setState({ redirect_url: '/my-wallet' })
                    } else {
                        if (role === '$Us&er67l@vi%eRst76Heri7') {
                            // this.props.history.push('/activity-center');
                            this.setState({ redirect_url: '/activity-center' })
                        }
                        else if (role === '#u$43*70&78h7') {
                            // this.props.history.push('/'+location);
                            // var urlredirect = '/' + location;
                            // this.setState({ redirect_url: urlredirect })
                            this.setState({ redirect_url: '/activity-center' })
                        } else if (role === '$Us&er67l@vi%eRst76Heri7') {
                            // this.props.history.push('/activity-center');
                            this.setState({ redirect_url: '/activity-center' })
                        } else {
                            // this.props.history.push('/'+location);
                            var urlredirect = '/' + location;
                            this.setState({ redirect_url: urlredirect })
                        }
                    }

                }
                var today = new Date();
                var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

                if (currdetails.exp < Date.now()) {
                    // this.logout();
                } else {
                }




                if (currdetails.urxrs == '$In&f67l@n%eR76Hi7') {
                    this.setState({
                        ifluencer: true
                    })
                } else if (currdetails.urxrs == '$Us&er67l@vi%eRst76Heri7') {

                    this.setState({
                        visiter: true
                    })
                } else if (currdetails.urxrs == '#u$43*70&78h7') {
                    this.setState({
                        userLogin: true,
                        activeUser:true
                        
                    })
                }
            }


        } else {
            this.setState({
                loggedout: true
            })
        }
        if (localStorage.getItem('sc_theme_yf')) {
            this.setState({
                light_theme: localStorage.getItem('sc_theme_yf') == 'true' ? true : false
            })
        } else {
            this.setState({
                light_theme: true
            })
            localStorage.setItem("sc_theme_yf", true);
        }
    }

    changeurl = (newurl) => {
        this.setState({
            cggurl: newurl,
        })
    }

    showLogin = () => {
        this.props.history.push(`/login${this.state.cgurl}`);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.singleuserdata) {
            this.setState({
                firstname: nextProps.singleuserdata.firstname,
                lastname: nextProps.singleuserdata.lastname,
                nickname: nextProps.singleuserdata.nickname,
                profilepic: nextProps.singleuserdata.profile_pic
            })
        }
    }

    myFunction = () => {
        this.setState({ addclass: !this.state.addclass })
        if (this.state.addclass) {
            this.setState({ addclasss: !this.state.addclasss })
        } else {
            this.setState({ addclasss: !this.state.addclasss })
        }
    }

    RemoveactivatepcNav = (e) => {
        $(".navmenu_outerbox").removeClass("pcmenuopen");
        $('.item-quantity').addClass('collapsed');
        $(".navbar-collapse").removeClass("show");
        $("#hambtn").removeClass("change");
        if (this.state.addclass) {
            this.setState({ addclass: false })
        } else {
            this.setState({ addclasss: false })
        }
    }




    handleCheckbox(e) {
        this.setState({
            [e.target.name]: e.target.checked
        })
        // localStorage.setItem('sc_theme_yf', e.target.checked)
        localStorage.setItem('sc_theme_yf', "")
        window.$('.main-content-sec').toggleClass('sc-yf');
    }
    logout = () => {
        localStorage.clear('token');
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.removeItem('encudata');
        localStorage.removeItem('encuid');
        localStorage.removeItem('camethrough');
        localStorage.removeItem('xp_password');
        localStorage.removeItem('zap_wallet_address');
        localStorage.removeItem('network');
        localStorage.removeItem('account_id');
        localStorage.removeItem('wallet_group');
        localStorage.removeItem('PLATFORM_ID_COMMOM_DB');
        localStorage.removeItem('intro-popup');
        window.location.reload('/signup')
    };
    handlebackstep = () => {

        // localStorage.setItem('setshowwalletpagestate',this.props.plandetail.showplandetails)
        localStorage.clear('token');
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.clear()
        window.location.href = 'https://activelink.io';
        // window.location.reload('/signup')
    }

    addDefaultSrcprofile = (ev) => {
        ev.target.src = "https://storage.googleapis.com/xr-sports/xrgames/profileimages/Profile-pic-Activelink.png"
    }


    render() {
        const { loggedout, ifluencer, visiter, light_theme, isLoggedIn ,activeUser} = this.state;
        return (
            <>
                <header className="get-signin bg-light float-start w-100">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg bg-light px-2">
                            <a className="navbar-brand">
                                <img className="logo" src="https://storage.googleapis.com/xr-sports/xrsports_games/mav-header/xr_fav2.png" alt="Activelink Brand Logo" onClick={this.handlebackstep}></img>
                            </a>
                            {/* <button onClick={() => this.myFunction()} className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> */}
                            {/* <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                        </button> */}
                            <button onClick={() => this.myFunction} className="navbar-toggler navbar-toggler-right" id="" type="button" data-bs-toggle="collapse" data-bs-target="#nav" >
                                <div className={`${this.state.addclass ? `change` : this.state.addclasss ? 'changee' : ''}`} id="hambtn"  >
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                            </button>
                            <div className="collapse navbar-collapse" id="nav">
                                {/* <ul className="navbar-nav">
						<li className={light_theme ?`nav-item lightnav`:`nav-item darknav`}>
                            <NavLink className="nav-link" to="/for-creators">For Creators</NavLink>
						</li>
						<li className={light_theme ?`nav-item lightnav`:`nav-item darknav`}>
							 <a className="nav-link" href="#">Pricing</a>
						</li>
                        <li className={light_theme ?`nav-item lightnav`:`nav-item darknav`}>
							 <a className="nav-link" href="#">Resources</a>
						</li>
					</ul> */}

                                <ul className="navbar-nav ms-auto bg-light">
                                    <li className="d-inline d-lg-none">
                                        <button data-bs-toggle="collapse" data-bs-target="#nav" className="close float-right">&times;</button>
                                    </li>
                                    {
                                        isLoggedIn ?
                                            this.state.visiter ?
                                                ''
                                                :
                                                this.state.activeUser?
                                                ''
                                                :
                                                
                                            
                                                this.state.ifluencer
                                                    ?
                                                    <li className="nav-item">
                                                        <NavLink className="nav-link text-truncate" to="/activity-center" onClick={(e) => this.RemoveactivatepcNav(e)}>Dashboard</NavLink>
                                                    </li>
                                                    :
                                                    <li className="nav-item">
                                                        <NavLink className="nav-link text-truncate" to="/user-profile" onClick={(e) => this.RemoveactivatepcNav(e)}>Profile</NavLink>
                                                    </li>
                                            : ''
                                    }

                                    {/* {
                                        this.state.userLogin?
                                        this.state.activeUser?
                                            ''
                                            :
                                            ''
                                            :''
                                    } */}



                                    {
                                        this.state.isLoggedIn 
                                            ?
                                            <>
                                                {/* {
                                             this.state.visiter?
                                                ''
                                                :
                                                <li className="nav-item">
                                                    <NavLink className="nav-link text-truncate" to="/plans" onClick={(e) => this.Removeactinfluencer
                                            } */}
                                                <li className="nav-item logout-desktop-view">
                                                    <a className="nav-link text-truncate" href="/signup" onClick={() => this.logout()}>Logout</a>
                                                </li>
                                                {/* <li className="nav-item go-pro">
                                                    <NavLink className="text-truncate btn primary see-all" to="/for-creators" onClick={(e) => this.RemoveactivatepcNav(e)}>For Creators</NavLink>
                                            </li> */}
                                            </>
                                            :
                                            <>
                                                {/* <li className="nav-item">
                                                    <NavLink className="nav-link text-truncate" to="/creator-community" onClick={(e) => this.RemoveactivatepcNav(e)}>Community</NavLink>
                                            </li> */}
                                                {/* <LazyLoadImage className="nav-item   nav-item me-3  w-15" src="https://storage.googleapis.com/xr-sports/xrgamesgg/homepage/Globe.png?edfewf" alt="Global Social Commerce Platform" /> */}

                                                <li className="nav-item me-lg-3 me-md-3 me-sm-0 me-0">
                                                    <NavLink className="btn btn-primary start-now" to={this.state.redirect_url}>START NOW</NavLink>
                                                </li>
                                                <li className="nav-item mt-lg-0 mt-md-0 mt-sm-3 mt-3">
                                                    <NavLink className="btn btn-primary sign-in" to={this.state.cggurl.includes('signup') ? `/${this.state.cggurl}` : `/signup`} onClick={(e) => this.RemoveactivatepcNav(e)}>LOGIN</NavLink>
                                                </li>
                                                {/* <li className="nav-item go-pro mr-0">
                                                    <NavLink className="text-truncate btn primary see-all" to="/for-creators" onClick={(e) => this.RemoveactivatepcNav(e)}>For Creators</NavLink>
                                            </li> */}
                                                {/* <li className="nav-item influencers-categories">
                                                    <NavLink className="btn primary see-all" to="/go-pro">For Influencers</NavLink>
                                            </li> */}

                                            </>
                                    }
                                    {
                                        this.state.isLoggedIn
                                            ?
                                            <>
                                                <li className="nav-item border-left-mobile">
                                                    {/* <a className="nav-link text-truncate text-space-link vkkk" href={this.state.ifluencer ? `/${this.state.nickname}` : this.state.userLogin ? `/user-profile` : ''} onClick={(e) => this.RemoveactivatepcNav(e)}> */}
                                                    <a className="nav-link text-truncate text-space-link vkkk" href="my-profile" onClick={(e) => this.RemoveactivatepcNav(e)}>

                                                        {
                                                            this.state.profilepic
                                                                ?
                                                                this.state.profilepic.includes('http')
                                                                    ?
                                                                    <img className="img-fluid rounded-circle me-2" width="35" src={this.state.profilepic} alt="" onError={this.addDefaultSrcprofile} />
                                                                    :
                                                                    this.state.profilepic === '' || this.state.profilepic === null || this.state.profilepic === 'null' || this.state.profilepic === '0' || this.state.profilepic === 'undefined'
                                                                        ?
                                                                        <img className="img-fluid rounded-circle me-2" width="35" src="https://storage.googleapis.com/xr-sports/xrgames/profileimages/Profile-pic-Activelink.png" alt="" />
                                                                        :
                                                                        <img className="img-fluid rounded-circle me-2" width="35" src={`${process.env.REACT_APP_GCPURL}/profileimages/${this.state.profilepic}`} alt="" onError={this.addDefaultSrcprofile} />
                                                                :
                                                                <img className="img-fluid rounded-circle me-2" width="35" src="https://storage.googleapis.com/xr-sports/xrgames/profileimages/Profile-pic-Activelink.png" alt="" />

                                                        }
                                                        {this.state.nickname}  </a>
                                                </li>
                                            </>
                                            :
                                            ''
                                    }
                                    {/* <li className="nav-item logout-desktop-view ms-3">

                                    {
                                        this.state.light_theme === true
                                        ?
                                            <input type="checkbox" className="aa d-none" id="xr-swtich" name="light_theme" value={this.state.light_theme} onChange={(e) => this.handleCheckbox(e)} defaultChecked={true}/>
                                        :
                                            <input type="checkbox" className="bb d-none" id="xr-swtich" name="light_theme" value={this.state.light_theme} onChange={(e) => this.handleCheckbox(e)} />
                                    }
                                    <div className="toggle">
                                        <div className="xr-switch-holder ani">
                                            <label className={this.state.light_theme?'active':''} htmlFor="xr-swtich">Toggle</label>
                                        </div>
                                    </div>

                                </li> */}
                                </ul>
                                {/* <div className="d-none d-md-block"><XRSwitch /></div> */}
                            </div>

                            {/* <XRSwitch/> */}
                        </nav>
                    </div>
                    <div className="switch-mobile-view">
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-md-4 text-center border-right">
                        <XRSwitch />
                        </div> */}
                                {
                                    this.state.isLoggedIn
                                        ?
                                        <>
                                            <div className="col-md-6 col-6 text-center border-right">
                                                <NavLink className="nav-link p-0 mt-1" to={this.state.ifluencer ? `/${this.state.nickname}` : this.state.userLogin ? `/user-profile` : '#'} onClick={(e) => this.RemoveactivatepcNav(e)}>
                                                    {
                                                        this.state.profilepic
                                                            ?
                                                            this.state.profilepic.includes('http')
                                                                ?
                                                                <img className="img-fluid rounded-circle me-2" width="35" src={this.state.profilepic} alt="" onError={this.addDefaultSrcprofile} />
                                                                :
                                                                this.state.profilepic === '' || this.state.profilepic === null || this.state.profilepic === 'null' || this.state.profilepic === '0' || this.state.profilepic === 'undefined'
                                                                    ?
                                                                    <img className="img-fluid rounded-circle me-2" width="35" src="https://storage.googleapis.com/xr-sports/xrgames/profileimages/Profile-pic-Activelink.png" alt="" />
                                                                    :
                                                                    <img className="img-fluid rounded-circle me-2" width="35" src={`${process.env.REACT_APP_GCPURL}/profileimages/${this.state.profilepic}`} alt="" onError={this.addDefaultSrcprofile} />
                                                            :
                                                            <img className="img-fluid rounded-circle me-2" width="35" src="https://storage.googleapis.com/xr-sports/xrgames/profileimages/Profile-pic-Activelink.png" alt="" />

                                                    }
                                                    <span> {this.state.firstname} </span> </NavLink>
                                            </div>
                                            <div className="col-md-6 col-6 text-center logout-desktop-view">
                                                <NavLink className="nav-link p-0 mt-1" to="" onClick={() => this.logout()}>Logout</NavLink>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-md-6 col-6 text-center border-right">
                                                <a className="nav-link p-0 mt-1" href='https://app.activelink.io/signup'>Sign In</a>
                                            </div>
                                            <div className="col-md-6 col-6 text-center">
                                                <a className="nav-link p-0 mt-1" href='https://app.activelink.io/signup'>Sign Up</a>
                                            </div>
                                        </>
                                }

                            </div>

                        </div>
                    </div>

                </header>

            </>
        );
    }

}
const mapStateToProps = (state) => ({
    singleuserdata: state.userDetails.singleuserdata,
    singleuserdataloading: state.userDetails.loading
})
export default withRouter(connect(mapStateToProps)(XRHeader));