import * as types from "../types/types";

const initalState = {
    loading: true,
    loadingtwo: true,
    accessfanloading:true,
    data: [],
    singleuser: [],
    monthlycount: [],
    accessfanhub:[],
    totalcount: '',
    highschooldata: [],
    subadmin: [],
    bannedusers: [],
    deletedusers: [],
    singleuserdata: [],
    mytournaments: [],
    leaderboardusersdata: [],
    adminvideo:[],
    adminuservideo:[],
    adminexclusivevideo:[],
    adminexclusivevideonewuser:'',
    adminexclusivevideoexistinguser:'',
    exclusivevideos:[],
    singleexclusivevideoloading:true,
    singleexclusivevideocommentloading:true,
    singleexclusivevideo:[],
    adminuser:[],
    admin_users:[],
    singlesluguserdata:[],
    adminPreferences: [],
    allCollectibels:[],
    limitedCollectibels:[],
    userHashtags: [],
    userWalletInfo:[],
    userWalletInfoloading:true,
    walletactivities:[],
    singlesluguserdataloading:true,
    topthreeplayers:[],
    allsuperadmin:[],
    allWalletUsers:[],
    userAllWallets:[],
    userAllWalletsByGroup:[],
    userAllWalletsFanHub:[],
    next_wallet_group:"",
    leaderboarddata:[],
    walletactivities:[],
    limiteswalletactivites:[],
    singleexclusivevideocomments:[],
    alltrendings:[],    
    latestexclusivevideo:[],
    restricteduserchat: [],
    support_ticket:[],
    userdetailhub:[],
    restrictedfanhubuserchat: []
}

const userReducer = (state = initalState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_DATA_BEGIN_TWO:
            return {
                ...state,
                loadingtwo: true
            }
        case types.FETCH_USER_BYSLUG_BEGIN:
            return {
                ...state,
                singlesluguserdataloading: true
            }
        case types.FETCH_USER_BYSLUG_SUCCESS:
            return {
                ...state,
                singlesluguserdataloading: false,
                singlesluguserdata: action.payload
            }
        case types.FETCH_ACCESS_FANHUB_BEGIN:
            return {
                ...state,
                accessfanloading:true
            }
        case types.FETCH_ACCESS_FANHUB_SUCCESS:
            return{
                ...state,
                accessfanloading:false,
                accessfanhub:action.payload
            }
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                singleuser: action.payload
            }
        case types.FETCH_USER_DETAIL_HUB_SUCCESS:
            return {
                ...state,
                loading: false,
                userdetailhub: action.payload
            }            
        case types.FETCH_SINGLE_USER_SUCCESS:

            return {
                ...state,
                loading: false,
                singleuserdata: action.payload,
                singleuserstatus: action.payloadd
            }
        case types.FETCH_ALL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case types.FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
            case types.FETCH_ADMIN_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                admin_users: action.payload
            }
        
        
        
        
        case types.FETCH_ADMIN_BY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                adminuser: action.payload
            }
        case types.FETCH_ADMINVIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminvideo: action.payload
            }
        case types.FETCH_ADMIN_USER_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminuservideo: action.payload
            }
        case types.FETCH_ADMIN_EXCLUSIVE_VIDEOS_SUCCESS:
            return {
                ...state,
                loadingtwo: false,
                adminexclusivevideo: action.payload,
                adminexclusivevideonewuser: action.newuser,
                adminexclusivevideoexistinguser: action.existinguser
            }
            case types.FETCH_EXCLUSIVE_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                exclusivevideos: action.payload
            }

        case types.FETCH_SINGLE_EXCLUSIVE_VIDEO_BEGIN:
            return {
                ...state,
                singleexclusivevideoloading: true
            }
            case types.FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_BEGIN:
                return {
                    ...state,
                    singleexclusivevideocommentloading: true
                }
    
        case types.FETCH_SINGLE_EXCLUSIVE_VIDEO_SUCCESS:
            return {
                ...state,
                singleexclusivevideoloading: false,
                singleexclusivevideo: action.payload
            }
            case types.FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_SUCCESS:
                return {
                    ...state,
                    singleexclusivevideocommentloading: false,
                    singleexclusivevideocomments: action.payload
                }
                
                    case types.FETCH_USERS_COUNT:
                        return {
                            ...state,
                            loading: false,
                            monthlycount: action.payload,
                            totalcount: action.paylo
                        }
                        case types.FETCH_NOTIFICATIONS_SUCCESS:
                            return {
                                ...state,
                                loadingtwo: false,
                                data: action.payload,
                            }
                            case types.FETCH_ALL_HIGH_SCHOOL_SUCCESS:
                                return {
                                    ...state,
                                    loading: false,
                                    highschooldata: action.payload
                                }
                                case types.FETCH_SUBADMIN_SUCCESS:
                                return {
                                    ...state,
                                    loading: false,
                                    subadmin: action.payload
                                }
                                case types.FETCH_MY_TOURNAMENTS_SUCCESS:
                                    return {
                                        ...state,
                                        loading: false,
                                        mytournaments: action.payload
                                    }
                                    case types.FETCH_LEADERBOARD_TOURNAMENTS_SUCCESS:
                                        return {
                                            ...state,
                                            loading: false,
                                            leaderboardusersdata: action.payload
                                        }
                                         case types.FETCH_USER_PROFILE_BYSLUG_BEGIN:
                                        return {
                                            ...state,
                                            singlesluguserprofiledataloading: true
                                        }
                                    case types.FETCH_USER_PROFILE_BYSLUG_SUCCESS:
                                        return {
                                            ...state,
                                            singlesluguserprofiledataloading: false,
                                            singlesluguserprofiledata: action.payload
                                        }
                                        // ------------wallet returns-------------------------------
                                        case types.FETCH_LIMITED_COLLECTIBELS:
                                            return {
                                                ...state,
                                                loading: false,
                                                limitedCollectibels: action.payload
                                            }
                                            case types.FETCH_LIMITED_WALLET_ACTIVITIES:
                                                return { 
                                                    ...state,
                                                    loading: false,
                                                    limiteswalletactivites: action.payload
                                                }
                                                case types.FETCH_PURCHASED_NFTS_BY_WALLET:
                                                    return {
                                                        ...state,
                                                        loading:false,
                                                        purchasednfts : action.payload
                                                    }
                                            case types.FETCH_USER_ALL_WALLETS:
                                                return {
                                                    ...state,
                                                    loading: false,
                                                    userAllWallets: action.payload,
                                                    next_wallet_group: action.next_wallet_group,
                                                }
                                                case types.FETCH_USER_ALL_WALLETS_BY_GROUP:
                                                return {
                                                    ...state,
                                                    loading: false,
                                                    userAllWalletsByGroup: action.payload,
                                                }
                                                case types.FETCH_USER_ALL_WALLETS_FANHUB:
                                                return {
                                                    ...state,
                                                    loading: false,
                                                    userAllWalletsFanHub: action.payload
                                                }
                                                case types.FETCH_WALLET_INFORMATION_START:
                                                return {
                                                    ...state,
                                                    userWalletInfoloading: true
                                                    }
                                                
                                                case types.FETCH_WALLET_INFORMATION:
                                                    return {
                                                        ... state,
                                                        userWalletInfoloading:false,
                                                        loadingtwo:false,
                                                        userWalletInfo : action.payload
                                                    }
                                                    case types.FETCH_WALLET_ACTIVITIES:
                                                        return{
                                                            ...state,
                                                            loading: false,
                                                            walletactivities : action.payload
                                                        }
                                                        case types.FETCH_WALLET_USER_DETAILS:
                                                            return {
                                                                ...state,
                                                                loading: false,
                                                                allWalletUsers: action.payload
                                                            }
        case types.FETCH_RELATED_EXCLUSIVE_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false,
                relatedexclusivevideo: action.payload
            }
        

        case types.FETCH_BANNED_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                bannedusers: action.payload
            }
        case types.FETCH_DELETED_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedusers: action.payload
            }
        
        case types.FETCH_ADMIN_PREFERENCES:
            return {
                ...state,
                loading: false,
                adminPreferences: action.payload
            }
        case types.FETCH_ALL_COLLECTIBELS:
            return {
                ...state,
                loading:false,
                allCollectibels : action.payload
            }
        case types.FETCH_USERS_HAHSTAGS:
            return { 
                ... state,
                loading:true,
                userHashtags : action.payload
            }    
        case types.FETCH_TOP_THREE_PLAYER_SUCCESS:
            return{
                ...state,
                loading: false,
                topthreeplayers: action.payload
            }
        case types.FETCH_ALL_SUPERADMIN_SUCCESS:
            return{
                ...state,
                loading: false,
                allsuperadmin: action.payload
            }
        
        case types.FETCH_ALL_ACTIVE_USERS:
            return {
                ...state,
                loading: false,
                activeusers: action.payload
            }
        case types.FETCH_ALL_USERS:
            return {
                ...state,
                loading: false,
                allusers: action.payload
            }
        
        case types.FETCH_LEADERBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                leaderboarddata: action.payload
            }            
        case types.FETCH_TRENDING:
            return {
                ...state,
                loading: false,
                alltrendings: action.payload
            }
        case types.FETCH_LATEST_EXCLUSIVE_VIDEO:
            return {
                ...state,
                loading: false,
                latestexclusivevideo: action.payload
            }
        case types.FETCH_RESTRICTED_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                restricteduserchat: action.payload,
                restrictedfanhubuserchat: action.payloadd
            }
        case types.FETCH_SUPPORT_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                support_ticket: action.payload
            }
        

        default:
            return state
    }
}

export default userReducer;