import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { NavLink, withRouter } from 'react-router-dom';
import parseJwt, { apiBaseUrl, logo_dark, swalpopup, deleteRecord, banner_presenting, } from '../store/helpers/common';
import getAllAdminDataMenus from './../store/actions/MediaAction'
import axios from 'axios';
import $ from 'jquery';
import renderHTML from 'react-render-html';
import moment from "moment-timezone";
import { getUserdetails } from "../store/actions/userActions";
import { getsecureclientData } from '../store/actions/XrDynamicAction';

import { getAdminLogs } from "../store/actions/XrDynamicAction";
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

import swal from 'sweetalert';
import { getSinglebanner, getAllbanners, getDeskbanners, getMobbanner } from "../store/actions/BannersAction";
import { getlobbyDeskbanners, getlobbyMobbanner } from '../store/actions/LobbybannerAction'
import { getDynamicOptions, } from "../store/actions/XrDynamicAction";
import { getDynamicplatformimages } from "../store/actions/XrDynamicAction";
import InputColor from 'react-input-color';
class Fanhubmenus extends Component {
    constructor(props) {
        super(props);
        this.tourValidatorhomebanner = new SimpleReactValidator();
        this.Validatorhomepartner = new SimpleReactValidator();
        this.state = {
            isLoggedIn: false,
            propic: '',
            allpages: '',
            platform: ``,
            allVideos: '',
            adminmenuloding: '',
            allmenusadmindata: '',
            currid: '',
            userrole: '',
            allUserVideos: '',
            checkparam: '',
            banner_id: "",
            banner_link: "",
            banner_image: "",
            previewimage: "",
            screentype: "",
            leftmenubar: false,
            isSubmitAboutFanhub: false,
            about_fanhub_home_status: "",
            fanhub_contact_no: "",
            fanhub_email: "",
            fanhub_address: "",
            id: 0,
            url: '',
            bannertype: '',
            bannertext: '',
            bannerlocation: '',
            screentype: '',
            bannerimagee: null,
            showActiondiv: false,
            my_lobby_video: "",
            isSubmitLobbybanner: false,
            bannerimagefile: null,
            isImageUploaded: false,
            isAccordionCollapsed: true,

        }

        // dynamic options pages 
        this.state = {
            dynamicoptions: '',
            page_name: '',
            content: '',
            isSubmitcred: false,
            pagedetails: '',
            color: '',
            setColor: '',
            bodycolor: '#5e72e4',
            footercolor: '#5e72e4',
            headercolor: '#5e72e4',
            pcolor: '#5e72e4',
            h1color: '#5e72e4',
            h2color: '#5e72e4',
            h3color: '#5e72e4',
            h4color: '#5e72e4',
            h5color: '#5e72e4',
            h6color: '#5e72e4',
            featuredcolor: '#5e72e4',

            btntextcolor: "#000000",
            btnbgcolor: "#c7c7c7",
            btnhovertextcolor: "#000000",
            btnhoverbgcolor: "#ffffff",
            btnhoverbordercolor: "#c7c7c7",
            //  pfont:'',
            //  h1font:'',
            //  h2font:'',
            //  h3font:'',
            //  h4font:'',
            //  h5font:'',
            //  h6font:'',
            socialvalue: '',
            socialid: '',
            socialname: '',
            code: 'hello',
            showEditor: false,
            submitScript: false,

            bannertext: "",
            bannerimagefile: "",

        }

        this.state = {
            platformimages: '',
            linkurl: '',
            // linkname:'',
            image_name: '',
            newlinkurl: '',
            newlinkname: '',
            newtitle: "",
            newimage_name: "",
            linkid: '',
            isSubmitcred: false,
            image_id: '',
            heading: '',
            viewpageimageurl: "",
            refresh_count: "1",
            partnerimagefile: "",
            newdescription: "",
            clickdone: "no",
        }
    }
    componentDidMount() {
        const { platform } = this.state;
        const currdetails = parseJwt(localStorage.getItem('token'));
        if (localStorage.getItem('token')) {

            const currid = currdetails.sub;
            const userrole = currdetails.urxrs;

            this.setState({
                currid: currid,
                userrole: userrole
            })

            let request;
            request = {
                method: 'GET',
                url: `${process.env.REACT_APP_APIURL}/user/get-user/${currid}`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            };
            axios(request).then((response) => {
                this.setState({
                    propic: response.data.data.profile_pic,
                });
            })
                .catch((error) => {
                });

            // let requestt;
            // requestt = {
            //     method: 'GET',
            //     url: `https://dev.xrsports.gg/superadminapi/public/user/get-all-pages`,
            //     headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            // };
            // axios(requestt).then((response) => {
            //     this.setState({
            //         allpages: response.data.data,
            //     });
            // })
            //     .catch((error) => {
            //     });

            let requestVideo;
            requestVideo = {
                method: 'GET',
                url: `${process.env.REACT_APP_APIURL}/user/get-admin-videos`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            };
            axios(requestVideo).then((response) => {
                this.setState({
                    allVideos: response.data.data,
                });
            })
                .catch((error) => {
                });

            let requestUserVideo;
            requestUserVideo = {
                method: 'GET',
                url: `${process.env.REACT_APP_APIURL}/user/get-admin-user-videos`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            };
            axios(requestUserVideo).then((response) => {
                this.setState({
                    allUserVideos: response.data.data,
                });
            })
                .catch((error) => {
                });

            let menusData;
            menusData = {
                method: 'GET',
                url: `${process.env.REACT_APP_APIURL}/user/get-admin-menu`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
            };
            axios(menusData).then((response) => {
                this.setState({
                    allmenusadmindata: response.data.data,
                });

            })
                .catch((error) => {
                });


            this.props.dispatch(getsecureclientData());
            this.props.dispatch(getUserdetails(currid));
            // this.props.dispatch(getMobbanner('home'));
            // this.props.dispatch(getDeskbanners('home'));
            this.props.dispatch(getlobbyDeskbanners('lobby'));
            this.props.dispatch(getlobbyMobbanner('lobby'))
            this.props.dispatch(getDynamicOptions());
            this.props.dispatch(getDynamicplatformimages());
        }
        // $('head').find('link#css1').remove();
        // $('head').find('link#css2').remove();
        // $('head').find('link#css3').remove();
        // $('head').find('link#css4').remove();

    }
    handleDropdownChange = (dropdown, selectedValue) => {
        this.setState({
            [dropdown]: selectedValue,
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.secureclientdata) {
            if (nextProps.secureclientdata.length > 0) {

                let client_details = parseJwt(nextProps.secureclientdata);

                this.setState({
                    clientdetails: client_details.client,
                    clientdetailsid: client_details.client.id,
                    logo_onee: client_details.client.logo_one,
                    logo_one_show: client_details.client.logo_one,
                    logo_twoo: client_details.client.logo_two,
                    logo_two_show: client_details.client.logo_two,
                    base_url: client_details.client.base_url,
                    loader_urll: client_details.client.loader_url,
                    loader_url_show: client_details.client.loader_url,
                    facebook: client_details.client.facebook,
                    twitter: client_details.client.twitter,
                    discord: client_details.client.discord,
                    banner_loaderr: client_details.client.banner_loader,
                    banner_loader_show: client_details.client.banner_loader,
                    instagram: client_details.client.instagram,
                    twitch: client_details.client.twitch,
                    aboutus: client_details.client.aboutus,
                    privacy_policy: client_details.client.privacy_policy,
                    faq: client_details.client.faq,
                    client_email: client_details.client.client_email,
                    client_phone: client_details.client.client_phone,
                    client_website: client_details.client.client_website,
                    client_address: client_details.client.client_address,
                    google_client_id: client_details.client.google_client_id,
                    footer_frame: client_details.client.footer_frame,
                    platform_theme: client_details.client.platform_theme,

                });
            }
        }

        this.setState({
            desktopbanner: nextProps.desktopbanner,
            mobilesingbanner: nextProps.mobilesingbanner,
            lobbydesktopbanner: nextProps.lobbydesktopbanner,
            lobbymobilesingbanner: nextProps.lobbymobilesingbanner
        });

        if (nextProps.singleuserdata) {
            if (nextProps.singleuserdata.domain_details) {
                this.setState({
                    domain_name: nextProps.singleuserdata.domain_details.domain_name,
                    internal_domain_name: nextProps.singleuserdata.domain_details.internal_domain_name,
                    ip_address: nextProps.singleuserdata.domain_details.ip_address,
                    platform_domain: nextProps.singleuserdata.domain_details.platform_domain,
                    api_platform_shortname: nextProps.singleuserdata.domain_details.api_platform_shortname,
                    bucket_name: nextProps.singleuserdata.domain_details.bucket_name,
                    default_meta_description: nextProps.singleuserdata.domain_details.default_meta_description,
                    default_meta_title: nextProps.singleuserdata.domain_details.default_meta_title,
                    platform_name: nextProps.singleuserdata.domain_details.short_suffix,
                    platform_short_name: nextProps.singleuserdata.domain_details.platform_short_name,
                    about_fanhub_home_title: nextProps.singleuserdata.domain_details.about_fanhub_home_title,
                    about_fanhub_home_desc: nextProps.singleuserdata.domain_details.about_fanhub_home_desc,
                    about_fanhub_home_status: nextProps.singleuserdata.domain_details.about_fanhub_home_status,
                    banner_status: nextProps.singleuserdata.domain_details.banner_status,
                    call_to_action_status: nextProps.singleuserdata.domain_details.call_to_action_status,
                    about_fanhub_home_status: nextProps.singleuserdata.domain_details.about_fanhub_home_status,
                    navigation_boxes_status: nextProps.singleuserdata.domain_details.navigation_boxes_status,
                    social_feed_status: nextProps.singleuserdata.domain_details.social_feed_status,
                    lobbyimage: nextProps.singleuserdata.domain_details.lobbyimage,
                    community_partners: nextProps.singleuserdata.domain_details.community_partners,
                    contact_info_status: nextProps.singleuserdata.domain_details.contact_info_status,
                    fanhub_contact_no: nextProps.singleuserdata.domain_details.phone,
                    fanhub_email: nextProps.singleuserdata.domain_details.email,
                    fanhub_address: nextProps.singleuserdata.domain_details.address,
                    //   chat_check:nextProps.singleuserdata.domain_details.communication_status,
                })
                // if (nextProps.singleuserdata.domain_details.about_fanhub_home_status == "1" && this.state.clickdone == "no") {
                //     // $('.slider').click(); 
                //     this.setState({
                //         clickdone: "yes"
                //     });
                //     if (document.getElementById("slider_feed")) {
                //         document.getElementById("slider_feed").click();
                //     }

                // }
                if (nextProps.singleuserdata.domain_details.communication_status === "1") {
                    this.setState({ chat_check: false })
                } else {
                    this.setState({ chat_check: true })
                }
            }
        }

        if (nextProps.dynamicoptions) {

            this.setState({
                dynamicoptions: nextProps.dynamicoptions,
            });
            if (nextProps.dynamicoptions && nextProps.dynamicoptions.length > 0) {
                nextProps.dynamicoptions.forEach((opt) => {
                    if (opt.option_key === 'header_script') {
                        this.setState({ code: opt.option_value })
                    }
                })
            }
        }
        if (nextProps.platformimages) {
            this.setState({
                platformimages: nextProps.platformimages,
            })
        }
        if (nextProps.refresh === "yes" && this.state.refresh_count === "1") {
            this.setState({
                refresh_count: 0,
            })
            this.props.dispatch(getsecureclientData());
            this.props.dispatch(getUserdetails(this.state.currid));
            // this.props.dispatch(getMobbanner('home'));
            // this.props.dispatch(getDeskbanners('home'));
            this.props.dispatch(getlobbyDeskbanners('lobby'))
            this.props.dispatch(getlobbyMobbanner('lobby'))
            this.props.dispatch(getDynamicOptions());
            this.props.dispatch(getDynamicplatformimages());

        }
    }

    logout = () => {
        localStorage.clear('token');
        this.setState({ isLoggedIn: !this.state.isLoggedIn, });
        localStorage.setItem('intro-popup', 'yes');
        this.props.history.push('/login');

        this.props.dispatch(getAdminLogs(this.state.currid));
        // window.location.reload('/login');
        // window.location.reload();
    };
    remtrid = (e, leftmenu) => {
        if (leftmenu === "leftmenu") {
            this.setState({
                leftmenubar: true,
            })
        }
        localStorage.removeItem('trmid', 'menuName');

        $("#sidebar-wrapper").toggleClass("toggled");

    }
    ToggleSideNavv = (e) => {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    }

    validateLoginForm = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        e.preventDefault();
        var r = document.querySelector(':root');
        var fontname = `--${e.target.name}`;

        r.style.setProperty(fontname, e.target.value);
    }

    validateTourForm = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        var res = e.target.value.match(/fake/g);
        if (res) {
            let file = $(`#${e.target.id}`)[0].files[0];
            this.setState({ imagelelogo: URL.createObjectURL(file) })
            if (e.target.name == "logo_one") {
                $('#hub_logo').attr('src', URL.createObjectURL(file));
                $('#hub_logo_actv').attr('src', URL.createObjectURL(file));
            }
        } else {
            var fullurl = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/${e.target.value}`;
            this.setState({ imagelelogo: fullurl })
        }


    }

    // lobby section 
    validatelobby = (e) => {

        this.setState({ [e.target.name]: e.target.value });
        var res = e.target.value.match(/fake/g);
        if (res) {
            let file = $(`#${e.target.id}`)[0].files[0];
            this.setState({ lobbyimg: URL.createObjectURL(file) })
            // if (e.target.name == "logo_one") {
            //     $('#hub_logo').attr('src', URL.createObjectURL(file));
            //     $('#hub_logo_actv').attr('src', URL.createObjectURL(file));
            // }
        } else {
            var fullurl = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/${e.target.value}`;
            this.setState({ lobbyimg: fullurl })
        }


    }
    // lobby sect end 


    validateForm = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        var res = e.target.value.match(/fake/g);
        if (res) {
            let file = $(`#${e.target.id}`)[0].files[0];
            this.setState({ previewimage: URL.createObjectURL(file) })
        } else {
            var fullurl = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/xrsports.games-homepage/preview-image.jpg?random=${moment().format('YYYYMM-DDHHmmss')}`;
            this.setState({ previewimage: fullurl })
        }
    }
    checkParam = (e, param) => {
        this.setState({ checkparam: param })
    }

    handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            let screentype = '';
            if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'gif') {
                screentype = 'image';
            } else if (fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'avi') {
                screentype = 'video';
            } else {
                swalpopup("Please select any jpg, jpeg, png, gif or any mp4 file.", 'error')
                return false;
            }
            this.setState({ 'screentype': screentype });
            this.setState({
                bannerimagefile: URL.createObjectURL(file),
                isImageUploaded: true,
            });
        }

    };
    videochangediv = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        // Check if the selected value is "yes" and set showActionsDiv accordingly
        if (value === 'yes') {
            this.setState({ showActiondiv: true });
        } else {
            this.setState({ showActiondiv: false });
        }
    }

    dataChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        e.preventDefault();
        if (e.target.name == "bannerimage") {
            this.setState({ [e.target.name]: e.target.value });
            var res = e.target.value.match(/fake/g);
            if (res) {
                let file = $(`#${e.target.id}`)[0].files[0];
                const fileSizeInBytes = file.size;
                const fileSizeInKB = (fileSizeInBytes / 1024 / 1024).toFixed(2);

                if (fileSizeInKB > 20) {
                    // swal("oops", "Image Size should be less than 10 MB.", "error");
                    swalpopup("File Size should be less than 20 MB.", 'error')
                    return;
                }
                this.setState({ bannerimagefile: URL.createObjectURL(file) })
                // $(`.new_partner`).attr('src', URL.createObjectURL(file));
            } else {

            }
        }
        if (e.target.name == "lobbybannerimage") {
            this.setState({ [e.target.name]: e.target.value });
            var res = e.target.value.match(/fake/g);
            if (res) {
                let file = $(`#${e.target.id}`)[0].files[0];
                const fileSizeInBytes = file.size;
                const fileSizeInKB = (fileSizeInBytes / 1024 / 1024).toFixed(2);

                if (fileSizeInKB > 20) {
                    // swal("oops", "Image Size should be less than 10 MB.", "error");
                    swalpopup("File Size should be less than 20 MB.", 'error')
                    return;
                }
                this.setState({ lobbybannerimagefile: URL.createObjectURL(file) })
                // $(`.new_partner`).attr('src', URL.createObjectURL(file));
            } else {

            }
        }
        if (e.target.name == "lobbyvideo") {
            const file = document.getElementById('lobbyvideo').files[0];
            var fileExtension = file.name.split('.').pop();
            var validate = this.tourValidatorhomebanner;
            this.setState({ isSubmithomebanner: true });
            var url = this.state.url;
            if (fileExtension != "gif") {
                // swal("", "Video File should be in mp4 format.", "error");
                swalpopup('Video File should be in Gif format.', "error");
                return;
            }
            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var result = '';
            var length = 10;
            for (var i = 0; i < length; i++) {
                result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            var filename = result + moment().format('YYYYMM-DDHHmmss') + '.' + fileExtension;

            const myNewFile = new File([file], filename, { type: file.type });
            var key = '';
            var url = '';
            this.setState({
                uploading: true
            })

            // put api function here to upload from backend 

            // const results = this.props.dispatch(createsecuresignedurlData('challenge_videos',filename))
            //   results.then(data => {
            //   })
            let request_audio;
            request_audio = {
                method: 'get',
                url: `${process.env.REACT_APP_APIURL}/user/create-signed-url/homecarouselbanners/${filename}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Content-type': 'video/gif',
                    'Content-type': 'multipart/form-data',
                    'X-Auth-Token': `${localStorage.getItem('token')}`,
                },
            };
            axios(request_audio).then((data_url) => {
                const signed_url = data_url.data.Signed_url
                // post video to gcp

                var dataa = myNewFile;
                const requestfile = new Request(signed_url, {
                    method: "PUT",
                    body: myNewFile,
                    headers: new Headers({
                        'Content-type': dataa.type,
                    })
                });
                return fetch(requestfile)
                    .then(data => {

                        url = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${filename}`;

                        this.setState({ my_lobby_video: url })
                        this.setState({ isSubmithomebanner: false });

                    })
                    .catch(err => {
                        this.setState({ isSubmitVideo: false });
                        this.setState({ video_loader: false })
                    });
            });

        }
        if (e.target.name == "partnerimage") {
            this.setState({ [e.target.name]: e.target.value });
            var res = e.target.value.match(/fake/g);
            if (res) {
                let file = $(`#${e.target.id}`)[0].files[0];
                this.setState({ partnerimagefile: URL.createObjectURL(file) })
                $(`.new_partner`).attr('src', URL.createObjectURL(file));
            } else {

            }
        }
        if (e.target.name == "platform_short_name") {
            this.setState({ [e.target.name]: e.target.value });
            $('.change_platfrom_name').text(e.target.value);
        }

        if (e.target.name == "platform_theme") {
            this.setState({ [e.target.name]: e.target.value });
            if (e.target.value == "dark") {
                $('#change_platform_theme').removeClass();
                $('#change_platform_theme').addClass("darkmode");
            }
            else if (e.target.value == "dark_2") {
                $('#change_platform_theme').removeClass();
                $('#change_platform_theme').addClass("darkmode_two");
            }
            else if (e.target.value == "dark_3") {
                $('#change_platform_theme').removeClass();
                $('#change_platform_theme').addClass("darkmode_three");
            } else if (e.target.value == "light") {
                $('#change_platform_theme').removeClass();
            } else {

            }
        }




    }
    uploadVideo = () => {
        const { checkparam } = this.state;
        this.setState({ isSubmitVideo: true })
        // this.setState({ video_loader: true })
        var file = $(`#${checkparam}`)[0].files[0];
        let formData = new FormData();
        formData.append('key', checkparam);
        formData.append('file', file);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/addImage`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitVideo: false, key: '', url: '', type: '' });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getsecureclientData());
                // swal("Done", data.ResponseText, "success");
                this.UpdateThirdSTepDashboard();
                swalpopup(data.ResponseText, 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitVideo: false });
        })
    }


    update_platform_hub = () => {
        let formData = new FormData();
        // $field_name = $request->input('field_name');
        // $filed_value = $request->input('filed_value');
        // $where_filed = $request->input('where_filed');
        // $where_value = $request->input('where_value');
        formData.append('platform_short_name', this.state.platform_short_name);
        formData.append('default_meta_description', this.state.default_meta_description);
        formData.append('platform_short_name', this.state.platform_short_name);
        formData.append('user_id', this.state.currid);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update_hub_details`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmithub: false });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getUserdetails(this.state.currid));
                this.UpdateThirdSTepDashboard();
                // swal("Done", data.ResponseText, "success");
                swalpopup("Platform Name Updated Successfully.", 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmithub: false });
        })
    }
    UpdateThirdSTepDashboard = () => {
        const { currid } = this.state;
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update-third-step/${currid}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'X-Auth-Token': `${localStorage.getItem('token')}`
            },
        });
        return fetch(request)
            .then(res => res.json())
            .then(res => {

            });
    }
    update_metas = () => {
        let formData = new FormData();

        this.setState({ isSubmitmetas: true });
        // $field_name = $request->input('field_name');
        // $filed_value = $request->input('filed_value');
        // $where_filed = $request->input('where_filed');
        // $where_value = $request->input('where_value');
        formData.append('default_meta_title', this.state.default_meta_title);
        formData.append('default_meta_description', this.state.default_meta_description);
        formData.append('user_id', this.state.currid);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update_hub_details`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitmetas: false });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getUserdetails(this.state.currid));
                // swal("Done", data.ResponseText, "success");
                this.UpdateThirdSTepDashboard();
                swalpopup("Meta Tags Updated Successfully.", 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitmetas: false });
        })
    }
    update_about_fanhub = () => {
        let formData = new FormData();
        this.setState({ isSubmitmetas: true });
        formData.append('about_fanhub_home_title', this.state.about_fanhub_home_title);
        formData.append('about_fanhub_home_desc', this.state.about_fanhub_home_desc);
        formData.append('user_id', this.state.currid);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update-about-fanhub-details`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitmetas: false });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getUserdetails(this.state.currid));
                // swal("Done", data.ResponseText, "success");
                this.UpdateThirdSTepDashboard();
                swalpopup(data.ResponseText, 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitmetas: false });
        })
    }
    setViewImage = (e, imgurl, id) => {
        var res = imgurl.match(/fake/g);
        if (res) {
            let file = $(`#${id}`)[0].files[0];
            this.setState({ viewprizeimage: URL.createObjectURL(file) })
        } else {
            var fullurl = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/${imgurl}`;
            this.setState({ viewprizeimage: fullurl })
        }
    }

    deleteBanner = (e, id) => {
        swal({
            title: "Are you sure to delete this Banner?",
            buttons: ["Cancel", "Yes"],
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                // deleteRecord('delete-banner', id)
                const request = new Request(`${process.env.REACT_APP_APIURL}/user/delete-banner/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${localStorage.getItem("token")}`
                    }
                });
                fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        if (res.ResponseCode === "1") {
                            swalpopup(res.ResponseText, "success");
                            this.props.dispatch(getDeskbanners());
                            this.props.dispatch(getMobbanner())
                        } else {
                            swalpopup(res.ResponseText, "error");
                        }
                    });
            } else {
            }
        });
    }


    deletelobbyBanner = (e, id) => {
        swal({
            title: "Are you sure to delete this Banner?",
            buttons: ["Cancel", "Yes"],
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                // deleteRecord('delete-banner', id)
                const request = new Request(`${process.env.REACT_APP_APIURL}/user/deletelobby-banner/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${localStorage.getItem("token")}`
                    }
                });
                fetch(request)
                    .then(res => res.json())
                    .then(res => {
                        if (res.ResponseCode === "1") {
                            swalpopup(res.ResponseText, "success");
                            this.props.dispatch(getlobbyDeskbanners('lobby'));
                            this.props.dispatch(getlobbyMobbanner('lobby'))
                        } else {
                            swalpopup(res.ResponseText, "error");
                        }
                    });
            } else {
            }
        });
    }

    deletepartner = (e, linkid) => {
        swal({
            title: "Are you sure to delete this partner?",
            buttons: ["Cancel", "Yes"],
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                deleteRecord('delete-platformimages', linkid)
                this.props.dispatch(getDynamicplatformimages());
            } else {
            }
        });
    }

    setHomepageImage = (e, imgurl) => {
        var fullurl = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${imgurl}`;
        this.setState({ viewimage: fullurl })
    }

    edithomebanner = (e, id, link, image) => {
        this.setState({
            'banner_id': id,
            'banner_link': link,
            'banner_image': image
        })
    }
    editlobbybanner = (e, id, link, image) => {
        this.setState({
            'banner_id': id,
            'banner_link': link,
            'banner_image': image
        })
    }
    handlelobbyInputChange = (e) => {
        const { name, value, type, files } = e.target;
        this.setState({
            [name]: type === 'file' ? files[0] : value,
        });
    };

    //   handlelobbySubmit = async (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append('id', this.state.id);
    //     formData.append('url', this.state.url);
    //     formData.append('bannertype', this.state.bannertype);
    //     formData.append('bannertext', this.state.bannertext);
    //     formData.append('bannerlocation', this.state.bannerlocation);
    //     formData.append('screentype', this.state.screentype);
    //     formData.append('bannerimagee', this.state.bannerimagee);

    //     try {
    //       const response = await fetch('/user/update-lobby-page-banner', {
    //         method: 'POST',
    //         body: formData,
    //       });

    //       if (response.ok) {
    //         const data = await response.json();
    //         if (data.ResponseCode === '1') {
    //           // Banner updated successfully, handle accordingly
    //         } else {
    //           // Error occurred during update, handle accordingly
    //           console.error('Error Occurred: ', data.ResponseText);
    //         }
    //       } else {
    //         // Handle HTTP error
    //         console.error('HTTP Error: ', response.statusText);
    //       }
    //     } catch (error) {
    //       console.error('An error occurred: ', error);
    //     }
    //   };


    signedurlforfile = (e) => {
        if (e.target.name == 'challengevideo') {
            var file = $('#challengevideo')[0].files[0];
            var fileExtension = file.name.split('.').pop();
            if (fileExtension != "mp4") {
                // swal("", "Video File should be in mp4 format.", "error");
                swalpopup('Video File should be in mp4 format.', "error");
                return;
            }
            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var result = '';
            var length = 10;
            for (var i = 0; i < length; i++) {
                result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            var filename = result + '.' + fileExtension;

            const myNewFile = new File([file], filename, { type: file.type });
            var key = '';
            var url = '';
            this.setState({
                uploading: true
            })

            let request_audio;
            request_audio = {
                method: 'get',
                url: `${process.env.REACT_APP_APIURL}/user/create-signed-url/challenge_videos/${filename}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Content-type': 'video/mp4',
                    'Content-type': 'multipart/form-data',
                    'X-Auth-Token': `${localStorage.getItem('token')}`,
                },
            };
            axios(request_audio).then((data_url) => {
                const signed_url = data_url.data.Signed_url
                // post video to gcp

                var dataa = myNewFile;
                const request = new Request(signed_url, {
                    method: "PUT",
                    body: myNewFile,
                    headers: new Headers({
                        'Content-type': dataa.type,
                    })
                });
                return fetch(request)
                    .then(data => {
                        url = `${process.env.REACT_APP_GCPURL}/challenge_videos/${filename}`;
                        this.setState({
                            // key: key,
                            url: url,
                            // challengevideo_key: key,
                            challenge_video_url: url,
                            challenge_link: url,
                            uploading: false,
                            btn_challange_upload_video: true,

                            stepon: true,
                        })
                    })
                    .catch(err => {
                        this.setState({ isSubmitVideo: false });
                        this.setState({ video_loader: false })
                    });
            });

        }
    }

    AddhomerBanner = (e) => {
        const { bannerurl, bannertype, platform_name, bannertext, screentype } = this.state;
        e.preventDefault();
        var validate = this.tourValidatorhomebanner;
        this.setState({ isSubmithomebanner: true });
        var url = this.state.url;
        const file = document.getElementById('bannerimage').files[0];
        if (validate.allValid()) {
            if (screentype !== "") {

                let request;
                let formData = new FormData();

                if (screentype === 'video') {
                    var fileExtension = file.name.split('.').pop();
                    if (fileExtension != "mp4") {
                        // swal("", "Video File should be in mp4 format.", "error");
                        swalpopup('Video File should be in mp4 format.', "error");
                        return;
                    }
                    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    var result = '';
                    var length = 10;
                    for (var i = 0; i < length; i++) {
                        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
                    }
                    var filename = result + moment().format('YYYYMM-DDHHmmss') + '.' + fileExtension;

                    const myNewFile = new File([file], filename, { type: file.type });
                    var key = '';
                    var url = '';
                    this.setState({
                        uploading: true
                    })

                    // put api function here to upload from backend 

                    // const results = this.props.dispatch(createsecuresignedurlData('challenge_videos',filename))
                    //   results.then(data => {
                    //   })
                    let request_audio;
                    request_audio = {
                        method: 'get',
                        url: `${process.env.REACT_APP_APIURL}/user/create-signed-url/homecarouselbanners/${filename}`,
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-type': 'video/mp4',
                            'Content-type': 'multipart/form-data',
                            'X-Auth-Token': `${localStorage.getItem('token')}`,
                        },
                    };
                    axios(request_audio).then((data_url) => {
                        const signed_url = data_url.data.Signed_url
                        // post video to gcp

                        var dataa = myNewFile;
                        const requestfile = new Request(signed_url, {
                            method: "PUT",
                            body: myNewFile,
                            headers: new Headers({
                                'Content-type': dataa.type,
                            })
                        });
                        return fetch(requestfile)
                            .then(data => {

                                url = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${filename}`;


                                // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                                formData.append('bannerimage', filename);
                                formData.append('url', bannerurl);
                                formData.append('bannertype', bannertype);
                                formData.append('screentype', screentype);
                                formData.append('bannertext', bannertext);
                                formData.append('platform', platform_name);
                                formData.append('bannerlocation', 'home');
                                request = {
                                    method: 'POST',
                                    url: `${process.env.REACT_APP_APIURL}/user/add-craousal-banner`,
                                    headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                                    data: formData
                                };

                                axios(request).then((data) => {
                                    if (data.data.ResponseCode === "1") {
                                        this.setState({
                                            bannerimagefile: null,
                                            isImageUploaded: false,
                                            isAccordionCollapsed: true,
                                            // Reset other state properties as needed
                                        });
                                        this.setState({ isSubmithomebanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                                        // swal("Done", data.data.ResponseText, "success");
                                        swalpopup(data.data.ResponseText, "success");
                                        this.UpdateThirdSTepDashboard();
                                        this.props.dispatch(getMobbanner('home'));
                                        this.props.dispatch(getDeskbanners('home'));
                                        // window.location.reload();

                                    } else {
                                        this.setState({ isSubmithomebanner: false });
                                        swalpopup(data.data.ResponseText, "error");
                                    }
                                });
                            })
                            .catch(err => {
                                this.setState({ isSubmitVideo: false });
                                this.setState({ video_loader: false })
                            });
                    });

                } else if (screentype === "image") {

                    // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                    formData.append('bannerimage', file);
                    formData.append('url', bannerurl);
                    formData.append('bannertype', bannertype);
                    formData.append('screentype', screentype);
                    formData.append('bannertext', bannertext);
                    formData.append('platform', platform_name);
                    formData.append('bannerlocation', 'home');
                    request = {
                        method: 'POST',
                        url: `${process.env.REACT_APP_APIURL}/user/add-craousal-banner`,
                        headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                        data: formData
                    };

                    axios(request).then((data) => {
                        if (data.data.ResponseCode === "1") {
                            this.setState({
                                bannerimagefile: null,
                                isImageUploaded: false,
                                isAccordionCollapsed: true,
                                // Reset other state properties as needed
                            });
                            this.setState({ isSubmithomebanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                            // swal("Done", data.data.ResponseText, "success");
                            swalpopup(data.data.ResponseText, "success");
                            this.UpdateThirdSTepDashboard();
                            this.props.dispatch(getMobbanner('home'));
                            this.props.dispatch(getDeskbanners('home'));
                            // window.location.reload();

                        } else {
                            this.setState({ isSubmithomebanner: false });
                            swalpopup(data.data.ResponseText, "error");
                        }
                    });
                } else {

                }
            }
        } else {
            this.setState({ isSubmithomebanner: false });
            validate.showMessages();
            this.forceUpdate();
        }

    }
    AddLobbyBannerNew = (e) => {
        // return;

        const { bannerurl, platform_name, bannertype,screenusertype, bannertext, my_lobby_video } = this.state;

        e.preventDefault();
        var validate = this.tourValidatorhomebanner;
        this.setState({ isSubmitLobbybanner: true });
        var url = this.state.url;
        // const file = document.getElementById('bannerimage').files[0];
        // var file = $(`#${bannerimage}`)[0].files[0];
        // if (validate.allValid()) {
        var screentype = 'image';
        if (screentype !== "") {
            let request;
            let formData = new FormData();

            if (screentype === 'video') {
                var file = document.getElementById('lobbybannerimage').files[0];
                // var file = $(`#lobbybannerimage`)[0].files[0];
                var fileExtension = file.name.split('.').pop();
                if (fileExtension != "mp4") {
                    // swal("", "Video File should be in mp4 format.", "error");
                    swalpopup('Video File should be in mp4 format.', "error");
                    return;
                }
                var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var result = '';
                var length = 10;
                for (var i = 0; i < length; i++) {
                    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
                }
                var filename = result + moment().format('YYYYMM-DDHHmmss') + '.' + fileExtension;

                const myNewFile = new File([file], filename, { type: file.type });
                var key = '';
                var url = '';
                this.setState({
                    uploading: true
                })

                // put api function here to upload from backend 

                // const results = this.props.dispatch(createsecuresignedurlData('challenge_videos',filename))
                //   results.then(data => {
                //   })
                let request_audio;
                request_audio = {
                    method: 'get',
                    url: `${process.env.REACT_APP_APIURL}/user/create-signed-url/homecarouselbanners/${filename}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-type': 'video/mp4',
                        'Content-type': 'multipart/form-data',
                        'X-Auth-Token': `${localStorage.getItem('token')}`,
                    },
                };
                axios(request_audio).then((data_url) => {
                    const signed_url = data_url.data.Signed_url
                    // post video to gcp

                    var dataa = myNewFile;
                    const requestfile = new Request(signed_url, {
                        method: "PUT",
                        body: myNewFile,
                        headers: new Headers({
                            'Content-type': dataa.type,
                        })
                    });
                    return fetch(requestfile)
                        .then(data => {

                            url = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${filename}`;

                            var screentype = 'image';
                            // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                            formData.append('bannerimage', filename);
                            formData.append('url', bannerurl);
                            formData.append('banner_type', bannertype);
                            formData.append('screentype', screentype);
                            formData.append('screenusertype', screenusertype);
                            formData.append('bannertext', bannertext);
                            formData.append('platform', platform_name);
                            formData.append('lobby_location', 'lobby');

                            request = {
                                method: 'POST',
                                url: `${process.env.REACT_APP_APIURL}/user/add-lobby-banner`,
                                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                                data: formData
                            };

                            axios(request).then((data) => {
                                if (data.data.ResponseCode === "1") {

                                    this.setState({ lobbybannerimagefile: '', isSubmitLobbybanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                                    // swal("Done", data.data.ResponseText, "success");
                                    swalpopup(data.data.ResponseText, "success");
                                    // this.UpdateThirdSTepDashboard();
                                    // this.props.dispatch(getMobbanner('home'));
                                    // this.props.dispatch(getDeskbanners('home'));
                                    this.setState({url:'',banner_type:'',screentype:'',screenusertype:'',bannertext:'',platform:''})
                                    this.props.dispatch(getlobbyDeskbanners('lobby'));
                                    this.props.dispatch(getlobbyMobbanner('lobby'))
                                    // window.location.reload();

                                } else {
                                    this.setState({ isSubmitLobbybanner: false });
                                    swalpopup(data.data.ResponseText, "error");
                                }
                            });
                        })
                        .catch(err => {
                            this.setState({ isSubmitVideo: false });
                            this.setState({ video_loader: false })
                        });
                });

            } else if (screentype === "image") {
                // var file = $(`#lobbybannerimage`)[0].files[0];                    
                var file = document.getElementById('lobbybannerimage').files[0];

                var screentype = 'image';
                // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                formData.append('bannerimage', file);
                formData.append('url', bannerurl);
                formData.append('banner_type', bannertype);
                formData.append('screentype', screentype);
                formData.append('screenusertype', screenusertype);
                formData.append('bannertext', bannertext);
                formData.append('platform', platform_name);
                formData.append('lobby_location', 'lobby');
                formData.append('my_lobby_video', my_lobby_video)
                request = {
                    method: 'POST',
                    url: `${process.env.REACT_APP_APIURL}/user/add-lobby-banner`,
                    headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                    data: formData
                };

                axios(request).then((data) => {
                    if (data.data.ResponseCode === "1") {
                        this.setState({ lobbybannerimagefile: '', isSubmitLobbybanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', my_lobby_video: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                        // swal("Done", data.data.ResponseText, "success");
                        swalpopup(data.data.ResponseText, "success");
                        // this.UpdateThirdSTepDashboard();
                        // this.props.dispatch(getMobbanner('home'));
                        // this.props.dispatch(getDeskbanners('home'));
                        this.props.dispatch(getlobbyDeskbanners('lobby'));
                        this.props.dispatch(getlobbyMobbanner('lobby'))
                        // window.location.reload();

                    } else {
                        this.setState({ isSubmitLobbybanner: false });
                        swalpopup(data.data.ResponseText, "error");
                    }
                });
            } else {

            }




        }
    }
    AddLobbyBanner = (e) => {
        // return;

        const { bannerurl, platform_name, bannertype, bannertext, my_lobby_video } = this.state;

        e.preventDefault();
        var validate = this.tourValidatorhomebanner;
        this.setState({ isSubmitLobbybanner: true });
        var url = this.state.url;
        // const file = document.getElementById('bannerimage').files[0];
        // var file = $(`#${bannerimage}`)[0].files[0];
        // if (validate.allValid()) {
        var screentype = 'image';
        if (screentype !== "") {
            let request;
            let formData = new FormData();

            if (screentype === 'video') {
                var file = document.getElementById('lobbybannerimage').files[0];
                // var file = $(`#lobbybannerimage`)[0].files[0];
                var fileExtension = file.name.split('.').pop();
                if (fileExtension != "mp4") {
                    // swal("", "Video File should be in mp4 format.", "error");
                    swalpopup('Video File should be in mp4 format.', "error");
                    return;
                }
                var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var result = '';
                var length = 10;
                for (var i = 0; i < length; i++) {
                    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
                }
                var filename = result + moment().format('YYYYMM-DDHHmmss') + '.' + fileExtension;

                const myNewFile = new File([file], filename, { type: file.type });
                var key = '';
                var url = '';
                this.setState({
                    uploading: true
                })

                // put api function here to upload from backend 

                // const results = this.props.dispatch(createsecuresignedurlData('challenge_videos',filename))
                //   results.then(data => {
                //   })
                let request_audio;
                request_audio = {
                    method: 'get',
                    url: `${process.env.REACT_APP_APIURL}/user/create-signed-url/homecarouselbanners/${filename}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-type': 'video/mp4',
                        'Content-type': 'multipart/form-data',
                        'X-Auth-Token': `${localStorage.getItem('token')}`,
                    },
                };
                axios(request_audio).then((data_url) => {
                    const signed_url = data_url.data.Signed_url
                    // post video to gcp

                    var dataa = myNewFile;
                    const requestfile = new Request(signed_url, {
                        method: "PUT",
                        body: myNewFile,
                        headers: new Headers({
                            'Content-type': dataa.type,
                        })
                    });
                    return fetch(requestfile)
                        .then(data => {

                            url = `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${filename}`;

                            var screentype = 'image';
                            // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                            formData.append('bannerimage', filename);
                            formData.append('url', bannerurl);
                            formData.append('banner_type', bannertype);
                            formData.append('screentype', screentype);
                            formData.append('bannertext', bannertext);
                            formData.append('platform', platform_name);
                            formData.append('lobby_location', 'lobby');

                            request = {
                                method: 'POST',
                                url: `${process.env.REACT_APP_APIURL}/user/add-lobby-banner`,
                                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                                data: formData
                            };

                            axios(request).then((data) => {
                                if (data.data.ResponseCode === "1") {

                                    this.setState({ lobbybannerimagefile: '', isSubmitLobbybanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                                    // swal("Done", data.data.ResponseText, "success");
                                    swalpopup(data.data.ResponseText, "success");
                                    this.UpdateThirdSTepDashboard();
                                    // this.props.dispatch(getMobbanner('home'));
                                    // this.props.dispatch(getDeskbanners('home'));
                                    this.props.dispatch(getlobbyDeskbanners('lobby'));
                                    this.props.dispatch(getlobbyMobbanner('lobby'))
                                    // window.location.reload();

                                } else {
                                    this.setState({ isSubmitLobbybanner: false });
                                    swalpopup(data.data.ResponseText, "error");
                                }
                            });
                        })
                        .catch(err => {
                            this.setState({ isSubmitVideo: false });
                            this.setState({ video_loader: false })
                        });
                });

            } else if (screentype === "image") {
                // var file = $(`#lobbybannerimage`)[0].files[0];                    
                var file = document.getElementById('lobbybannerimage').files[0];

                var screentype = 'image';
                // formData.append('bannerimage', $('#bannerimage')[0].files[0]);
                formData.append('bannerimage', file);
                formData.append('url', bannerurl);
                formData.append('banner_type', bannertype);
                formData.append('screentype', screentype);
                formData.append('bannertext', bannertext);
                formData.append('platform', platform_name);
                formData.append('lobby_location', 'lobby');
                formData.append('my_lobby_video', my_lobby_video)
                request = {
                    method: 'POST',
                    url: `${process.env.REACT_APP_APIURL}/user/add-lobby-banner`,
                    headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                    data: formData
                };

                axios(request).then((data) => {
                    if (data.data.ResponseCode === "1") {
                        this.setState({ lobbybannerimagefile: '', isSubmitLobbybanner: false, bannerimage: '', screentype: '', bannerurl: '', bannertype: '', bannertext: '', my_lobby_video: '', sponsorbanner: '', sponsormobilebanner: '', bannerimagefile: "" });
                        // swal("Done", data.data.ResponseText, "success");
                        swalpopup(data.data.ResponseText, "success");
                        this.UpdateThirdSTepDashboard();
                        // this.props.dispatch(getMobbanner('home'));
                        // this.props.dispatch(getDeskbanners('home'));
                        this.props.dispatch(getlobbyDeskbanners('lobby'));
                        this.props.dispatch(getlobbyMobbanner('lobby'))
                        // window.location.reload();

                    } else {
                        this.setState({ isSubmitLobbybanner: false });
                        swalpopup(data.data.ResponseText, "error");
                    }
                });
            } else {

            }




        }
        // } else {
        //     this.setState({ isSubmithomebanner: false });
        //     validate.showMessages();
        //     this.forceUpdate();
        // }
    }





    setplatformtheme = (ef, platform_theme) => {
        // const{checkparam}= this.state;

        this.setState({ isSubmitGoogleLink: true })

        let clientid = this.state.clientdetailsid;
        let formData = new FormData();
        formData.append('clientid', clientid);
        formData.append('platform_theme', platform_theme);

        const request = new Request(`${process.env.REACT_APP_APIURL}/user/set-platform-theme`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitGoogleLink: false });
            if (data.ResponseCode == 1) {
                this.UpdateThirdSTepDashboard();
                this.props.dispatch(getsecureclientData());
                // swal("Done", data.ResponseText, "success");
                swalpopup(data.ResponseText, 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitGoogleLink: false });
        })
    }

    // update color code 
    setColor = (e, statename) => {
        // var r = document.querySelector(':root');
        // var aaa = `--${statename}`;

        // r.style.setProperty(aaa, e.hex);
        if (statename === 'bodycolor') {
            this.setState({ bodycolor: e.hex })
        } else if (statename === 'footercolor') {
            this.setState({ footercolor: e.hex })
        } else if (statename === 'headercolor') {
            this.setState({ headercolor: e.hex })
        } else if (statename === 'pcolor') {
            this.setState({ pcolor: e.hex })
        } else if (statename === 'h1color') {
            this.setState({ h1color: e.hex })
        } else if (statename === 'h2color') {
            this.setState({ h2color: e.hex })
        } else if (statename === 'h3color') {
            this.setState({ h3color: e.hex })
        } else if (statename === 'h4color') {
            this.setState({ h4color: e.hex })
        } else if (statename === 'h5color') {
            this.setState({ h5color: e.hex })
        } else if (statename === 'h6color') {
            this.setState({ h6color: e.hex })
        } else if (statename === 'featuredcolor') {
            this.setState({ featuredcolor: e.hex })
        } else if (statename === 'primarycolor') {
            this.setState({ primarycolor: e.hex })
        } else if (statename === 'secondarycolor') {
            this.setState({ secondarycolor: e.hex })
        } else if (statename === 'menulinks') {
            this.setState({ menulinks: e.hex })
        } else if (statename === 'btntextcolor') {
            this.setState({ btntextcolor: e.hex })
        } else if (statename === 'btnbgcolor') {
            this.setState({ btnbgcolor: e.hex })
        } else if (statename === 'btnhovertextcolor') {
            this.setState({ btnhovertextcolor: e.hex })
        } else if (statename === 'btnhoverbgcolor') {
            this.setState({ btnhoverbgcolor: e.hex })
        } else if (statename === 'btnhoverbordercolor') {
            this.setState({ btnhoverbordercolor: e.hex })
        }

    }
    // update color code 
    handleChangeComplete = (e, statename) => {
        var r = document.querySelector(':root');
        var aaa = `--${statename}`;
        r.style.setProperty(aaa, e.hex);
        if (statename === 'bodycolor') {
            this.setState({ bodycolor: e.hex })
        } else if (statename === 'footercolor') {
            this.setState({ footercolor: e.hex })
        } else if (statename === 'headercolor') {
            this.setState({ headercolor: e.hex })
        } else if (statename === 'pcolor') {
            this.setState({ pcolor: e.hex })
        } else if (statename === 'h1color') {
            this.setState({ h1color: e.hex })
        } else if (statename === 'h2color') {
            this.setState({ h2color: e.hex })
        } else if (statename === 'h3color') {
            this.setState({ h3color: e.hex })
        } else if (statename === 'h4color') {
            this.setState({ h4color: e.hex })
        } else if (statename === 'h5color') {
            this.setState({ h5color: e.hex })
        } else if (statename === 'h6color') {
            this.setState({ h6color: e.hex })
        } else if (statename === 'featuredcolor') {
            this.setState({ featuredcolor: e.hex })
        } else if (statename === 'primarycolor') {
            this.setState({ primarycolor: e.hex })
        } else if (statename === 'secondarycolor') {
            this.setState({ secondarycolor: e.hex })
        } else if (statename === 'menulinks') {
            this.setState({ menulinks: e.hex })
        } else if (statename === 'btntextcolor') {
            this.setState({ btntextcolor: e.hex })
        } else if (statename === 'btnbgcolor') {
            this.setState({ btnbgcolor: e.hex })
        } else if (statename === 'btnhovertextcolor') {
            this.setState({ btnhovertextcolor: e.hex })
        } else if (statename === 'btnhoverbgcolor') {
            this.setState({ btnhoverbgcolor: e.hex })
        } else if (statename === 'btnhoverbordercolor') {
            this.setState({ btnhoverbordercolor: e.hex })
        }
    }
    saveColor = (e) => {
        const { bodycolor, footercolor, headercolor, pcolor, h1color, h2color, h3color, h4color, h5color, h6color, featuredcolor, primarycolor, secondarycolor, menulinks, btntextcolor, btnbgcolor, btnhovertextcolor, btnhoverbgcolor, btnhoverbordercolor } = this.state;
        this.setState({ isSubmitcred: true });
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update-dynamic-color`, {
            method: "POST",
            body: JSON.stringify({ bodycolor, footercolor, headercolor, pcolor, h1color, h2color, h3color, h4color, h5color, h6color, featuredcolor, primarycolor, secondarycolor, menulinks, btntextcolor, btnbgcolor, btnhovertextcolor, btnhoverbgcolor, btnhoverbordercolor }),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`
            })
        });
        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    this.setState({ isSubmitcred: false });
                    // swal("Done", data.ResponseText, "success");
                    swalpopup(data.ResponseText, "success")
                    this.UpdateThirdSTepDashboard();
                    this.props.dispatch(getDynamicOptions());
                } else {
                    this.setState({ isSubmitcred: false });
                    // swal("Oops!", data.ResponseText, "error");
                    swalpopup(data.ResponseText, "error")
                }
            })
            .catch(err => {
            });
    }
    savefontfam = (e) => {
        const { pfont, h1font, h2font, h3font, h4font, h5font, h6font, primaryfont } = this.state;
        this.setState({ isSubmitcred: true });
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update-dynamic-font`, {
            method: "POST",
            body: JSON.stringify({ pfont, h1font, h2font, h3font, h4font, h5font, h6font, primaryfont }),
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Auth-Token": `${localStorage.getItem("token")}`
            })
        });
        return fetch(request)
            .then(res => res.json())
            .then(data => {
                if (data.ResponseCode === "1") {
                    this.setState({ isSubmitcred: false });
                    // swal("Done", data.ResponseText, "success");
                    swalpopup(data.ResponseText, "success")
                    this.UpdateThirdSTepDashboard();
                    this.props.dispatch(getDynamicOptions());
                } else {
                    this.setState({ isSubmitcred: false });
                    // swal("Oops!", data.ResponseText, "error");
                    swalpopup(data.ResponseText, "error")
                }
            })
            .catch(err => {
            });
    }

    additmodal = (e, header, image_id) => {
        this.setState({
            image_id: image_id,
            heading: header
        })
    }
    Addpartner = (e) => {
        // const { newimage_name, newtitle, newlinkurl, newdescription } = this.state;
        // this.setState({ isSubmitcred: true });
        // const heading = "HOMESIDEBAR";
        // const image_id = "";
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/add-platformimagess`, {
        //     method: "POST",
        //     body: JSON.stringify({ partnerimage , newimage_name, newtitle, newlinkurl, heading, image_id, newdescription }),
        //     headers: new Headers({
        //         "Content-Type": "application/json",
        //         "X-Auth-Token": `${localStorage.getItem("token")}`
        //     })
        // });
        //  return fetch(request)
        //         .then(res => res.json())
        //         .then(data => {
        //             if (data.ResponseCode === "1") {
        //                 // this.setState({ isSubmitcred: false });
        //                 this.props.dispatch(getDynamicplatformimages());
        //                 // swal("Done", data.ResponseText, "success");
        //                 swalpopup(data.ResponseText, "success");
        //                 document.getElementById("closee").click();
        //             } else {
        //                 // swal("Oops!", data.ResponseText, "error");
        //                 swalpopup(data.ResponseText, "error");
        //             }
        //         })
        //         .catch(err => {
        //         });


        const { newimage_name, newtitle, newlinkurl, newdescription } = this.state;
        e.preventDefault();
        var validate = this.Validatorhomepartner;

        // var url = this.state.url;
        const heading = "HOMESIDEBAR";
        const image_id = ""
        if (validate.allValid()) {
            this.setState({ isSubmitcred: true });
            let request;
            let formData = new FormData();
            formData.append('partnerimage', $('#partnerimage')[0].files[0]);
            formData.append('newimage_name', newimage_name);
            formData.append('newtitle', newtitle);
            formData.append('newlinkurl', newlinkurl);
            formData.append('heading', heading);
            formData.append('image_id', image_id);
            formData.append('newdescription', newdescription);
            request = {
                method: 'POST',
                url: `${process.env.REACT_APP_APIURL}/user/add-platformimages`,
                headers: { 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` },
                data: formData
            };

            axios(request).then((data) => {
                if (data.data.ResponseCode === "1") {
                    this.setState({ isSubmitcred: false, newimage_name: '', newtitle: '', newlinkurl: '', heading: '', image_id: '', newdescription: '', partnerimagefile: "" });
                    this.UpdateThirdSTepDashboard();
                    this.props.dispatch(getDynamicplatformimages());
                    swalpopup(data.data.ResponseText, "success");
                    // window.location.reload();

                } else {
                    this.setState({ isSubmitcred: false });
                }
            });
        } else {
            this.setState({ isSubmithomebanner: false });
            validate.showMessages();
            this.forceUpdate();
        }


    }
    handleCheckbox(e) {
        // if (e.target.name == "about_fanhub_home_status") {
        let isChecked = e.target.checked;
        this.setState({
            [e.target.name]: isChecked
        })
        // }
        let formData = new FormData();
        formData.append('about_fanhub_home_status', isChecked);
        formData.append('user_id', this.state.currid);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/update_hub_details`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitmetas: false });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getUserdetails(this.state.currid));
                this.UpdateThirdSTepDashboard();
                swalpopup(data.ResponseText, 'success')
            } else {
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitmetas: false });
        })

    }
    update_contact_info = () => {
        let formData = new FormData();
        this.setState({ isSubmitmetas: true });

        formData.append('fanhub_contact_no', this.state.fanhub_contact_no);
        formData.append('fanhub_email', this.state.fanhub_email);
        formData.append('fanhub_address', this.state.fanhub_address);
        formData.append('user_id', this.state.currid);
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/fanhub-contact-info`, {
            method: "POST",
            headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
            body: formData
        });
        return fetch(request).then(res => res.json()).then((data) => {
            this.setState({ isSubmitmetas: false });
            if (data.ResponseCode == "1") {
                this.props.dispatch(getUserdetails(this.state.currid));
                // swal("Done", data.ResponseText, "success");
                this.UpdateThirdSTepDashboard();
                swalpopup(data.ResponseText, 'success')
            } else {
                // swal("Oops!", data.ResponseText, "error");
                swalpopup(data.ResponseText, 'error')
            }
        }).catch((err) => {
            this.setState({ isSubmitmetas: false });
        })
    }
    statusInfo = (e, type) => {
        let cate = "";
        let status = "";
        if (type == "banner") {
            // this.setState({
            // banner_status: !this.state.banner_status
            // })
            cate = "Banners";
            status = this.state.banner_status == "1" ? "disable" : "enable";
        } else if (type == "cta") {
            // this.setState({
            // call_to_action_status: !this.state.call_to_action_status
            // })
            cate = "Call to action";
            status = this.state.call_to_action_status == "1" ? "disable" : "enable";
        } else if (type == "aboutfanhub") {
            // this.setState({
            // about_fanhub_home_status: !this.state.about_fanhub_home_status
            // })
            cate = "About Fanhub";
            status = this.state.about_fanhub_home_status == "1" ? "disable" : "enable";
        } else if (type == "navigationbox") {
            // this.setState({
            // navigation_boxes_status: !this.state.navigation_boxes_status
            // })
            cate = "Navigation Boxes";
            status = this.state.navigation_boxes_status == "1" ? "disable" : "enable";
        } else if (type == "fanhubfeed") {
            // this.setState({
            // social_feed_status: !this.state.social_feed_status
            // })
            cate = "Fanhub Feeds";
            status = this.state.social_feed_status == "1" ? "disable" : "enable";
        } else if (type == "communitypartners") {
            // this.setState({
            // community_partners: !this.state.community_partners
            // })
            cate = "Community Partners";
            status = this.state.community_partners == "1" ? "disable" : "enable";
        } else if (type == "contactinfo") {
            // this.setState({
            // contact_info_status: !this.state.contact_info_status
            // })
            cate = "Contact Info";
            status = this.state.contact_info_status == "1" ? "disable" : "enable";
        }
        swal({
            title: `Are you sure you want to ` + status + ` ` + cate + `?`,
            buttons: ["Cancel", "Yes"],
            dangerMode: true
        }).then(willUpdate => {
            if (willUpdate) {

                let formData = new FormData();
                this.setState({ isSubmitmetas: true });

                formData.append('type', type);
                formData.append('user_id', this.state.currid);
                const request = new Request(`${process.env.REACT_APP_APIURL}/user/fanhub-contact-info-status`, {
                    method: "POST",
                    headers: { 'X-Auth-Token': `${localStorage.getItem('token')}` },
                    body: formData
                });
                return fetch(request).then(res => res.json()).then((data) => {
                    this.setState({ isSubmitmetas: false });
                    if (data.ResponseCode == "1") {
                        this.props.dispatch(getUserdetails(this.state.currid));
                        this.UpdateThirdSTepDashboard();
                        swalpopup(data.ResponseText, 'success')
                    } else {
                        swalpopup(data.ResponseText, 'error')
                    }
                }).catch((err) => {
                    this.setState({ isSubmitmetas: false });
                })
            } else {
            }
        });

    }
    render() {
        // const primaryfont = getComputedStyle(document.documentElement).getPropertyValue('--primaryfont');
      
        const { allmenusadmindata, dynamicoptions } = this.state;
        let banneri = 1;
        let bannerj = 1;
        let homebanneri = 1;
        let homebannerj = 1;
        let communitybanner = 1;
        $('#menu-toggle').on('click', function () {
            if ($("#wrapper").hasClass("toggled")) {
                $(".admin-side-menu").removeClass('hover_unbind');
                $('.collapse').removeClass('show');
            } else {
                $(".admin-side-menu").addClass('hover_unbind');
            }
        });

        $('.card-header, .card-title, .btn.btn-header-link').on('click', function () {
            $("#wrapper").addClass("toggled");
            $(".admin-side-menu").addClass('hover_unbind');
        });

        if (!$(".admin-side-menu").hasClass("hover_unbind")) {
            $(".admin-side-menu").on("mouseleave", function () {
                if (!$(this).hasClass('hover_unbind')) {
                    $(this).removeClass('hover_bind');
                    $("#wrapper,.admin-header").removeClass("toggled");
                    $("#wrapper,.admin-header").removeClass("toggled_hover");
                }
            });
        }
        return (
            <>
                {
                    window.location.pathname === "/customize-homepage" || window.location.pathname === "/customize-fanhublogo" ?
                        <>
                            <div className="activity-center">
                                <h5><NavLink to="/activity-center"><i className="fa fa-chevron-left me-2" aria-hidden="true"></i> Activity Center</NavLink></h5>
                            </div>
                            <div id="accordion" className="accordion cfh pb-5">
                                <div className="card mb-0">
                                    <span className="customize_title ps-0">Theme</span>
                                    <div id={`menu_theme`} className="card-body collapse show p-0 py-0" data-parent="#accordion">
                                        <div className="sidemenu float-start w-100">
                                            <div className="float-start w-100">
                                                <div id="accordionmode" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionmode" href={`#menu_mode`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The theme of the Fan Hub determines its overall appearance, including the colors, fonts, and styles used. Selecting a theme can help you create a visually appealing and cohesive design for your Fan Hub.
                                                                                    <br/><br/>
                                                                                    Choose a theme from the "Select Platform Theme" dropdown to apply it to your Fan Hub. You can choose between options like Dark Blue, All Black, Cool Grey, and Light.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Mode - Dark, Light</span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_mode`} className="card-body collapse py-0" data-parent="#accordionmode">
                                                            <div className="sidemenu float-start w-100 mt-2 mb-2 pl-4">
                                                                {/* <li> */}
                                                                <div className="select-plan-details col-md-12 p-0 mb-3">
                                                                    <h6 className="mt-0" htmlFor="description">Select Platform Theme</h6>
                                                                    <select name="platform_theme" id="platform_theme" className="form-control" value={this.state.platform_theme} onChange={(e) => this.dataChange(e)}>
                                                                        <option value="">Select Theme</option>
                                                                        <option value="dark">Dark Blue</option>
                                                                        <option value="dark_2"> All Black</option>
                                                                        <option value="dark_3">Cool Grey</option>
                                                                        <option value="light">Light</option>
                                                                    </select>
                                                                </div>
                                                                <div className="registered text-center upload-file">
                                                                    <button type="button" onClick={(ef) => this.setplatformtheme(ef, this.state.platform_theme)} disabled={this.state.isSubmitVideo} className="cfh_button mt-1">{this.state.isSubmitVideo ? 'Please wait' : 'Update'}</button>
                                                                </div>
                                                                {/* </li> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* plateform images  */}
                                                {/* <div id="accordionmodeimg" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionmodeimg" href={`#image_mode`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The theme of the Fan Hub determines its overall appearance, including the colors, fonts, and styles used. Selecting a theme can help you create a visually appealing and cohesive design for your Fan Hub.
                                                                                    <br /><br />
                                                                                    Choose a theme from the "Select Platform Theme" dropdown to apply it to your Fan Hub. You can choose between options like Dark Blue, All Black, Cool Grey, and Light.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>select plateform image</span>
                                                            </div>
                                                        </div>
                                                        <div id={`image_mode`} className="card-body collapse" data-parent="#accordionmodeimg">
                                                            <div className="sidemenu float-start w-100 mt-2 mb-2 pl-4">
                                                               
                                                                <div className="col-md-12 p-0 mb-3">
                                                                    <h6 style={{ color: 'black' }} className="mt-0" htmlFor="description">Select Platform Theme</h6>
                                                                    <select name="platform_theme" id="platform_theme" className="form-control" value={this.state.platform_theme} onChange={(e) => this.dataChange(e)}>
                                                                        <option value="">Select Theme</option>
                                                                        <option value="dark">Dark Blue</option>
                                                                        <option value="dark_2"> All Black</option>
                                                                        <option value="dark_3">Cool Grey</option>
                                                                        <option value="light">Light</option>
                                                                    </select>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                         */}


                                                {/* plateform images  */}










                                                {/* ---------------Images section start------------------------- */}
                                                <div className="float-start w-100">
                                                <div id="accordionlobbysetup" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionlobbysetup" href={`#menu_left_lobbysetup`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                <i className="fa fa-image" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                The homepage slider on the fanhub does both by presenting your products or services, displaying your brand, or assisting your consumers with improved site navigation by displaying the most important things on your banner.                                                                   

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Lobby Image

                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_left_lobbysetup`} className="card-body collapse py-0" data-parent="#accordionlobbysetup">
                                                            <div className="sidemenu float-lleft w-100 pl-4 pt-2">
                                                                <div className="float-start w-100">
                                                                    <p className="m-0">
                                                                        <NavLink className="mb-0" onClick={(e) => this.remtrid(e, "leftmenu")} target='' to="/lobby-banner-setup"><i className="fas fa-cog me-1"></i>Lobby Setup</NavLink>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                {/* <div id="accordionbanner" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionbanner" href={`#lobby_banner`} >
                                                            <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                                <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-image" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The homepage slider on the fanhub does both by presenting your products or services, displaying your brand, or assisting your consumers with improved site navigation by displaying the most important things on your banner.                                                                    </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span className="d-flex align-items-center justify-content-between float-start w-100"> Lobby Image</span>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'banner')} className="hidebutton">
                                                            {this.state.banner_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                                        </a>
                                                        <div id={`lobby_banner`} className="card-body collapse py-0" data-parent="#accordionbanner">
                                                            <div className="sidemenu float-start w-100 pl-4 mt-2">
                                                                <div className="float-start w-100">
                                                                    <h6>Desktop image</h6>
                                                                    <p className="customize_inputtitle">1600x580px recommended</p>
                                                                    <div className="">
                                                                        {
                                                                            this.props.lobbydesktoploading ?
                                                                                <>Loading...</>
                                                                                :
                                                                                this.state.lobbydesktopbanner ?
                                                                                    this.state.lobbydesktopbanner.length > 0 ? this.state.lobbydesktopbanner.map((post) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div key={post.banner_id}>
                                                                                                    <div className="float-start w-100 d-flex align-items-center justify-content-between">
                                                                                                        Slide {banneri++}
                                                                                                        <span className="">
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className="desktop_sliders float-start w-100 mt-1">
                                                                                                        {
                                                                                                            post.lobby_image.split('.').pop() == "webm" || post.lobby_image.split('.').pop() == "mp4" ?

                                                                                                                <div className="float-start w-100">
                                                                                                                    <video className="dynamicimages_sidebar w-100" width="100%" height="auto" preload="auto" autoplay="false" loop muted playsinline>
                                                                                                                        <source id={`${post.banner_id}_banner`} src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.lobby_image}`} />
                                                                                                                    </video>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.lobby_image}?random=${moment().format('YYYYMM-DDHHmmss')}`} />
                                                                                                        }
                                                                                                    </div>

                                                                                              </div>
                                                                                                <hr />
                                                                                            </>
                                                                                        )
                                                                                    }) :
                                                                                        <div className="no-events col-md-12 text-center my-2">
                                                                                            <p className="mb-0">No Banners</p>
                                                                                        </div>
                                                                                    :
                                                                                    <div className="no-events col-md-12 text-center my-2">
                                                                                        <p className="mb-0">No Banners</p>
                                                                                    </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="float-start w-100"><h6>Mobile Image</h6>
                                                                    <p className="customize_inputtitle">600x600px recommended</p>
                                                                    <div className="">
                                                                        {
                                                                            this.props.lobbydesktoploading ?
                                                                                <>Loading...</>
                                                                                :
                                                                                this.state.lobbymobilesingbanner ?
                                                                                    this.state.lobbymobilesingbanner.length > 0 ? this.state.lobbymobilesingbanner.map((post) => {

                                                                                        return (
                                                                                            <>
                                                                                                <div key={post.banner_id}>
                                                                                                    <div className="float-start w-100 d-flex align-items-center justify-content-between">
                                                                                                        Slide {bannerj++}
                                                                                                        <span className="">
                                                                                                         </span>
                                                                                                    </div>
                                                                                                    <div className="desktop_sliders float-start w-100 mt-1">

                                                                                                        {
                                                                                                            post.lobby_image.split('.').pop() == "webm" || post.lobby_image.split('.').pop() == "mp4" ?
                                                                                                                <span>
                                                                                                                    <video className="dynamicimages_sidebar w-100" width="100%" height="auto" preload="auto" autoplay="true" loop muted playsinline>
                                                                                                                        <source id={`${post.bannerid}_banner`} src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.lobby_image}`} />
                                                                                                                    </video>
                                                                                                                </span>
                                                                                                                :
                                                                                                                <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.lobby_image}?random=${moment().format('YYYYMM-DDHHmmss')}`} />
                                                                                                        }
                                                                                                    </div>

                                                                                                    <span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <hr />
                                                                                            </>
                                                                                        )
                                                                                    }) :
                                                                                        <div className="no-events col-md-12 text-center my-2">
                                                                                            <p className="mb-0">No Banners</p>
                                                                                        </div>
                                                                                    :
                                                                                    <div className="no-events col-md-12 text-center my-2">
                                                                                        <p className="mb-0">No Banners</p>
                                                                                    </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="float-start w-100">
                                                                    <div id="accordionnewbanner" className="accordion initial_menubar update_theme">
                                                                        <div className="card mb-0 p-0">
                                                                            <div className="card-header-add add-new-slide my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordionnewbanner" href={`#menu_addbanner`} >
                                                                                <div className="card-title mb-0 d-flex align-items-center justify-content-start">
                                                                                    <NavLink className="btn btn-header-link p-0 m-0 float-none w-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                        <span className="tooltippptext">
                                                                                            <div className="">
                                                                                                <div className="">
                                                                                                    <p className="gray-text">
                                                                                                        Add Image here
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>

                                                                                    <a className="add_slide w-0 float-none mb-0" href="javascript:void(0)"><i className="fas fa-plus-circle d-inline-block me-1"></i> Add a new Image</a>

                                                                                </div>
                                                                            </div>
                                                                            <div id={`menu_addbanner`} className="card-body collapse py-0" data-parent="#accordionnewbanner">
                                                                                <div className="">
                                                                                    <FormGroup className="w-100">
                                                                                        <FormLabel className="d-flex alin-items-center justify-content-start" htmlFor="title">
                                                                                            <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                                <span className="tooltippptext">
                                                                                                    <div className="">
                                                                                                        <div className="">
                                                                                                            <p className="gray-text">
                                                                                                                Recommended image properties<br />
                                                                                                                ● <b>Mobile Dimensions:</b> 675 x 339<br />
                                                                                                                ● <b>Desktop Dimensions:</b> 1920 x 269<br />
                                                                                                                ● <b>Size:</b> less than 5 MB<br />
                                                                                                                ● <b>Format:</b> .jpg, .gif, or .png
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                            <span style={{ color: 'black' }}>Lobby Image</span>
                                                                                        </FormLabel>
                                                                                        <br />
                                                                                        <div className="registered text-center upload-file mb-3">
                                                                                            <label htmlFor="lobbybannerimage" className="">
                                                                                                <div className="btn-file">

                                                                                                    {
                                                                                                        this.state.lobbybannerimagefile ?
                                                                                                            this.state.lobbybannerimagefile !== "data:image/png;base64" ?
                                                                                                                <label htmlFor="lobbybannerimage" className="choose_file">
                                                                                                                    <span className="" ><img className="w-100" src={this.state.lobbybannerimagefile} /></span>
                                                                                                                </label>
                                                                                                                :
                                                                                                                <div className="btn-file__actions">
                                                                                                                    <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                                        <div className="btn-file__actions__item--shadow">
                                                                                                                            <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                                            <div className="visible-xs-block"></div>
                                                                                                                            <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                                            <p className="my-2"><small>Or</small></p>
                                                                                                                            <h4>Browse media on your device</h4>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            :
                                                                                                            <div className="btn-file__actions">
                                                                                                                <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                                    <div className="btn-file__actions__item--shadow">
                                                                                                                        <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                                        <div className="visible-xs-block"></div>
                                                                                                                        <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                                        <p className="my-2"><small>Or</small></p>
                                                                                                                        <h4>Browse media on your device</h4>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    }


                                                                                                </div>
                                                                                            </label>

                                                                                            <input className='d-none' name="lobbybannerimage" id="lobbybannerimage" type="file" onChange={(e) => this.dataChange(e)} />
                                                                                        </div>





                                                                                        <div>
                                                                                            <FormGroup className="w-100 mb-3">
                                                                                                <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                                        <span className="tooltippptext">
                                                                                                            <div className="">
                                                                                                                <div className="">
                                                                                                                    <p className="gray-text">
                                                                                                                        Choose the Slide Type here, you can use a video Slide.
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <span style={{ color: 'black' }}>Upload video</span></FormLabel>
                                                                                                <br />
                                                                                                <select className="form-control" onChange={this.videochangediv}>
                                                                                                    <option value="">Select Type</option>
                                                                                                    <option value="yes">yes</option>
                                                                                                    <option value="no">no</option>
                                                                                                </select>
                                                                                            </FormGroup>
                                                                                            {
                                                                                                this.state.showActiondiv && (
                                                                                                    <div className="registered text-center upload-file mb-3">
                                                                                                        <label htmlFor="lobbyvideo" className="">
                                                                                                            <div className="btn-file">

                                                                                                                {
                                                                                                                    this.state.my_lobby_video ?

                                                                                                                        <label htmlFor="lobbybannerimage" className="choose_file">
                                                                                                                            <span className="" ><img className="w-100" src={this.state.my_lobby_video} /></span>
                                                                                                                        </label>
                                                                                                                        :
                                                                                                                        <div className="btn-file__actions">
                                                                                                                            <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                                                <div className="btn-file__actions__item--shadow">
                                                                                                                                    <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                                                    <div className="visible-xs-block"></div>
                                                                                                                                    <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                                                    <p className="my-2"><small>Or</small></p>
                                                                                                                                    <h4>Browse media on your device</h4>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>


                                                                                                                }


                                                                                                            </div>
                                                                                                        </label>

                                                                                                        <input className='d-none' name="lobbyvideo" id="lobbyvideo" type="file" onChange={(e) => { this.dataChange(e); }} />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>



                                                                                    </FormGroup>
                                                                                    
                                                                                    <FormGroup className="w-100 select-plan-details mb-3">
                                                                                        <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                                                            <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                                <span className="tooltippptext">
                                                                                                    <div className="">
                                                                                                        <div className="">
                                                                                                            <p className="gray-text">
                                                                                                                Choose the Slide Device here
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                            <span style={{ color: 'black' }}>Visible on</span></FormLabel>
                                                                                        <br />
                                                                                        <select className="form-control" name="bannertype" onChange={e => this.dataChange(e)}>
                                                                                            <option value="">Screen Type</option>
                                                                                            <option value="desktop">Desktop</option>
                                                                                            <option value="mobile">Mobile</option>
                                                                                        </select>
                                                                                        {this.tourValidatorhomebanner.message('bannertype', this.state.bannertype, 'required')}
                                                                                    </FormGroup>
                                                                                    <div className="text-center wrapper">
                                                                                        <button type="submit" onClick={(e) => this.AddLobbyBanner(e, '756753335', 'lobby')} disabled={this.state.isSubmitLobbybanner} className="cfh_button">{this.state.isSubmitLobbybanner ? 'Please wait' : 'Add'}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}

                                                {/* -----------------------------------------images section end---------------------------- */}
                                            </div>
                                        </div>
                                    </div>

                                    <span className="customize_title ps-0">Meta</span>
                                    <div id={`menu_metas`} className="card-body collapse show p-0 py-0" data-parent="#accordion">
                                        <div className="sidemenu">
                                            <div>
                                                <div id="accordionmeta" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionmeta" href={`#menu_meta`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The Meta title and Description of the Fan Hub is displayed in the tab title of the browser you’re currently using and it’s also the name used when you bookmark a page, and Meta Description act as a short summary device that internet users can use to Index your Fan Hub.
                                                                                    <br /><br />
                                                                                    Click here to learn more about the Fan Hub's Meta title and Description
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Title and Description</span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_meta`} className="card-body collapse py-0" data-parent="#accordionmeta">
                                                            <div className="select-plan-details sidemenu float-start w-100 mb-0 mt-2 pl-4">
                                                                <div className="float-start w-100">
                                                                    <h6 className="customize_inputtitle pt-0">Meta Title</h6>
                                                                    <input type="text" maxLength="2000" id="default_meta_title" placeholder="Describe your meta title" className="form-control" name="default_meta_title" onChange={(e) => this.dataChange(e)} value={this.state.default_meta_title} />
                                                                </div>
                                                                <div className="float-start w-100">
                                                                    <h6 className="customize_inputtitle mt-3">Meta Description</h6>
                                                                    <textarea type="text" rows="6" cols="50" maxLength="2000" id="default_meta_description" placeholder="Describe your meta Description" className="form-control" name="default_meta_description" onChange={(e) => this.dataChange(e)} value={this.state.default_meta_description} >{this.state.default_meta_description}</textarea>
                                                                </div>
                                                                <div className="float-start w-100">
                                                                    <button type="button" onClick={(e) => this.update_metas(e)} disabled={this.state.isSubmitmetas} className="cfh_button mt-3">{this.state.isSubmitmetas ? 'Please wait' : 'Update'}</button>
                                                                </div>
                                                            </div>
                                                            {/* <ul className="sidemenu">
                                                                <li>
                                                                    <span className="customize_inputtitle">Platform Name</span>
                                                                    <input type="text" maxLength="2000" id="platform_short_name" placeholder="Describe your platform name" className="form-control" name="platform_short_name" onChange={(e) => this.dataChange(e)} value={this.state.platform_short_name} />
                                                                </li>
                                                            
                                                                <button type="button" onClick={(e) => this.update_platform_hub(e)} disabled={this.state.isSubmithub} className="cfh_button mt-3">{this.state.isSubmithub ? 'Please wait' : 'Update'}</button>  
                                                            </ul>   */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div id="accordionpreviewimg" className="accordion initial_menubar update_theme">
                                                    <div className="card p-0 mb-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionpreviewimg" href={`#menu_previewimg`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    Your Fan Hub preview images are the images that appear when you share your Fan Hub URL. Preview Images are vital to your brand's appearance and success on social media and beyond.
                                                                                    <br /><br />
                                                                                    Click here to learn more about the Fan Hub Preview Images.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Preview Image</span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_previewimg`} className="card-body mt-2 collapse py-0" data-parent="#accordionpreviewimg">
                                                            <div className="sidemenu pl-4">
                                                                <div>
                                                                </div>
                                                                <div>
                                                                    <div className="registered text-center upload-file">
                                                                        <label htmlFor="preview-image" className="choose_file">
                                                                            {this.state.previewimage && this.state.checkparam == "preview-image" ?

                                                                                <span><img className="dynamicimages_sidebar w-100" src={this.state.previewimage} /></span>
                                                                                :
                                                                                <span><img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/xrsports.games-homepage/preview-image.jpg?random=${moment().format('YYYYMM-DDHHmmss')}`} /></span>
                                                                            }
                                                                        </label>
                                                                        <input id="preview-image" className="hide-input" type="file" name="preview-image" onChange={e => this.validateForm(e)} onClick={(e) => this.checkParam(e, 'preview-image')} />
                                                                        <button type="button" onClick={(e) => this.uploadVideo(e)} disabled={this.state.isSubmitVideo} className="cfh_button mt-2">{this.state.isSubmitVideo ? 'Please wait' : 'Update'}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div id="accordionfavicon" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionfavicon" href={`#menu_favicon`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    Favicon is an icon associated with your Fan Hub, typically displayed in the address bar of a browser accessing the site or next to the Fan Hub name in a user's list of bookmarks.
                                                                                    <br /><br />
                                                                                    Click here to learn more about the Fan Hub Favicon.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span className="ps-0">Favicon</span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_favicon`} className="card-body collapse py-0" data-parent="#accordionfavicon">
                                                            <div className="sidemenu pl-4">
                                                                <div>
                                                                    <div className="registered text-center upload-file">
                                                                        <label htmlFor="favicon" className="choose_file">

                                                                            {this.state.imagelelogo && this.state.checkparam == "favicon" ?

                                                                                <span><img className="dynamicimages_sidebar w-100" src={this.state.imagelelogo} /></span>
                                                                                :
                                                                                <span><img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/favicon.jpg?random=${moment().format('YYYYMM-DDHHmmss')}`} /></span>
                                                                            }
                                                                        </label>
                                                                        <input id="favicon" className="hide-input" type="file" name="favicon" onChange={e => this.validateTourForm(e)} onClick={(e) => this.checkParam(e, 'favicon')} />
                                                                        <button type="button" onClick={(e) => this.uploadVideo(e)} disabled={this.state.isSubmitVideo} className="cfh_button mt-1">{this.state.isSubmitVideo ? 'Please wait' : 'Update'}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                    <span className="customize_title ps-0">Header</span>
                                    <div id={`menu_header`} className="card-body collapse show p-0 py-0" data-parent="#accordion">
                                        <div className="float-start w-100">
                                            <div className="float-start w-100">
                                                <div id="accordion_platformname" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordion_platformname" href={`#menu_platformname`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The Platform Name of the Fan Hub is displayed in the top of the platofomr header.
                                                                                    <br /><br />
                                                                                    Click here to learn more about the Fan Hub's Platform Name
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Platform Name</span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_platformname`} className="card-body collapse py-0" data-parent="#accordion_platformname">
                                                            <div className="select-plan-details sidemenu float-start w-100 mt-3 mb-0 pl-4">
                                                                <div className="float-start w-100">
                                                                    <h6 className="customize_inputtitle pt-0">Platform Name</h6>
                                                                    <input type="text" maxLength="2000" id="platform_short_name" placeholder="Describe your platform name" className="form-control" name="platform_short_name" onChange={(e) => this.dataChange(e)} value={this.state.platform_short_name} />
                                                                </div>
                                                                <button type="button" onClick={(e) => this.update_platform_hub(e)} disabled={this.state.isSubmithub} className="cfh_button mt-3">{this.state.isSubmithub ? 'Please wait' : 'Update'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="float-start w-100">
                                                <div id="accordionlogo" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header mt-2 collapsed my-1" data-bs-toggle="collapse" data-parent="#accordionlogo" href={`#menu_logo`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    You can update your Fan Hub logo here,  Your Fan Hub Logo is will be used on the main header of the website, and on the emails that will be sent of to your fans.
                                                                                    <br /><br />
                                                                                    Click here to learn more about the best practices to upload a Fan Hub Logo.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Logo</span>
                                                            </div>
                                                        </div>

                                                        <div id={`menu_logo`} className="card-body mt-2 collapse py-0" data-parent="#accordionlogo">
                                                            <div className="sidemenu float-start w-100 pl-4">
                                                                <div className="float-start w-100">
                                                                    <div className="registered text-center upload-file">
                                                                        <label htmlFor="logo_one_url" className="choose_file">

                                                                            {this.state.logo_one ?

                                                                                <span><img className="dynamicimages_sidebar w-100" src={this.state.imagelelogo} /></span>
                                                                                :
                                                                                <span><img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/mav-header/Logo_dark.png?random=${moment().format('YYYYMM-DDHHmmss')}`} /></span>
                                                                            }
                                                                        </label>
                                                                        <input id="logo_one_url" className="hide-input" type="file" name="logo_one" onChange={e => this.validateTourForm(e)} onClick={(e) => this.checkParam(e, 'logo_one_url')} />
                                                                        <button type="button" onClick={(e) => this.uploadVideo(e)} disabled={this.state.isSubmitVideo} className="cfh_button mt-1">{this.state.isSubmitVideo ? 'Please wait' : 'Update'}</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="float-start w-100">
                                                <div id="accordionmenu" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionmenu" href={`#menu_left`} >
                                                            <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    The Activelink menu editor allows you to create multi-level menus using a simple 'drag and drop' interface. Drag menu items up or down to change their order of appearance in the menu.
                                                                                    <br /><br />
                                                                                    Click here to learn more about customizing your fan hub.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span>Left Menu

                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div id={`menu_left`} className="card-body collapse py-0" data-parent="#accordionmenu">
                                                            <div className="sidemenu float-lleft w-100 pl-4 pt-2">
                                                                <div className="float-start w-100">
                                                                    <p className="m-0">
                                                                        <NavLink className="mb-0" onClick={(e) => this.remtrid(e, "leftmenu")} target='' to="/add-custom-menu"><i className="fas fa-cog me-1"></i>Menu Setting</NavLink>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="customize_title ps-0 mt-2">Homepage Elements</span>

                                    {/* ----------------- Homepage banner starts here --------------- */}
                                    <div id="accordionbanner" className="accordion initial_menubar update_theme mt-2">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionbanner" href={`#menu_banner`} >
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
                                                        <i className="fa fa-image" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        The homepage slider on the fanhub does both by presenting your products or services, displaying your brand, or assisting your consumers with improved site navigation by displaying the most important things on your banner.                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100"> Slider</span>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'banner')} className="hidebutton">
                                                {/* <i style={{ position: '', right: '', display: 'block' }} className={this.state.banner_status == "1" ? `far fa-solid fa-eye` : `far fa-solid fa-eye-slash`} /> */}
                                                {this.state.banner_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                            <div id={`menu_banner`} className="card-body collapse py-0" data-parent="#accordionbanner">
                                                <div className="sidemenu float-start w-100 pl-4 mt-2">
                                                    <div className="float-start w-100">
                                                        <h6>Desktop Slides</h6>
                                                        <p className="customize_inputtitle">1600x580px recommended</p>
                                                        <div className="">
                                                            {
                                                                this.props.desktopbannerloading ?
                                                                    <>Loading...</>
                                                                    :
                                                                    this.state.desktopbanner ?
                                                                        this.state.desktopbanner.length > 0 ? this.state.desktopbanner.map((post) => {

                                                                            return (
                                                                                <>
                                                                                    <div key={post.bannerid}>
                                                                                        <div className="float-start w-100 d-flex align-items-center justify-content-between">
                                                                                            Slide {homebanneri++}
                                                                                            <span className="">
                                                                                                <a className="me-2 float-none w-0" onClick={(e) => this.props.edithomebanner(e, post.bannerid, post.link, post.image, post.bannertype, post.screentype, post.text, "yes")} href="#modal-container-updatebanner" role="button" data-bs-toggle="modal"><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                                                                <a className="float-none w-0" onClick={(e) => this.deleteBanner(e, post.bannerid)}><i className="fa fa-trash me-1" aria-hidden="true"></i></a>
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}?random=${moment().format('YYYYMM-DDHHmmss')}`} /> */}
                                                                                        <div className="desktop_sliders float-start w-100 mt-1">
                                                                                            {
                                                                                                post.image.split('.').pop() == "webm" || post.image.split('.').pop() == "mp4" ?

                                                                                                    <div className="float-start w-100">
                                                                                                        <video className="dynamicimages_sidebar w-100" width="100%" height="auto" preload="auto" autoplay="false" loop muted playsinline>
                                                                                                            <source id={`${post.bannerid}_banner`} src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}`} />
                                                                                                        </video>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}?random=${moment().format('YYYYMM-DDHHmmss')}`} />
                                                                                            }
                                                                                        </div>

                                                                                        {/* BannerID: {post.bannerid}<br/>
                                                                                                                Link: {post.link}<br/>
                                                                                                                text: {post.text && post.text !== 'undefined'?post.text:'---'}<br/> */}
                                                                                        {/* <span> */}
                                                                                        {/* <a className="viewimagetour" onClick={(e) => this.setHomepageImage(e,post.image)} href="#modal-container-banner" role="button" data-bs-toggle="modal">view</a> */}

                                                                                        {/* <a id="modal-385731" href="#modal-container-385731" role="button" className="btn" data-bs-toggle="modal">Launch demo modal</a> */}

                                                                                        {/* </span> */}
                                                                                        {/* <a className='cfh_button' onClick={(e)=>this.deleteBanner(e,post.bannerid)}>Delete</a> */}
                                                                                    </div>
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        }) :
                                                                            <div className="no-events col-md-12 text-center my-2">
                                                                                <p className="mb-0">No Banners</p>
                                                                            </div>
                                                                        :
                                                                        <div className="no-events col-md-12 text-center my-2">
                                                                            <p className="mb-0">No Banners</p>
                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="float-start w-100"><h6>Mobile Slides</h6>
                                                        <p className="customize_inputtitle">600x600px recommended</p>
                                                        <div className="">
                                                            {
                                                                this.props.desktopbannerloading ?
                                                                    <>Loading...</>
                                                                    :
                                                                    this.state.mobilesingbanner ?
                                                                        this.state.mobilesingbanner.length > 0 ? this.state.mobilesingbanner.map((post) => {

                                                                            return (
                                                                                <>
                                                                                    <div key={post.bannerid}>
                                                                                        <div className="float-start w-100 d-flex align-items-center justify-content-between">
                                                                                            Slide {homebannerj++}
                                                                                            <span className="">
                                                                                                <a className="me-2 float-none w-0" onClick={(e) => this.props.edithomebanner(e, post.bannerid, post.link, post.image, post.bannertype, post.screentype, post.text, "yes")} href="#modal-container-updatebanner" role="button" data-bs-toggle="modal"><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                                                                <a className="float-none w-0" onClick={(e) => this.deleteBanner(e, post.bannerid)}><i className="fa fa-trash me-1" aria-hidden="true"></i></a>
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}?random=${moment().format('YYYYMM-DDHHmmss')}`} /> */}
                                                                                        <div className="desktop_sliders float-start w-100 mt-1">

                                                                                            {
                                                                                                post.image.split('.').pop() == "webm" || post.image.split('.').pop() == "mp4" ?
                                                                                                    <span>
                                                                                                        <video className="dynamicimages_sidebar w-100" width="100%" height="auto" preload="auto" autoplay="true" loop muted playsinline>
                                                                                                            <source id={`${post.bannerid}_banner`} src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}`} />
                                                                                                        </video>
                                                                                                    </span>
                                                                                                    :
                                                                                                    <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}?random=${moment().format('YYYYMM-DDHHmmss')}`} />
                                                                                            }
                                                                                        </div>

                                                                                        <span>
                                                                                        </span>
                                                                                    </div>
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        }) :
                                                                            <div className="no-events col-md-12 text-center my-2">
                                                                                <p className="mb-0">No Banners</p>
                                                                            </div>
                                                                        :
                                                                        <div className="no-events col-md-12 text-center my-2">
                                                                            <p className="mb-0">No Banners</p>
                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="float-start w-100">
                                                        <div id="accordionnewbanner" className={`accordion initial_menubar update_theme ${this.state.isAccordionCollapsed ? 'collapsed' : ''}`}>
                                                            <div className="card mb-0 p-0">
                                                                <div className="card-header-add add-new-slide my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordionnewbanner" href={`#menu_addbanner`} >
                                                                    <div className="card-title mb-0 d-flex align-items-center justify-content-start">
                                                                        <NavLink className="btn btn-header-link p-0 m-0 float-none w-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                        {/* <span style={{ color: 'black', cursor:'pointer' }} >+ Add a new slide */}
                                                                        <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                            <span className="tooltippptext">
                                                                                <div className="">
                                                                                    <div className="">
                                                                                        <p className="gray-text">
                                                                                            Add side here
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <a className="add_slide w-0 float-none mb-0" href="javascript:void(0)"><i className="fas fa-plus-circle d-inline-block mr-0"></i> Add a new slide</a>
                                                                    </div>
                                                                </div>
                                                                <div id={`menu_addbanner`} className={`card-body collapse py-0 ${this.state.isAccordionCollapsed ? '' : 'show'}`} data-parent="#accordionnewbanner">
                                                                    <div className="">
                                                                        <FormGroup className="w-100">
                                                                            <FormLabel className="d-flex alin-items-center justify-content-start" htmlFor="title">
                                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    <span className="tooltippptext">
                                                                                        <div className="">
                                                                                            <div className="">
                                                                                                <p className="gray-text">
                                                                                                    Recommended image properties<br />
                                                                                                    ● <b>Mobile Dimensions:</b> 675 x 339<br />
                                                                                                    ● <b>Desktop Dimensions:</b> 1920 x 269<br />
                                                                                                    ● <b>Size:</b> less than 5 MB<br />
                                                                                                    ● <b>Format:</b> .jpg, .gif, or .png
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <span style={{ color: 'black' }}>Slide Image</span>
                                                                            </FormLabel>
                                                                            <br />
                                                                            <div className="registered text-center upload-file mb-3">
                                                                                <label htmlFor="bannerimage" className="">
                                                                                    <div className="btn-file">

                                                                                        {
                                                                                            this.state.bannerimagefile ?
                                                                                                this.state.bannerimagefile !== "data:image/png;base64" && this.state.screentype === "image" ?
                                                                                                    <label htmlFor="bannerimage" className="choose_file">
                                                                                                        <span className="" ><img className="w-100" src={this.state.bannerimagefile} /></span>
                                                                                                    </label>
                                                                                                    : this.state.screentype === "video" ?
                                                                                                        <label htmlFor="bannerimage" className="choose_file">
                                                                                                            <span className="" >
                                                                                                                <video className="w-100" width="100%" height="auto" preload="auto" autoplay="true" loop muted playsinline>
                                                                                                                    <source id="" src={this.state.bannerimagefile} />
                                                                                                                </video>
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        :
                                                                                                        <div className="btn-file__actions">
                                                                                                            <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                                <div className="btn-file__actions__item--shadow">
                                                                                                                    <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                                    <div className="visible-xs-block"></div>
                                                                                                                    <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                                    <p className="my-2"><small>Or</small></p>
                                                                                                                    <h4>Browse media on your device</h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                :
                                                                                                <div className="btn-file__actions">
                                                                                                    <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                        <div className="btn-file__actions__item--shadow">
                                                                                                            <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                            <div className="visible-xs-block"></div>
                                                                                                            <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                            <p className="my-2"><small>Or</small></p>
                                                                                                            <h4>Browse media on your device</h4>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                        }


                                                                                    </div>
                                                                                </label>

                                                                                <input className='d-none' name="bannerimage" id="bannerimage" type="file" onChange={(e) => { this.dataChange(e); this.handleFileSelect(e); }} />
                                                                                {this.tourValidatorhomebanner.message('Image', this.state.bannerimage, 'required')}
                                                                            </div>

                                                                        </FormGroup>
                                                                        <FormGroup className="select-plan-details w-100 mb-3">
                                                                            <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    <span className="tooltippptext">
                                                                                        <div className="">
                                                                                            <div className="">
                                                                                                <p className="gray-text">
                                                                                                    Add the Slide Image link here
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <span style={{ color: 'black' }}>Slide Link</span></FormLabel>
                                                                            <br />
                                                                            <FormControl type="text" maxLength="35" name="bannerurl" onChange={e => this.dataChange(e)} />
                                                                        </FormGroup>
                                                                        <FormGroup className="select-plan-details w-100 mb-3">
                                                                            <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    <span className="tooltippptext">
                                                                                        <div className="">
                                                                                            <div className="">
                                                                                                <p className="gray-text">
                                                                                                    Choose the Slide Type here, you can use a video or Image Slide.
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <span style={{ color: 'black' }}>Slide Type</span></FormLabel>
                                                                            <br />
                                                                            <select className="form-control" name="screentype" value={this.state.screentype} onChange={e => this.dataChange(e)}>
                                                                                <option value="">Select Type</option>
                                                                                <option value="video">Video</option>
                                                                                <option value="image">Image</option>
                                                                            </select>
                                                                            {this.tourValidatorhomebanner.message('screentype', this.state.screentype, 'required')}
                                                                        </FormGroup>
                                                                        <FormGroup className="select-plan-details w-100 mb-3">
                                                                            <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    <span className="tooltippptext">
                                                                                        <div className="">
                                                                                            <div className="">
                                                                                                <p className="gray-text">
                                                                                                    Choose the Slide Device here
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <span style={{ color: 'black' }}>Visible on</span></FormLabel>
                                                                            <br />
                                                                            <select className="form-control" name="bannertype" onChange={e => this.dataChange(e)}>
                                                                                <option value="">Screen Type</option>
                                                                                <option value="desktop">Desktop</option>
                                                                                <option value="mobile">Mobile</option>
                                                                            </select>
                                                                            {this.tourValidatorhomebanner.message('bannertype', this.state.bannertype, 'required')}
                                                                        </FormGroup>
                                                                        <div className="text-center wrapper">
                                                                            <button type="submit" onClick={(e) => this.AddhomerBanner(e, '756753335', 'home')} disabled={this.state.isSubmithomebanner} className="cfh_button">{this.state.isSubmithomebanner ? 'Please wait' : 'Add'}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    {/* ----------------- Homepage banner ends here --------------- */}


                                    {/* ----------------- call to action starts here --------------- */}
                                    <div className="accordion initial_menubar update_theme">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmeta"
                                                href={`#cta`}>
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                    </NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
                                                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        The call-to-action (CTA) element on the Fan hub prompts users to register, converting them from passive visitors to active community members.
                                                                        {/* <br/><br/>
                                                                                            Click here to learn more about the best practices to upload Homepage Slides. */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Call to action

                                                    </span>
                                                </div>
                                                <div id={`cta`} className="card-body collapse py-0" data-parent="#accordion">
                                                    {/* <ul className="sidemenu">
                                                        <li>
                                                            <div id="accordionmeta" className="accordion">
                                                                <div className="card mb-0">
                                                                    <div id={`cta`} className="card-body collapse" data-parent="#accordionmeta">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul> */}
                                                    {/* <p className="card-title mb-0">
                                                        <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e, "leftmenu")} to={`/navigation-boxes`}>{ }
                                                        <span>Add call to action</span>
                                                        </NavLink>
                                                        
                                                    </p> */}
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'cta')} className="hidebutton">
                                                {/* <i style={{ position: 'absolute', right: '0', display: 'block' }} className={this.state.call_to_action_status == "1" ? `far fa-solid fa-eye` : `far fa-solid fa-eye-slash`} /> */}
                                                {this.state.call_to_action_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                        </div>
                                    </div>
                                    {/* ----------------- call to action ends here --------------- */}


                                    {/* ----------------- about us starts here --------------- */}
                                    {/* <div className="accordion">
                                        <div className="card mb-0">

                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmeta"
                                                href={`#about_fanhub`}>
                                                <p className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                    </NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-address-card" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="gray-text">
                                                                        The "About" section is crucial as it provides a concise overview of your Fan hub. A compelling "About" section can foster trust and engagement with the audience.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">About

                                                    </span>
                                                </p>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'aboutfanhub')} className="hidebutton">
                                                
                                                {this.state.about_fanhub_home_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                        </div>
                                        <div id={`about_fanhub`} className="card-body collapse" data-parent="#accordion">
                                            <ul className="sidemenu">
                                                <li>
                                                    <div id="accordionmeta" className="accordion">
                                                        <div className="card mb-0">

                                                            <div id={`about_fanhub`} className="card-body collapse" data-parent="#accordionmeta">


                                                                <p className="card-title mb-0">
                                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                                    </NavLink>
                                                                    <p className="mb-2">Title</p>
                                                                    <input type="text" maxLength="2000" id="about_fanhub_home_title" placeholder="Add a Title"
                                                                        className="form-control" name="about_fanhub_home_title" onChange={(e) => this.dataChange(e)}
                                                                        value={this.state.about_fanhub_home_title} />
                                                                    <p className="mb-2 mt-2">Description</p>
                                                                    <textarea type="text" rows="6" cols="50" maxLength="2000" id="about_fanhub_home_desc"
                                                                        placeholder="Add a Description" className="form-control" name="about_fanhub_home_desc"
                                                                        onChange={(e) => this.dataChange(e)} value={this.state.about_fanhub_home_desc} >{this.state.about_fanhub_home_desc}</textarea>

                                                                    <button type="button" onClick={(e) => this.update_about_fanhub(e)}
                                                                        disabled={this.state.isSubmitAboutFanhub}
                                                                        className="cfh_button mt-3">{this.state.isSubmitAboutFanhub ? 'Please wait' :
                                                                            'Update'}</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> 
                                    </div> */}

                                    <div className="accordion initial_menubar update_theme">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmetaa" href={`#about_fanhub`} >
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-address-card" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        The "About" section is crucial as it provides a concise overview of your Fan hub. A compelling "About" section can foster trust and engagement with the audience.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">About</span>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'aboutfanhub')} className="hidebutton">

                                                {this.state.about_fanhub_home_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                            <div id={`about_fanhub`} className="card-body collapse py-0" data-parent="#accordionmeta">


                                                <div className="select-plan-details sidemenu float-start w-100 mb-0 pl-4 mt-2">
                                                    <NavLink className="btn btn-header-link border-0 d-block p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <h6 className="customize_inputtitle pt-0">Title</h6>
                                                    <input type="text" maxLength="2000" id="about_fanhub_home_title" placeholder="Add a Title"
                                                        className="form-control" name="about_fanhub_home_title" onChange={(e) => this.dataChange(e)}
                                                        value={this.state.about_fanhub_home_title} />
                                                    <h6 className="customize_inputtitle pt-0 mt-3 float-start w-100">Description</h6>
                                                    <textarea type="text" rows="6" cols="50" maxLength="2000" id="about_fanhub_home_desc"
                                                        placeholder="Add a Description" className="form-control" name="about_fanhub_home_desc"
                                                        onChange={(e) => this.dataChange(e)} value={this.state.about_fanhub_home_desc} >{this.state.about_fanhub_home_desc}</textarea>

                                                    <button type="button" onClick={(e) => this.update_about_fanhub(e)}
                                                        disabled={this.state.isSubmitAboutFanhub}
                                                        className="cfh_button mt-3">{this.state.isSubmitAboutFanhub ? 'Please wait' :
                                                            'Update'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ----------------- about us ends here --------------- */}


                                    {/* ----------------- navigation boxes starts here --------------- */}
                                    {/* <div className="accordion">
                                        <div className="card mb-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmeta"
                                                href={`#navigation_box`}>
                                                <p className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                    </NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-hand-pointer" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="gray-text">
                                                                        Custom Navigation Boxes allows you to personalize fan's experience by providing quick links to different sections.
                                                                        It enables easy navigation to shop, specific content, FAQ's or chat space. Enhancing user engagement, custom sections offer a personalized touch and seamless access to preferred content. 
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Navigation Boxes

                                                    </span>
                                                </p>
                                                <div id={`navigation_box`} className="card-body collapse" data-parent="#accordion">
                                                    <ul className="sidemenu pl-3">
                                                        <li>
                                                            <div id="accordionmeta" className="accordion">
                                                                <div className="card mb-0">
                                                                    <div id={`navigation_box`} className="card-body collapse" data-parent="#accordionmeta">
                                                                        <p><a className="mb-0" href="/navigation-boxes" rel="noopener noreferrer"><i className="fas fa-box me-1"></i> Add Navigation Box</a></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'navigationbox')} className="hidebutton">
                                                {this.state.navigation_boxes_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                        </div>
                                    </div> */}
                                    <div id="accordionmetaa" className="accordion initial_menubar update_theme pb-0">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmetaa" href={`#navigation_box`} >
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-hand-pointer" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        Custom Navigation Boxes allows you to personalize fan's experience by providing quick links to different sections.
                                                                        It enables easy navigation to shop, specific content, FAQ's or chat space. Enhancing user engagement, custom sections offer a personalized touch and seamless access to preferred content.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Navigation Boxes
                                                    </span>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'navigationbox')} className="hidebutton">
                                                {this.state.navigation_boxes_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                            <div id={`navigation_box`} className="card-body my-2 collapse py-0" data-parent="#accordionmetaa">
                                                <div className="sidemenu float-start w-100 pl-4">
                                                    <div className="float-start w-100"><a href="/navigation-boxes" rel="noopener noreferrer">Add Navigation Box</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ----------------- navigation boxes ends here --------------- */}


                                    {/* ----------------- social feed starts here --------------- */}
                                    <div className="accordion initial_menubar update_theme">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmeta"
                                                href={`#fanhub_feed`}>
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                    </NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
                                                        <i className="fa fa-share-square" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        Enabling a social feed offers real-time updates and fosters interaction, building a vibrant community. It enhances user engagement, promotes content sharing, and boosts visibility, ultimately driving fan hub traffic and fan retention.                                                                        {/* <br/><br/>
                                                                                            Click here to learn more about the best practices to upload Homepage Slides. */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Social Feed

                                                    </span>
                                                </div>
                                                {/* <div id={`fanhub_feed`} className="card-body collapse" data-parent="#accordion">
                                                    <ul className="sidemenu">
                                                        <li>
                                                            <div id="accordionmeta" className="accordion">
                                                                <div className="card mb-0">

                                                                    <div id={`fanhub_feed`} className="card-body collapse" data-parent="#accordionmeta">
                                                                        <p><a href="/my-feed" rel="noopener noreferrer">Add Custom Feed</a></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'fanhubfeed')} className="hidebutton">
                                                {/* <i style={{ position: 'absolute', right: '0', display: 'block' }} className={this.state.social_feed_status == "1" ? `far fa-solid fa-eye` : `far fa-solid fa-eye-slash`} /> */}
                                                {this.state.social_feed_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                        </div>
                                    </div>
                                    {/* ----------------- social feed ends here --------------- */}


                                    {/* ----------------- community partners starts here --------------- */}
                                    <div id="accordioncommunity" className="accordion initial_menubar update_theme">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordioncommunity" href={`#menu_community`} >
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
                                                        <i className="fa fa-handshake" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        Partnerships enhance credibility, access to new audiences, and financial support. Sponsors offer vital funds for fan hubs, events, or initiatives, empowering the community to thrive and achieve common goals.                                                                        {/* <br/><br/>
                                                                                            Click here to learn more about the best practices to upload Homepage Slides. */}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Community Partners

                                                    </span>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'communitypartners')} className="hidebutton">
                                                {/* <i style={{ position: 'absolute', right: '0', display: 'block' }} className={this.state.community_partners == "1" ? `far fa-solid fa-eye` : `far fa-solid fa-eye-slash`} /> */}
                                                {this.state.community_partners == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>

                                            <div id={`menu_community`} className="card-body collapse py-0" data-parent="#accordioncommunity">
                                                <div className="sidemenu float-start w-100 pl-4">
                                                    <div className="float-start w-100">
                                                        <div className="">
                                                            {
                                                                this.props.platformimagesloading ?
                                                                    <>Loading...</>
                                                                    :
                                                                    this.state.platformimages ?
                                                                        this.state.platformimages.length > 0 ? this.state.platformimages.map((post) => {

                                                                            if (post.heading == "HOMESIDEBAR") {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            post.sub_heading ?
                                                                                                post.sub_heading.length > 0 ? post.sub_heading.map((postt, index) => (
                                                                                                    <>
                                                                                                        <div key={post.image_id}>
                                                                                                            <div className="float-start w-100 d-flex align-items-center justify-content-between">
                                                                                                                Slide {communitybanner++}
                                                                                                                <span className="">
                                                                                                                    <a className="me-2 float-none w-0" onClick={(e) => this.props.edithomepartners(e, postt.link_id, postt.link_url, postt.image, postt.title, postt.is_active, postt.description, "yes")} href="#modal-container-updatepartner" role="button" data-bs-toggle="modal"><i className="fa fa-edit" aria-hidden="true"></i></a>
                                                                                                                    <a className="float-none w-0" onClick={(e) => this.deletepartner(e, postt.link_id)}><i className="fa fa-trash me-1" aria-hidden="true"></i></a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <div className="desktop_sliders float-start w-100 mt-1">
                                                                                                                {
                                                                                                                    <img className="dynamicimages_sidebar w-100" src={
                                                                                                                        postt.image ?
                                                                                                                            postt.image.includes('http') ? postt.image
                                                                                                                                : `${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/communitypartners/${postt.image}`
                                                                                                                            : ""
                                                                                                                    } />
                                                                                                                }
                                                                                                            </div>

                                                                                                            {/* <img className="dynamicimages_sidebar w-100" src={postt.image} /> */}
                                                                                                            {/* <img className="dynamicimages_sidebar w-100" src={`${process.env.REACT_APP_S3BUCKET_PLATFORNSPECIFIC}${this.state.bucket_name}${this.state.api_platform_shortname}/homecarouselbanners/${post.image}?random=${moment().format('YYYYMM-DDHHmmss')}`} /> */}
                                                                                                        </div>
                                                                                                        <hr />
                                                                                                    </>
                                                                                                )) : '' : ''
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }

                                                                        }) :
                                                                            <div className="no-events col-md-12 text-center mt-5">
                                                                                <p>No Banners</p>
                                                                            </div>
                                                                        :
                                                                        <div className="no-events col-md-12 text-center mt-5">
                                                                            <p>No Banners</p>
                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="float-start w-100">
                                                        <div id="accordionnewpartner" className="accordion initial_menubar update_theme">
                                                            <div className="card mb-0 p-0">
                                                                <div className="card-header-add add-new-slide my-2 collapsed" data-bs-toggle="collapse" data-parent="#accordionnewpartner" href={`#menu_addpartner`} >
                                                                    <div className="card-title mb-0 d-flex align-items-center justify-content-start">
                                                                        <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                        <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                            <span className="tooltippptext">
                                                                                <div className="">
                                                                                    <div className="">
                                                                                        <p className="gray-text">
                                                                                            Add partner here
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                        <a className="add_slide w-0 float-none mb-0" href="javascript:void(0)"><i className="fas fa-plus-circle d-inline-block me-1"></i> Add a new partner</a>
                                                                    </div>
                                                                </div>
                                                                <div id={`menu_addpartner`} className="card-body collapse py-0" data-parent="#accordionnewpartner">
                                                                    <div className="sidemenu float-start w-100 pl-4">
                                                                        <div className="float-start w-100">
                                                                            <div className="m-0 float-start w-100 d-flex align-items-center justify-content-start">
                                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0 float-start">
                                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                    <span className="tooltippptext">
                                                                                        <div className="">
                                                                                            <div className="">
                                                                                                <p className="gray-text">
                                                                                                    Recommended image properties<br />
                                                                                                    ● <b>Mobile Dimensions:</b> 675 x 339<br />
                                                                                                    ● <b>Desktop Dimensions:</b> 1920 x 269<br />
                                                                                                    ● <b>Size:</b> less than 5 MB<br />
                                                                                                    ● <b>Format:</b> .jpg, .gif, or .png
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <FormLabel className="mb-0" htmlFor="title">Partner Image</FormLabel>
                                                                            </div>
                                                                            <div className="registered text-center upload-file mt-3">
                                                                                <label htmlFor="partnerimage" className="">
                                                                                    <div className="btn-file">
                                                                                        {this.state.partnerimagefile && this.state.partnerimagefile !== "data:image/png;base64" ?
                                                                                            <label htmlFor="partnerimage" className="choose_file">
                                                                                                <span className="" ><img className="w-100" src={this.state.partnerimagefile} /></span>
                                                                                            </label>

                                                                                            :
                                                                                            <div className="btn-file__actions">
                                                                                                <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                                    <div className="btn-file__actions__item--shadow">
                                                                                                        <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                        <div className="visible-xs-block"></div>
                                                                                                        <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                        <p className="my-2"><small>Or</small></p>
                                                                                                        <h4>Browse media on your device</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }


                                                                                    </div>
                                                                                </label>

                                                                                <input className='d-none' name="partnerimage" id="partnerimage" type="file" onChange={(e) => this.dataChange(e)} />
                                                                                {this.Validatorhomepartner.message('Image', this.state.partnerimage, 'required')}
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="form-group w-100 mb-0">
                                                                                        <span className="customize_inputtitle">Image Link</span>
                                                                                        <input className="form-control" type="text" name="newimage_name" value={this.state.newimage_name} onChange={(e) => this.dataChange(e)} />
                                                                                    </div> */}

                                                                        <div className="select-plan-details form-group w-100 mb-3">
                                                                            <span className="customize_inputtitle">Title</span>
                                                                            <input className="form-control" type="text" name="newtitle" value={this.state.newtitle} onChange={(e) => this.dataChange(e)} />
                                                                            {this.Validatorhomepartner.message('Title', this.state.newtitle, 'required')}
                                                                        </div>
                                                                        <div className="select-plan-details form-group w-100 mb-3">
                                                                            <span className="customize_inputtitle">Link Url</span>
                                                                            <input className="form-control" type="text" name="newlinkurl" value={this.state.newlinkurl} onChange={(e) => this.dataChange(e)} />
                                                                            {this.Validatorhomepartner.message('Link', this.state.newlinkurl, 'required')}
                                                                        </div>
                                                                        {/* <div className="form-group w-100 mb-0">
                                                                                        <span className="customize_inputtitle">Description</span>
                                                                                        <input className="form-control" type="text" name="newdescription" value={this.state.newdescription} onChange={(e) => this.dataChange(e)} />
                                                                                        {this.Validatorhomepartner.message('Description', this.state.newdescription, 'required')}
                                                                                    </div> */}
                                                                        <div className="text-left wrapper mt-3">
                                                                            <button type="submit" className="cfh_button text-left" onClick={(e) => this.Addpartner(e, '756753335', 'home')} disabled={this.state.isSubmitcred}>{this.state.isSubmitcred ? 'Please wait' : 'Add'}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ----------------- community partners ends here --------------- */}


                                    {/* ----------------- contact info starts here --------------- */}
                                    {/* <div className="accordion">
                                        <div className="card mb-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmetaa"
                                                href={`#contact_info`}>
                                                <p className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }
                                                    </NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className="gray-text">
                                                                        Adding contact information is essential as it enables effective communication and connection with others. Stay reachable and open up doors for meaningful interactions, support or other business opportunities.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Contact info

                                                    </span>
                                                </p>

                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'contactinfo')} className="hidebutton">
                                                {this.state.contact_info_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                        </div>
                                        <div id={`contact_info`} className="card-body collapse" data-parent="#accordion">
                                            <ul className="sidemenu">
                                                <li>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    <div id="accordionmetaa" className="accordion initial_menubar update_theme">
                                        <div className="card mb-0 p-0">
                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100" data-bs-toggle="collapse" data-parent="#accordionmetaa" href={`#contact_info`} >
                                                <div className="card-title mb-0 d-flex align-items-center justify-content-between float-start w-100">
                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                                        <span className="tooltippptext">
                                                            <div className="">
                                                                <div className="">
                                                                    <p className="gray-text">
                                                                        Adding contact information is essential as it enables effective communication and connection with others. Stay reachable and open up doors for meaningful interactions, support or other business opportunities.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <span className="d-flex align-items-center justify-content-between float-start w-100">Contact info</span>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" onClick={(e) => this.statusInfo(e, 'contactinfo')} className="hidebutton">
                                                {this.state.contact_info_status == "1" ? <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/openEye.png`} /> : <img className="hidebuttonimage" src={`${process.env.REACT_APP_S3BUCKET_COMMON}/common/closeEye.png`} />}
                                            </a>
                                            <div id={`contact_info`} className="card-body collapse py-0" data-parent="#accordionmetaa">
                                                <div className="select-plan-details sidemenu pl-4">
                                                    <NavLink className="btn border-0 d-block btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                    <h6 className="customize_inputtitle pt-0 mt-3 float-start w-100">Phone</h6>
                                                    <input type="text" maxLength="2000" className="form-control" placeholder="+1 480 000 0000" id="fanhub_contact_no" name="fanhub_contact_no" onChange={(e) => this.dataChange(e)} value={this.state.fanhub_contact_no} />
                                                    <h6 className="customize_inputtitle pt-0 mt-3 float-start w-100">Email</h6>
                                                    <input type="text" maxLength="2000" className="form-control" placeholder="hello@myfanhub.com" id="fanhub_email" name="fanhub_email" onChange={(e) => this.dataChange(e)} value={this.state.fanhub_email} />
                                                    <h6 className="customize_inputtitle pt-0 mt-3 float-start w-100">Address</h6>
                                                    <input type="text" maxLength="2000" className="form-control" placeholder="Times Square, New York City" id="fanhub_address" name="fanhub_address" onChange={(e) => this.dataChange(e)} value={this.state.fanhub_address} />
                                                    <button type="button" onClick={(e) => this.update_contact_info(e)} disabled={this.state.isSubmitAboutFanhub} className="cfh_button mt-3">{this.state.isSubmitAboutFanhub ? 'Please wait' : 'Update'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ----------------- contact info ends here --------------- */}


                                    <span className="customize_title ps-0 mt-2">Colors</span>
                                    <div id={`menu_theme2`} className="card-body collapse show p-0" data-parent="#accordion">
                                        {/* <div className="sidemenu theme_colors float-start w-100"> */}
                                            {/* <div className="float-start w-100">
                                                <div id="" className="accordion initial_menubar update_theme">
                                                    <div className="card mb-0 p-0"> */}
                                                        {/* <div className="card-header my-0 collapsed" data-bs-toggle="collapse" data-parent="#"  >
                                                            <p className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <span>Colors</span>
                                                            </p>
                                                        </div> */}
                                                        <div className="card-body p-0" data-parent="#">
                                                            <div className="">
                                                                {
                                                                    dynamicoptions ?
                                                                        dynamicoptions.length > 0 ? dynamicoptions.map((post) => (
                                                                            post.option_type === 'color' && !['headercolor', 'footercolor', 'h2color', 'h3color', 'h4color', 'h5color', 'h6color', 'bodycolor', 'pcolor', 'h1color', 'featuredcolor', 'primarycolor', 'secondarycolor', 'menulinks'].includes(post.option_key) ?
                                                                                <>
                                                                                    <div id={`accordion_${post.option_key}`} className="accordion initial_menubar update_theme">
                                                                                        <div className="card mb-0 p-0">
                                                                                            <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent={`#accordion_${post.option_key}`} href={`#menu${post.option_key}`} >
                                                                                                <div className="card-title mb-0">
                                                                                                    <NavLink className="btn btn-header-link p-0 m-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                                                    <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                                        <span className="tooltippptext">
                                                                                                            <div className="">
                                                                                                                <div className="">
                                                                                                                    <p className="gray-text">
                                                                                                                        {
                                                                                                                            post.option_key === 'bodycolor' ?
                                                                                                                                "Set Body Text Color"
                                                                                                                                : post.option_key === 'footercolor' ?
                                                                                                                                    "Customize Footer Background Color"
                                                                                                                                    : post.option_key === 'headercolor' ?
                                                                                                                                        "Choose Header Background Color"
                                                                                                                                        : post.option_key === 'pcolor' ?
                                                                                                                                            "Adjust Paragraph Color"
                                                                                                                                            : post.option_key === 'h1color' ?
                                                                                                                                                "Style Heading 1 Color"
                                                                                                                                                : post.option_key === 'h2color' ?
                                                                                                                                                    "Modify Heading 2 Color"
                                                                                                                                                    : post.option_key === 'h3color' ?
                                                                                                                                                        "Update Heading 3 Color"
                                                                                                                                                        : post.option_key === 'h4color' ?
                                                                                                                                                            "Change Heading 4 Color"
                                                                                                                                                            : post.option_key === 'h5color' ?
                                                                                                                                                                "Choose Heading 5 Color"
                                                                                                                                                                : post.option_key === 'h6color' ?
                                                                                                                                                                    "Customize Heading 6 Color"
                                                                                                                                                                    : post.option_key === 'featuredcolor' ?
                                                                                                                                                                        "Select Featured Color"
                                                                                                                                                                        : post.option_key === 'primarycolor' ?
                                                                                                                                                                            "Set Primary Color"
                                                                                                                                                                            : post.option_key === 'secondarycolor' ?
                                                                                                                                                                                "Choose Secondary Color"
                                                                                                                                                                                : post.option_key === 'menulinks' ?
                                                                                                                                                                                    "Modify Menu Links Color"
                                                                                                                                                                                    : post.option_key === 'btntextcolor' ?
                                                                                                                                                                                        "Modify Button Text Color"
                                                                                                                                                                                        : post.option_key === 'btnbgcolor' ?
                                                                                                                                                                                            "Set Button Background Color"
                                                                                                                                                                                            : post.option_key === 'btnhovertextcolor' ?
                                                                                                                                                                                                "Customize Button Hover Text Color"
                                                                                                                                                                                                : post.option_key === 'btnhoverbgcolor' ?
                                                                                                                                                                                                    "Adjust Button Hover Border Color"
                                                                                                                                                                                                    : post.option_key === 'btnhoverbordercolor' ?
                                                                                                                                                                                                        "Adjust Button Hover Border Color"
                                                                                                                                                                                                        : "Modify color"
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <span style={{cursor: 'pointer' }} >{post.option_name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div id={`menu${post.option_key}`} className="card-body collapse py-0" data-parent={`#accordion_${post.option_key}`}>
                                                                                                <div className="pl-4">
                                                                                                    <div className="color_picker mt-0">
                                                                                                        <InputColor
                                                                                                            className="hidden d-none"
                                                                                                            initialValue={post.option_value}
                                                                                                            value={post.option_value}
                                                                                                            onChange={(e) => this.setColor(e, post.option_key)}
                                                                                                            placement="right"
                                                                                                        />
                                                                                                        <SketchPicker
                                                                                                            className="mt-3"
                                                                                                            color={this.state[post.option_key]}
                                                                                                            // value={post.option_value}
                                                                                                            onChangeComplete={(e) => this.handleChangeComplete(e, post.option_key)}
                                                                                                        // onChange={(e) => this.setColor(e, post.option_key)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <hr /> */}
                                                                                </>
                                                                                : ''

                                                                        )) : ''
                                                                        : ''
                                                                }
                                                                <div className="float-start w-100 mt-3"> <button className="cfh_button" onClick={(e) => this.saveColor(e)}>Update</button></div>
                                                            </div>
                                                        </div>
                                                    {/* </div>
                                                </div>
                                            </div> */}

                                            {/* <li>
                                                <div id="accordionfontfamily" className="accordion">
                                                    <div className="card mb-0">
                                                        <div className="card-header my-1 collapsed" data-bs-toggle="collapse" data-parent="#accordionfontfamily" href={`#menu_fontfamily`} >
                                                            <p className="card-title mb-0 ms-2">
                                                                <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <span>Font Family</span>
                                                            </p>
                                                        </div>
                                                        <div id={`menu_fontfamily`} className="card-body collapse " data-parent="#accordionfontfamily">
                                                            <div className="">
                                                                {
                                                                    dynamicoptions ?
                                                                        dynamicoptions.length > 0 ? dynamicoptions.map((post) => (
                                                                            post.option_type === 'font' && !['h3font', 'h4font', 'h5font', 'h6font'].includes(post.option_key) ?

                                                                                <>
                                                                                    <div className="">
                                                                                        <span style={{ color: 'black' }} >{post.option_name}</span>
                                                                                    </div>
                                                                                    <div className="mt-2">
                                                                                        <select name={post.option_key} className='form-control' value={this.state[post.option_key] && this.state[post.option_key] != "" ? this.state[post.option_key] : post.option_value} onChange={(e) => this.validateLoginForm(e)} >
                                                                                            <option value="">Choose Font</option>
                                                                                            <option value="metropolismedium">metropolismedium</option>
                                                                                            <option value="AdelleLight">AdelleLight</option>
                                                                                            <option value="FuturaDemiBold">FuturaDemiBold</option>
                                                                                            <option value="FuturaExtraBold">FuturaExtraBold</option>
                                                                                            <option value="FuturaLight">FuturaLight</option>
                                                                                            <option value="FuturaLightBt">FuturaLightBt</option>
                                                                                            <option value="FuturaBook">FuturaBook</option>
                                                                                            <option value="FuturaMedium">FuturaMedium</option>
                                                                                            <option value="FuturaMediumCondensed">FuturaMediumCondensed</option>
                                                                                            <option value="gemunu_libreregular">Gemunu Libreregular</option>
                                                                                            <option value="gemunu_libreextrabold">Gemunu Libreextrabold</option>
                                                                                            <option value="gemunu_librebold">Gemunu Librebold</option>
                                                                                        </select>
                                                                                    </div>
                                                                                    <hr />
                                                                                </>
                                                                                : ''

                                                                        )) : ''
                                                                        : ''
                                                                }
                                                                <button className="cfh_button" onClick={(e) => this.savefontfam(e)}>Update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> */}
                                        {/* </div> */}
                                    </div>

                                </div>
                            </div>
                        </>
                        :
                        window.location.pathname === "/add-custom-menu"  || window.location.pathname === "/lobby-banner-setup" || window.location.pathname === "/navigation-boxes" || this.state.leftmenubar ?
                            <>
                                <div className="activity-center">
                                    <h5><NavLink to="/activity-center"><i className="fa fa-chevron-left me-2" aria-hidden="true"></i> Activity Center</NavLink></h5>
                                </div>
                                <div id="accordion" className="accordion cfh pb-5">
                                    <div className="card mb-0">
                                        <span className="customize_title ps-0">
                                            {
                                                window.location.pathname === "/navigation-boxes" ?
                                                    `Navigation Boxes`
                                                    :
                                                    `Custom Menus`
                                            }

                                        </span>
                                        <div id={`menu_theme`} className="card-body collapse show p-0 py-0" data-parent="#accordion">
                                            <div className="sidemenu float-start w-100">
                                                <div className="float-start w-100">
                                                    <div id="accordionmode" className="accordion initial_menubar update_theme">
                                                        <div className="card mb-0 p-0">
                                                            <div className="card-header my-1 collapsed align-items-center justify-content-between float-start w-100">                                                   <div className="card-title mb-0">
                                                                <NavLink className="btn btn-header-link p-0" onClick={(e) => this.remtrid(e)} to={`#`}>{ }</NavLink>
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    {
                                                                                        window.location.pathname === "/navigation-boxes" ?
                                                                                            `Custom Navigation Boxes allows you to personalize fan's experience by providing quick links to different sections. It enables easy navigation to shop, specific content, FAQ's or chat space. Enhancing user engagement, custom sections offer a personalized touch and seamless access to preferred content..`
                                                                                            :
                                                                                            // window.location.pathname === "/lobby-banner-setup" ?
                                                                                            // `With Custom Menus, you have full control over the navigation of your fan hub. Easily add and edit menus to showcase your content and guide your visitors through a seamless browsing experience. Create personalized menus that reflect your brand and organize your pages, posts, and other important sections. Take charge of your fan hub's navigation and provide a user-friendly interface for your audience."`
                                                                                            // :
                                                                                            `With Custom Menus, you have full control over the navigation of your fan hub. Easily add and edit menus to showcase your content and guide your visitors through a seamless browsing experience. Create personalized menus that reflect your brand and organize your pages, posts, and other important sections. Take charge of your fan hub's navigation and provide a user-friendly interface for your audience."`
                                                                                    }


                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span className="">
                                                                    {
                                                                        window.location.pathname === "/navigation-boxes" ?
                                                                            `Manage the Navigation Boxes`
                                                                            :
                                                                            window.location.pathname === "/lobby-banner-setup" ?
                                                                            `Design your digital home`
                                                                            :
                                                                            `Manage Your Fan Hub Navigation`
                                                                    }


                                                                </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        window.location.pathname === "/lobby-banner-setup" ?
<div className="nft-details-info border-none bg-none mb-4 mt-4">
                                        <div id={`menu_addbanner`}>
                                            <div className="">
                                                <FormGroup className="w-100">
                                                    <FormLabel className="d-flex alin-items-center justify-content-start" htmlFor="title">
                                                        <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                            <span className="tooltippptext">
                                                                <div className="">
                                                                    <div className="">
                                                                        <p className="gray-text">
                                                                            Recommended image properties<br />
                                                                            ● <b>Mobile Dimensions:</b> 675 x 339<br />
                                                                            ● <b>Desktop Dimensions:</b> 1920 x 269<br />
                                                                            ● <b>Size:</b> less than 5 MB<br />
                                                                            ● <b>Format:</b> .jpg, .gif, or .png
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <span style={{ color: 'black' }}>Lobby Image</span>
                                                    </FormLabel>
                                                    <br />
                                                    <div className="registered text-center upload-file mb-3">
                                                        <label htmlFor="lobbybannerimage" className="">
                                                            <div className="btn-file">

                                                                {
                                                                    this.state.lobbybannerimagefile ?
                                                                        this.state.lobbybannerimagefile !== "data:image/png;base64" ?
                                                                            <label htmlFor="lobbybannerimage" className="choose_file">
                                                                                <span className="" ><img className="w-100" src={this.state.lobbybannerimagefile} /></span>
                                                                            </label>
                                                                            :
                                                                            <div className="btn-file__actions">
                                                                                <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                    <div className="btn-file__actions__item--shadow">
                                                                                        <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                        <div className="visible-xs-block"></div>
                                                                                        <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                        <p className="my-2"><small>Or</small></p>
                                                                                        <h4>Browse media on your device</h4>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        :
                                                                        <div className="btn-file__actions">
                                                                            <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                <div className="btn-file__actions__item--shadow">
                                                                                    <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                    <div className="visible-xs-block"></div>
                                                                                    <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                    <p className="my-2"><small>Or</small></p>
                                                                                    <h4>Browse media on your device</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                        </div>
                                                        </label>
                                                        <input className='d-none' name="lobbybannerimage" id="lobbybannerimage" type="file" onChange={(e) => this.dataChange(e)} />
                                                    </div>
                                                     <div>
                                                        <FormGroup className="w-100 mb-3">
                                                            <FormLabel className="align-items-center justify-content-start" htmlFor="title">
                                                                <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                    <span className="tooltippptext">
                                                                        <div className="">
                                                                            <div className="">
                                                                                <p className="gray-text">
                                                                                    Choose the Slide Type here, you can use a video Slide.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span style={{ color: 'black' }}>Upload video</span></FormLabel>
                                                            <br />
                                                            <select className="form-control" onChange={this.videochangediv}>
                                                                <option value="">Select Type</option>
                                                                <option value="yes">yes</option>
                                                                <option value="no">no</option>
                                                            </select>
                                                        </FormGroup>
                                                        {
                                                            this.state.showActiondiv && (
                                                                <div className="registered text-center upload-file mb-3">
                                                                    <label htmlFor="lobbyvideo" className="">
                                                                        <div className="btn-file">

                                                                            {
                                                                                this.state.my_lobby_video ?
                                                                                     <label htmlFor="lobbybannerimage" className="choose_file">
                                                                                        <span className="" ><img className="w-100" src={this.state.my_lobby_video} /></span>
                                                                                    </label>
                                                                                    :
                                                                                    <div className="btn-file__actions">
                                                                                        <div className="btn-file__actions__item col-xs-12 text-center">
                                                                                            <div className="btn-file__actions__item--shadow">
                                                                                                <img className="img-fluid" src="https://storage.googleapis.com/xr-sports/xr_all/xrdynamic-admin/attach-icon.png"></img>
                                                                                                <div className="visible-xs-block"></div>
                                                                                                <h6 className="mt-3">Drag and drop your photo</h6>
                                                                                                <p className="my-2"><small>Or</small></p>
                                                                                                <h4>Browse media on your device</h4>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </label>
                                                                    <input className='d-none' name="lobbyvideo" id="lobbyvideo" type="file" onChange={(e) => { this.dataChange(e); }} />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="w-100 select-plan-details mb-3">
                                                    <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                        <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                            <span className="tooltippptext">
                                                                <div className="">
                                                                    <div className="">
                                                                        <p className="gray-text">
                                                                            Choose the Slide Device here
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <span style={{ color: 'black' }}>Visible on</span></FormLabel>
                                                    <br />
                                                    <select className="form-control" name="bannertype" onChange={e => this.dataChange(e)}>
                                                        <option value="">Screen Type</option>
                                                        <option value="desktop">Desktop</option>
                                                        <option value="mobile">Mobile</option>
                                                    </select>
                                                    {this.tourValidatorhomebanner.message('bannertype', this.state.bannertype, 'required')}
                                                </FormGroup>
                                                <FormGroup className="w-100 select-plan-details mb-3">
                                                    <FormLabel className="d-flex align-items-center justify-content-start" htmlFor="title">
                                                        <div className="tooltippp bg-gray-join-icon ps-0 pe-2 ms-0">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                            <span className="tooltippptext">
                                                                <div className="">
                                                                    <div className="">
                                                                        <p className="gray-text">
                                                                            Choose the user type
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <span style={{ color: 'black' }}>Visible on</span></FormLabel>
                                                    <br />
                                                    <select className="form-control" name="screenusertype" onChange={e => this.dataChange(e)}>
                                                        <option value="">User Type</option>
                                                        <option value="login">LoggedIn</option>
                                                        <option value="logout">Logged-out</option>
                                                        <option value="both">Both</option>
                                                    </select>
                                                    {this.tourValidatorhomebanner.message('screenusertype', this.state.screenusertype, 'required')}
                                                </FormGroup>
                                                <div className="text-center wrapper">
                                                    <button type="submit" onClick={(e) => this.AddLobbyBannerNew(e)} disabled={this.state.isSubmitLobbybanner} className="cfh_button">{this.state.isSubmitLobbybanner ? 'Please wait' : 'Add'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        ''
                                    }
                                    <NavLink className="cfh_button mt-5" to="/customize-homepage">Back To Preview </NavLink>
                                </div>
                            </>

                            :
                            <>
                                ""
                            </>
                }

                {/* ---------------------View Image ---------------------------------- */}
                <div className="modal fade submission_modals" id="modal-container-banner" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <button type="button" className="closer" id="closer" data-bs-dismiss="modal">&times;</button>
                    <div className="logo-image text-center">
                    </div>
                    <div className="login team modal-dialog" role="document">
                        <div className="login modal-content rob">
                            {
                                this.state.bannerext === 'mp4' || this.state.bannerext === 'webm' ?
                                    <>
                                        <div className="modal-header text-center">
                                            <h5 className="modal-titl text-center w-100 creategametitle" id="myModalLabel">Video</h5>
                                        </div>
                                        <div className="modal-body create_match">
                                            <video className="desktop" width="100%" height="auto" preload="auto" autoplay loop muted playsinline>
                                                <source src={this.state.viewimage} />
                                            </video>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="modal-header text-center">
                                            <h5 className="modal-titl text-center w-100 creategametitle" id="myModalLabel">Image</h5>
                                        </div>
                                        <div className="modal-body create_match">
                                            <img src={this.state.viewimage} alt="Banner" />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>



                {/* <div className="modal fade" id="modal-container-385731" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="myModalLabel">
								Modal title
							</h5> 
							<button type="button" className="close" data-bs-dismiss="modal">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							...
						</div> 
						<div className="modal-footer">
							 
							<button type="button" className="btn btn-primary">
								Save changes
							</button> 
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
								Close
							</button>
						</div>
					</div>
					
				</div>
				
			</div> */}


            </>
        );
    }
}
const mapStateToProps = (state) => ({
    adminmenuloding: state.menu.adminmenuloding,
    allmenusadmindata: state.menu.allAdminMenus,

    singleuserdata: state.userDetails.singleuser,
    singleuserdataloading: state.userDetails.loading,

    secureclientdata: state.dynamic.secureclientdata,
    secureclientdataloading: state.dynamic.secureclientdataloading,

    desktopbanner: state.banners.desktopbanner,
    mobilesingbanner: state.banners.mobilesingbanner,
    desktopbannerloading: state.banners.loading,


    lobbydesktopbanner: state.lobbyimg.lobbydesktopbanner,
    lobbymobilesingbanner: state.lobbyimg.lobbymobilesingbanner,
    lobbydesktoploading: state.lobbyimg.loading,



    dynamicoptions: state.dynamic.dynamicoptions,
    dynamicoptionsloading: state.dynamic.loading,

    platformimages: state.dynamic.platformimages,
    platformimagesloading: state.dynamic.loading,
    logs: state.dynamic.logs
})
export default connect(mapStateToProps)(Fanhubmenus);


