
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loaderspinner} from './../store/helpers/common';
import parseJwt  from '../store/helpers/common';

class My404Component extends Component {
    constructor(props) {
        super(props);
          this.state = {
            is_loggedin:false,
            iscompleted:false
        }
    }
    componentDidMount() {
        if (localStorage.getItem('token')) {
            const currdetails = parseJwt(localStorage.getItem('token'));
            const role = currdetails.urxrs;
            const userstatus = currdetails.sat;
            this.setState({ is_loggedin: true});
            this.props.history.push('/activity-center');
        }else{
            this.props.history.push('/login');
            this.setState({ is_loggedin: false});
        }
    }
    render() {
        const{is_loggedin} = this.state;
            return (
                <>
                    {
                        is_loggedin
                        ?
                            <div className="loaderdivmain"><img className="spinnerloader" src={loaderspinner}></img></div>
                        :
                    
                            <div className="container">
                                <div className="games-list">
                                    <div id="mainpage" className="main_box about col-md-12 p-0">
                                    <div className="main-content about-us mt-4">
                                                <div className="pagenotfound">
                                                    <h1>404 Page not found!</h1>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            );
        
    }
}

export default connect()(My404Component);