import { apiBaseUrl, callHandleArray, swalpopup } from '../helpers/common';
import * as actionTypes from '../types/types';
import {handleResponse} from '../helpers/userServices';
import toastr from "reactjs-toastr";
import swal from 'sweetalert';
import { EventTypes } from 'redux-segment';
import { da } from 'date-fns/locale';


export const fetchStart = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN
    }
}
export const fetchStarttwo = () => {
    return {
        type: actionTypes.FETCH_DATA_BEGIN_TWO
    }
}
export const fetchUserSuccess = (data) => {
   return {
        type: actionTypes.FETCH_USER_SUCCESS,
        payload: data.data
    }
}
export const fetchUsersSuccess = (data) => {
   
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        payload: data.data
    }
}
export const fetchAdminUsersSuccess = (data) => {
   
    return {
        type: actionTypes.FETCH_ADMIN_USERS_SUCCESS,
        payload: data.data
    }
}
export const fetchUsersCount = (data) => {
   
    return {
        type: actionTypes.FETCH_USERS_COUNT,
        payload: data.data,
        paylo: data.count,
    }
}
export const fetchAllUserSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_USER_SUCCESS,
        payload: data.data
    }
}
export const fetchNotificationsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        payload: data.data
    }
}
export const fetchAllhighschoolUserSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_HIGH_SCHOOL_SUCCESS,
        payload: data.data
    }
}
export function getUserdetails(id) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.data.cart === null){
            }else{
                if(data.data.cart === '[]' || data.data.cart.length === 0 || data.data.cart.length === 2){
                }else{
                    data.data.cart = JSON.parse(data.data.cart);
                }
            }        
            dispatch(fetchUserSuccess(data));
        });
    }
}
export const fetchUserDetailsHubSuccess = (data) => {
   return {
        type: actionTypes.FETCH_USER_DETAIL_HUB_SUCCESS,
        payload: data.data
    }
}
export function getUserdetailshub(id) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user-hub/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            if(data.data.cart === null){
            }else{
                if(data.data.cart === '[]' || data.data.cart.length === 0 || data.data.cart.length === 2){
                }else{
                    data.data.cart = JSON.parse(data.data.cart);
                }
            }        
            dispatch(fetchUserDetailsHubSuccess(data));
        });
    }
}

export const fetchSingleUserSuccess = (data) => {
   
    return {
        type: actionTypes.FETCH_SINGLE_USER_SUCCESS,
        payload: data.data,
        payloadd: data.status
    }
}
export function getSingleUserdetails(id) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/getuser/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.data){
                if(data.data.cart === null){
                }else{
                    if(data.data.cart === '[]' || data.data.cart.length === 0 || data.data.cart.length === 2){
                    }else{
                        data.data.cart = JSON.parse(data.data.cart);
                    }
                } 
            }
            dispatch(fetchSingleUserSuccess(data));
        });
    }
}
export function getallUserdetails(id=0,idd=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-usersdata/${id}/${idd}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json'}),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllUserSuccess(data));
        });
    }
}

export function getallUserdetailshighschool(id=0,idd=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-usersdata-highschool/${id}/${idd}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json'}),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllhighschoolUserSuccess(data));
        });
    }
}

export function getUsersdetail(id=`${localStorage.getItem('PLATFORM_ID_COMMOM_DB')}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allusers-hub/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUsersSuccess(data));
        });
    }
}

export function getAdminUsersdetail(id,pagenum=1) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allusers-admin/${localStorage.getItem('PLATFORM_ID_COMMOM_DB')}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminUsersSuccess(data));
        });
    }
}

export function getUserCount(id=`${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/getusercount/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUsersCount(data));
        });
    }
}

export function changeNotifications(id,idd,type) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/change-notifications/${id}/${idd}/${type}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchNotificationsSuccess(data));
            if (data.ResponseCode === '1') {
              // swal("Done", data.ResponseText, "success");
                  swalpopup(data.ResponseText, "success");
                dispatch(getUserdetails(id));
            } else {
                // swal("Oops!", data, "error");  
                swalpopup(data, "error");
            }
        });
    }
}
export const fetchSubadminSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SUBADMIN_SUCCESS,
        payload: data.data
    }
}

export function getSubAdmins(idd,pagenum=1) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-subadmins/${idd}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchSubadminSuccess(data));
        });
    }
}



export const fetchAdminVideosSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ADMINVIDEOS_SUCCESS,
        payload: data.data
    }
}

export function getAdminVideos(idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-videos`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminVideosSuccess(data));
        });
    }
}

export const fetchAdminUserVideosSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ADMIN_USER_VIDEOS_SUCCESS,
        payload: data.data
    }
}

export function getAdminUserVideos(idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-user-videos`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminUserVideosSuccess(data));
        });
    }
}


export const fetchAdminExclusiveVideosSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ADMIN_EXCLUSIVE_VIDEOS_SUCCESS,
        payload: data.data,
        newuser: data.newuser,
        existinguser: data.existinguser
    }
}

// export function getAdminExclusiveVideos(idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
//     return dispatch => {
//         dispatch(fetchStarttwo());
//         const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-exclusive-videos`, {
//             method: 'GET',
//             headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
//         });
//         return fetch(request).then(handleResponse).then((data) => {
//             dispatch(fetchAdminExclusiveVideosSuccess(data));
//         });
//     }
// }


export function getAdminExclusiveVideos(idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`,pagenum=1) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-exclusive-videos/${pagenum}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminExclusiveVideosSuccess(data));
        });
    }
}
export const fetchExclusiveVideosSuccess = (data) => {
    return {
        type: actionTypes.FETCH_EXCLUSIVE_VIDEOS_SUCCESS,
        payload: data.data
    }
}

export function getExclusiveVideos(id= 0, idd = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-exclusive-videos/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`  }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchExclusiveVideosSuccess(data));
        });
    }
}


export const fetchSingleexclusiveStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_EXCLUSIVE_VIDEO_BEGIN
    }
}
export const fetchSingleexclusiveCommentStart = () => {
    return {
        type: actionTypes.FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_BEGIN
    }
}
export const fetchSingleExclusiveVideoSuccess = (data) => { 
    return {
        type: actionTypes.FETCH_SINGLE_EXCLUSIVE_VIDEO_SUCCESS,
        payload: data.data
    }
}

export function getSingleExclusiveVideo(video_id,user_id) {
    return dispatch => {
        dispatch(fetchSingleexclusiveStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-single-exclusive-video/${video_id}/${user_id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchSingleExclusiveVideoSuccess(data));
        });
    }
}
export const fetchSingleExclusiveVideoCommentsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_SINGLE_EXCLUSIVE_VIDEO_COMMENT_SUCCESS,
        payload: data.data
    }
}

export function getSingleExclusiveVideoComments(video_id,user_id,comment_for) {
    return dispatch => {
        dispatch(fetchSingleexclusiveCommentStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-single-exclusive-video-comments/${video_id}/${user_id}/${comment_for}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchSingleExclusiveVideoCommentsSuccess(data));
        });
    }
}

export const fetchRelatedExclusiveVideoSuccess = (data) => {
    return {
        type: actionTypes.FETCH_RELATED_EXCLUSIVE_VIDEO_SUCCESS,
        payload: data.data
    }
}
export function getRelatedExclusiveVideo(id = 0) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-related-exclusive-video/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchRelatedExclusiveVideoSuccess(data));
        });
    }
}




export const fetchBannedUsersSuccess = (data) => {
    return {
        type: actionTypes.FETCH_BANNED_USER_SUCCESS,
        payload: data.data
    }
}
export const fetchDeletedUsersSuccess = (data) => {
    return {
        type: actionTypes.FETCH_DELETED_USER_SUCCESS,
        payload: data.data
    }
}

export function getBannedusers() {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-baned-users`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchBannedUsersSuccess(data));
        });
    }
}
export function getDeletedUsers() {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-deleted-users`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchDeletedUsersSuccess(data));
        });
    }
}



export const fetchMyTournamentsSuccess = (data) => {
    return {
        type: actionTypes.FETCH_MY_TOURNAMENTS_SUCCESS,
        payload: data.data
    }
}

export function getmytournaments(id, pagenum=1) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/my-tournaments/${id}/${pagenum}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchMyTournamentsSuccess(data));
        });
    }
}


export const fetchLeaderboardUserSuccess = (data) => {
    return {
        type: actionTypes.FETCH_LEADERBOARD_TOURNAMENTS_SUCCESS,
        payload: data.data
    }
}

export function getleaderboarduser(game,id) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-userleader-data/${game}/${id}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchLeaderboardUserSuccess(data));
        });
    }
}


export const fetchAdminByUserSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ADMIN_BY_USER_SUCCESS,
        payload: data.data
    }
}

export function getAdminByUser() {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-by-user`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminByUserSuccess(data));
        });
    }
}

export const fetchUserbySlugStart = () => {
    return {
        type: actionTypes.FETCH_USER_BYSLUG_BEGIN
    }
}
export const fetchUserbySlugSuccess = (data) => {
   
    return {
        type: actionTypes.FETCH_USER_BYSLUG_SUCCESS,
        payload: data.data,
        // meta: {
        //     analytics: {
        //       eventType: EventTypes.track,
        //     },
        //   },
    }
}
export function getSingleUserdetailsbyslug(slug) {
    return dispatch => {
        dispatch(fetchUserbySlugStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/getuser-slug/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.data){
                if(data.data.cart === null){
                }else{
                    if(data.data.cart === '[]' || data.data.cart.length === 0 || data.data.cart.length === 2){
                    }else{
                        data.data.cart = JSON.parse(data.data.cart);
                    }
                } 
            }  
            dispatch(fetchUserbySlugSuccess(data));
        });
    }
}
export const fetchUserProfilebySlugStart = () => {
    return {
        type: actionTypes.FETCH_USER_PROFILE_BYSLUG_BEGIN
    }
}
export const fetchUserProfilebySlugSuccess = (data) => {
   
    return {
        type: actionTypes.FETCH_USER_PROFILE_BYSLUG_SUCCESS,
        payload: data.data
    }
}
export const fetchAdminPreferences = (data) => {
    return {
        type: actionTypes.FETCH_ADMIN_PREFERENCES,
        payload: data.data
    }
}

export const fetchAllCollectibels = (data) => {
    return {
        type: actionTypes.FETCH_ALL_COLLECTIBELS,
        payload: data.data
    }
}

export const fetchLimitedCollectibels = (data) => {
    return {
        type: actionTypes.FETCH_LIMITED_COLLECTIBELS,
        payload: data.data
    }
}

export const fetchUserHastags = (data) => {
    return {
        type :  actionTypes.FETCH_USERS_HAHSTAGS,
        payload : data.data,
    }
}

export const fetchWalletInformation = (data) => {
    return {
        type: actionTypes.FETCH_WALLET_INFORMATION,
        payload: data.data,
    }
}



export function getSingleUserProfiledetailsbyslug(slug) {
    return dispatch => {
        dispatch(fetchUserProfilebySlugStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/getsingleuser-slug/${slug}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            if(data.data){
                if(data.data.cart === null){
                }else{
                    if(data.data.cart === '[]' || data.data.cart.length === 0 || data.data.cart.length === 2){
                    }else{
                        data.data.cart = JSON.parse(data.data.cart);
                    }
                } 
            }  
            dispatch(fetchUserProfilebySlugSuccess(data));
        });
    }
}

export function getAdminPreferences(page) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-admin-preferences/${page}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAdminPreferences(data));
        });
    }

}

export const fetchTopThreePlayersSuccess = (data) => {
    return {
        type: actionTypes.FETCH_TOP_THREE_PLAYER_SUCCESS,
        payload: data.data
    }
}

export function getTopThreePlayers() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-top-three-players`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchTopThreePlayersSuccess(data));
        });
    }
}

export function getAllCollectibels(userId){
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-all-collectibels/${userId}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllCollectibels(data));
        });
    }
}

export function getLimitedCollectibels(userId) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-limited-collectibels/${userId}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchLimitedCollectibels(data));
        });
    }
}


export function getUserHashtags(userId) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user-hastags/${userId}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserHastags(data));
        });
    }
}
        
export const fetchAllSuperAdminSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_SUPERADMIN_SUCCESS,
        payload: data.data
    }
}

export function getAllSuperAmin() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-allsuperadmin`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllSuperAdminSuccess(data));
        });
    }
}



// export function getWalletInformation (AESEncryption, HMACEncryption, network="polygon"){
//     return dispatch => {
//         dispatch(fetchStarttwo());
//         var requestType;
//         // const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-setup`, {
//             const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-wallet-information`, {
//             method: 'POST',
//             body: JSON.stringify({ AESEncryption, HMACEncryption, network , requestType:'ofnItellawteg' }),
//             headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
//         });
//         return fetch(request).then(handleResponse).then((data) => {
//            dispatch(fetchWalletInformation(data));
//         });
//     }
// }

export function getWalletInformation (xp_walletAddress="",account_id="", token="", network="", user_id=""){
    return dispatch => {
        dispatch(fetchStarttwo());
     //   var requestType;

        // console.log("data")
        // console.log(xp_walletAddress);
        // const request = new Request(`${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_APIURL}/user/wallet-setup`, {
           const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-wallet-information`, {
            method: 'POST',
            body: JSON.stringify({ xp_walletAddress, account_id , signupusertoken: token, network ,requestType:'ofnItellawteg', user_id }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
        //    console.log(data)
        //     console.log("data")
           dispatch(fetchWalletInformation(data));
        });
    }
}

export function getWalletInformationNear (AESEncryption, HMACEncryption ,network="near"){
    return dispatch => {
        dispatch(fetchStarttwo());
        var requestType;
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-setup`, {
            const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-wallet-information-near`, {
            method: 'POST',
            body: JSON.stringify({ AESEncryption, HMACEncryption,network, requestType:'ofnItellawteg' }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
           dispatch(fetchWalletInformation(data));
        });
    }
}

export function getWalletUserDetails(userId, walletAddress) {
    return dispatch => {
        dispatch(fetchStarttwo());
        var requestType;
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-setup`, {
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-wallet-user-details`, {
            method: 'POST',
            body: JSON.stringify({ userId, walletAddress, requestType: 'sliatedresutellawteg' }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchWalletUserDetails(data));
        });
    }
}

export function getUsersAllWallet(userId , network="near") {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/wallet-setup`, {
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user-all-wallet`, {
            method: 'POST',
            body: JSON.stringify({ userId, requestType: 'tellawllaresuteg', network }),
            headers: new Headers({ 'Content-Type': 'application/json'}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserAllWallet(data));
        });
    }
}

export function getUsersAllWalletByGroup(userId , wallet_group) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-setup`, {
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user-all-wallet`, {
            method: 'POST',
            body: JSON.stringify({ userId, wallet_group, requestType: 'puorgybtellawllaresuteg'}),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserAllWalletByGroup(data));
        });
    }
}

export const fetchUserAllWalletFanHub = (data) => {
    return {
        type: actionTypes.FETCH_USER_ALL_WALLETS_FANHUB,
        payload: data.data
    }
}
export function getUsersAllWalletfanhub(userId) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-setup-fanhub`, {
        // const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-user-all-wallet`, {
            method: 'POST',
            body: JSON.stringify({ userId, requestType: 'tellawllaresuteg' }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserAllWalletFanHub(data));
        });
    }
}

export const fetchWalletInformationstart = () => {
    return {
        type: actionTypes.FETCH_WALLET_INFORMATION_START
    }
}

export function getWalletRecentActivities(currentUserId, walletAddress) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-wallet-activities`, {
            method: 'POST',
            body: JSON.stringify({ currentUserId, walletAddress }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchWalletActivities(data));
        });
    }
}

export function getLimitedWalletRecentActivities(currentUserId, walletAddress) {
    return dispatch => {
        dispatch(fetchWalletInformationstart());	
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-limited-wallet-activities`, {
            method: 'POST',
            body: JSON.stringify({ currentUserId, walletAddress }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchLimitedWalletActivities(data));
        });
    }
}



//---------communication layer---------------
export const fetchactiveStart = () => {
    return {
        type: actionTypes.FETCH_ACTIVE_DATA_BEGIN
    }
}
export const fetchActiveUsersSuccess = (data) => {

    return {
        type: actionTypes.FETCH_ALL_ACTIVE_USERS,
        payload: data.dataa
    }
}
export const fetchAllUsersSuccess = (data) => {
    return {
        type: actionTypes.FETCH_ALL_USERS,
        payload: data.data
    }
}

export const fetchfanhubSatart=()=>{
    return{
        type:actionTypes.FETCH_ACCESS_FANHUB_BEGIN
    }
}

export const fetchfanhubsuccess=(data)=>{
    return {
        type:actionTypes.FETCH_ACCESS_FANHUB_SUCCESS,
        payload:data.data
    }
}
export const fetchWalletUserDetails = (data) => {
    return {
        type: actionTypes.FETCH_WALLET_USER_DETAILS,
        payload: data.data
    }
}


export const fetchUserAllWallet = (data) => {
    return {
        type: actionTypes.FETCH_USER_ALL_WALLETS,
        payload: data.data,
        next_wallet_group: data.next_wallet_group
    }
}
export const fetchUserAllWalletByGroup = (data) => {
    return {
        type: actionTypes.FETCH_USER_ALL_WALLETS_BY_GROUP,
        payload: data.data,
    }
}

export const fetchWalletActivities = (data) => {
    return {
        type: actionTypes.FETCH_WALLET_ACTIVITIES,
        payload: data.data
    }
}

export const fetchLimitedWalletActivities = (data) =>{
    return {
        type: actionTypes.FETCH_LIMITED_WALLET_ACTIVITIES,
        payload: data.data
    }
}


export function getActiveUsersdetail(id = `${process.env.REACT_APP_PLATFORM_SUFFIX}`, currentid) {
    return dispatch => {
        dispatch(fetchactiveStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allactiveusers/${id}/${currentid}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchActiveUsersSuccess(data));
        });
    }
}

export function getAllUsers(id = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchactiveStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allusers/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllUsersSuccess(data));
        });
    }
}

export function getAllUsershub(id = `${process.env.REACT_APP_PLATFORM_SUFFIX}`) {
    return dispatch => {
        dispatch(fetchactiveStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/allusers-hub/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });

        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchAllUsersSuccess(data));
        });
    }
}

export const fetchLeaderboardUserDataSuccess = (data) => {
    return {
        type: actionTypes.FETCH_LEADERBOARD_DATA_SUCCESS,
        payload: data.data
    }
}



export function getleaderboarduserdata(game,id) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-userleaderboard-data/${game}/${id}`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => { 
            dispatch(fetchLeaderboardUserDataSuccess(data));
        });
    }
}

/* -- get purchased nfts by wallet address -- */

export function getPurchasedNfts(walletAddress) {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/wallet-purchased-nfts`, {
            method: 'POST',
            body: JSON.stringify({ walletAddress }),
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetPurchaseNftsByWallet(data));
        });
    }
}

export const fetPurchaseNftsByWallet = (data) => {
    return {
        type: actionTypes.FETCH_PURCHASED_NFTS_BY_WALLET,
        payload: data.data
    }
}



//---------communication layer---------------


export function getXrTrending() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-xr-trending`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}`}),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchTrendings(data));
        });
    }
}

export const fetchTrendings = (data) => {
    return {
        type: actionTypes.FETCH_TRENDING,
        payload: data.data
    }
}
export function getSingleExclusiveLatestVideo(){
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-latest-exclusive-video`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchLatestExclusiveVideo(data));
        });
    }
}

export function getAllAccessFanhub(){
    return dispatch => {
        dispatch(fetchfanhubSatart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/get-all-access-fanhubdata`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
           console.log(data);
            dispatch(fetchfanhubsuccess(data));
        });
    }
}
export const fetchLatestExclusiveVideo = (data) => {
    return {
        type: actionTypes.FETCH_LATEST_EXCLUSIVE_VIDEO,
        payload: data.data
    }
}

export function getDashboard(id) {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/get-user-dashboard-details/${id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchUserDashboardDetails(data));
        });
    }
}
export const fetchUserDashboardDetails = (data) => {
    return {
        type: actionTypes.FETCH_USER_DASHBOARD_DETAILS,
        payload: data.data
    }
}
export function getRestrictedUserdetails() {
    return dispatch => {
        dispatch(fetchStarttwo());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/communication-restricted-users`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
            dispatch(fetchRestrictedUserdetails(data));
        });
    }
}
export const fetchRestrictedUserdetails = (data) => {
    return {
        type: actionTypes.FETCH_RESTRICTED_USERS_SUCCESS,
        payload: data.data,
        payloadd: data.allfanhubusers,
    }
}

export function getSupportTicket() {
    return dispatch => {
        dispatch(fetchStart());
        const request = new Request(`${process.env.REACT_APP_APIURL}/user/all-support-tickets`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
        });
        return fetch(request).then(handleResponse).then((data) => {
                  
            dispatch(fetchSupportTicketSuccess(data));
        });
    }
}
export const fetchSupportTicketSuccess = (data) => {
   return {
        type: actionTypes.FETCH_SUPPORT_TICKET_SUCCESS,
        payload: data.data
    }
}
//---------communication layer---------------
//------------------merchant data --------------------

// export function get() {
//     return dispatch => {
//         dispatch(fetchStart());
//         const request = new Request(`${process.env.REACT_APP_APIURL}/user/all-support-tickets`, {
//             method: 'GET',
//             headers: new Headers({ 'Content-Type': 'application/json', 'X-Auth-Token': `${localStorage.getItem('token')}` }),
//         });
//         return fetch(request).then(handleResponse).then((data) => {
                  
//             dispatch(fetchSupportTicketSuccess(data));
//         });
//     }
// }
// export const fetchSupportTicketSuccess = (data) => {
//    return {
//         type: actionTypes.FETCH_SUPPORT_TICKET_SUCCESS,
//         payload: data.data
//     }
// }
//-------------------merchant data---------------------------------