import * as types from "../types/types";
 const initalState = {
     loading: true,
     tab:'',
     data:[],
     singlerewarddata:[],
     singlereward:[],
     singlerewardloding:[],
     winnerrewards:[],
     winnerrewardsloading:true,
     subschools:[],
     singlesubschools:[],
     singlesubschoolspc:[],
    singlesubschoolsmobile:[]
 }
 
 const rewardsReducer = (state = initalState, action) => {
     switch (action.type) {
        case types.FETCH_DATA_BEGIN:
            return {
                ...state,
                loading: true
            }
        
        case types.FETCH_REWARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case types.FETCH_WINNER_REWARD_BEGIN:
            return {
                ...state,
                winnerrewardsloading: true
            }
        
        case types.FETCH_WINNER_REWARD_SUCCESS:
            return {
                ...state,
                winnerrewardsloading: false,
                winnerrewards: action.payload
            }
        case types.FETCH_SINGLE_REWARD_BEGIN:
            return {
                ...state,
                singlerewardloding: true
            }
        
        case types.FETCH_SINGLE_REWARD_SUCCESS:
            return {
                ...state,
                singlerewardloding: false,
                singlereward: action.payload
            }     
        case types.FETCH_SINGLE_REWARDS_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 singlerewarddata: action.payload
             }
        case types.FETCH_SUBSCHOOLS_SUCCESS:
            return {
                ...state,
                loading: false,
                subschools: action.payload
            }
        case types.FETCH_SINGLE_SUBSCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                singlesubschools: action.payload
            }  
        case types.FETCH_SUBSCHOOLS_PC_SUCCESS:
            return {
                ...state,
                loading: false,
                singlesubschoolspc: action.payload
            }  
        case types.FETCH_SUBSCHOOLS_MOBILE_SUCCESS:
            return {
                ...state,
                loading: false,
                singlesubschoolsmobile: action.payload
            }    
         default:
             return state
     }
 }
 
 export default rewardsReducer;